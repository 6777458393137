import React, { useEffect, useState } from 'react'
import { createRecord, editRecord } from '../../../request/Request';
import { filterSelectData, selectAgent, selectSender } from '../../../service/comboBox';
import { statusShop, svgToEmails, typeFilterSelect } from '../../../service/defaultData';
import validate from '../../../validate-forms/Shop/AddFormValidationRules';
import useForm from '../../../validate-forms/useForm';
import { ComboBox } from '../../ComboBox/ComboBox';
import { CustomDatePicker } from '../../CustomDatePicker/CustomDatePicker';
import { ErrorPage } from '../../ErrorPage/ErrorPage';
import { Loading } from '../../Loading/Loading';
import moment from 'moment';
import Warning from '../../Notification/Warning';
import { shopsErrors } from '../../../service/ErrorNotification/shopsErrors';

export const AddOrEdit = ({ agents, senders, shop, actionChange, render, createdOrEditShop, defaultSender, defaultAgent }) => {

  const [errorMessage, setErrorMessage] = useState(false);
  const [loading, setLoading] = useState(true);

  const [optionSender, setOptionSender] = useState([]);
  const [defaultsenderSelected, setDefaultsenderSelected] = useState(null);

  const [optionAgent, setOptionAgent] = useState([]);
  const [defaultAgentSelected, setDefaultAgentSelected] = useState(null);

  const [defaultStatusShop, setDefaultStatusShop] = useState(null);

  const [code_shop, setCode_shop] = useState('');

  const [defaultStartDate, setDefaultStartDate] = useState(null);
  const [defaultStoppedDate, setDefaultStoppedDate] = useState(null);

  const [optionStatusShops, setOptionStatusShops] = useState([]);

  const [statusButton, setStatusButton] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');

  const { values, errors, handleChange, handleSubmit } = useForm(sendForm, validate);

  useEffect(() => {
    if (render === 'edit' && defaultSender && defaultAgent) {
      setDefaultsenderSelected(defaultSender[0]);
      setDefaultAgentSelected(defaultAgent[0]);

      let enabledAndDisabledSenders = filterSelectData(typeFilterSelect.edit, senders, defaultSender);
      setOptionSender(enabledAndDisabledSenders);

      let enabledAndDisabledAgents = filterSelectData(typeFilterSelect.edit, agents, defaultAgent);
      setOptionAgent(enabledAndDisabledAgents);

    }
  }, [defaultSender, defaultAgent])


  useEffect(() => {
    if (render === 'add') {
      (async function () {
        let agents = await selectAgent();
        let senders = await selectSender();
        if (agents.statusResponse && senders.statusResponse) {

          let enabledAgents = filterSelectData(typeFilterSelect.add, agents.data);
          let enabledSenders = filterSelectData(typeFilterSelect.add, senders.data);

          setOptionAgent(enabledAgents);
          setOptionSender(enabledSenders)

        }
      })();
    }

  }, [agents, senders]);

  useEffect(() => {

    if (render === 'add') {
      let statusTemp = statusShop.filter(s => s.value === 'ENA');
      setOptionStatusShops(statusTemp);
      setLoading(false);
    } else {

      let defaultState = statusShop.filter(s => s.value === shop.enable);
      setDefaultStatusShop(defaultState[0]);

      let statusTemp = statusShop.filter(s => s.value !== 'NTF');
      setOptionStatusShops(statusTemp);

      setCode_shop(shop?.code_shop);
      values.id_agent = shop?.id_agent;
      values.code_shop = shop?.code_shop;
      values.id_sender = shop.id_sender;
      values.contract_code = shop?.contract_code;
      values.airport_iata_code = shop?.airport_iata_code;
      values.airport_area = shop?.airport_area;
      values.shop_name = shop?.shop_name;
      values.shop_long_name = shop?.shop_long_name;
      values.local_commercial_name = shop?.local_commercial_name;
      values.vinci_commercial_name = shop?.vinci_commercial_name;
      values.local_businness_category_l1 = shop?.local_businness_category_l1;
      values.local_businness_category_l2 = shop?.local_businness_category_l2;
      values.vinci_businness_category_l1 = shop?.vinci_businness_category_l1;
      values.vinci_businness_category_l2 = shop?.vinci_businness_category_l2;
      values.local_travel_area = shop?.local_travel_area;
      values.vinci_travel_area = shop?.vinci_travel_area;
      values.area_sqm = shop?.area_sqm;
      values.shop_format = shop?.shop_format;
      values.flow_pax = shop?.flow_pax;
      values.terminal = shop?.terminal;
      values.pier = shop?.pier;
      values.location = shop?.location;
      values.shop_floor = shop?.shop_floor;
      values.type_of_service = shop?.type_of_service;
      values.cnpj = shop?.cnpj;
      values.location_number = shop?.location_number;
      values.number_of_pos = shop?.number_of_pos;
      values.max_invoice_time = shop?.max_invoice_time;
      values.date_last_invoice = shop?.date_last_invoice;
      values.document_number = shop?.document_number;
      values.email = shop?.email;
      values.enable = shop?.enable;

      if (shop?.date_activity_started !== undefined) {
        let starDateTemp = moment(shop.date_activity_started).add(6, 'h');
        setDefaultStartDate(new Date(starDateTemp));
        values.date_activity_started = moment(shop.date_activity_started).add(6, 'h').format('YYYY-MM-DD');
      } else {
        values.date_activity_started = null;
      }

      if (shop?.date_activity_stopped !== undefined) {
        let stoppedDateTemp = moment(shop.date_activity_stopped).add(6, 'h');
        setDefaultStoppedDate(new Date(stoppedDateTemp));
        values.date_activity_stopped = moment(shop.date_activity_stopped).add(6, 'h').format('YYYY-MM-DD')
      } else {
        values.date_activity_stopped = null;
      }

    }

  }, [])

  useEffect(() => {
    if (code_shop !== '' && defaultAgentSelected && defaultsenderSelected) {
      setLoading(false);
    }
  }, [code_shop, defaultAgentSelected, defaultsenderSelected, optionSender, optionAgent])

  async function sendForm() {
    setWarningMessage('');
    setStatusButton(true);
    values?.date_activity_started && (values.date_activity_started = moment(values.date_activity_started).format('YYYY-MM-DDTHH:mm:ss'));
    values?.date_activity_stopped && (values.date_activity_stopped = moment(values.date_activity_stopped).format('YYYY-MM-DDTHH:mm:ss'));

    values?.area_sqm && (values.area_sqm = parseInt(values.area_sqm));
    values?.max_invoice_time && (values.max_invoice_time = parseInt(values.max_invoice_time));

    if (render !== 'add') {
      await editRecord(`/shop/${shop.id_shop}`, values).then(request => {
        setStatusButton(false);
        if (request.statusResponse) {
          redirect('edit');
        }
      }).catch(error => getErrors(error));
    } else {
      await createRecord(`/shop`, values).then(request => {
        setStatusButton(false);
        if (request.statusResponse) {
          redirect('created');
        }
      }).catch(error => getErrors(error));
    }

  }

  const getErrors = (error) => {

    setWarningMessage('');
    setErrorMessage(false);

    let respondeError = shopsErrors(error);

    if (respondeError === 'error') return setErrorMessage(true);

    setWarningMessage(respondeError);
    setStatusButton(false);

  }

  const redirect = (action) => {
    createdOrEditShop(action);
    actionChange('table');
  }

  function comboBoxChange(e) {
    handleChange(e);
  }

  const handleCodeChange = ({ target }) => {
    let re = /^[-,0-9]+$/;
    if (re.test(target.value) || target.value === '') {
      setCode_shop(target.value);
      values.code_shop = target?.value;
    }
  }

  const datePickerChange = (e) => handleChange(e);

  const handleStartDate = (date) => values.date_activity_started = date;

  const handleEndDate = (date) => values.date_activity_stopped = date;

  return loading ? <Loading /> : errorMessage ? <ErrorPage redirect={true} actionChange={actionChange} name={'Tiendas'} /> :
    <section className="section form-color">
      <form onSubmit={handleSubmit} noValidate>

        <div className="acction-card"><span aria-hidden="true">
          {render === 'add' ? 'Agregar ' : 'Actualizar '} Tienda</span>
        </div>

        {warningMessage !== '' && <Warning text={warningMessage} />}

        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="code_shop" className="form-label">Código de la Tienda</label>
              <input type="text" className={`input-form form-control ${errors.code_shop && 'is-danger'}`} placeholder="Código de la Tienda" name="code_shop" onChange={handleCodeChange} value={code_shop} required />
              {errors.code_shop && (
                <p className="help is-danger">{errors.code_shop}</p>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="contract_code" className="form-label">Código del Contrato</label>
              <input type="text" className={`input-form form-control ${errors.contract_code && 'is-danger'}`} placeholder="Código del Contrato" name="contract_code" onChange={handleChange} value={values.contract_code || ''} required />
              {errors.contract_code && (
                <p className="help is-danger">{errors.contract_code}</p>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <ComboBox name={"enable"} text={"Estado"} options={optionStatusShops} onChange={comboBoxChange} defaultValueSelect={defaultStatusShop} />
              {errors.enable && (
                <p className="help is-danger">{errors.enable}</p>
              )}
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="shop_long_name" className="form-label">Nombre de la Tienda (largo)</label>
              <input type="text" className={`input-form form-control ${errors.shop_long_name && 'is-danger'}`} placeholder="Nombre" name="shop_long_name" onChange={handleChange} value={values.shop_long_name || ''} required />
              {errors.shop_long_name && (
                <p className="help is-danger">{errors.shop_long_name}</p>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="vinci_commercial_name" className="form-label">Nombre de Local Comercial(Vinci)</label>
              <input type="text" className={`input-form form-control ${errors.vinci_commercial_name && 'is-danger'}`} placeholder="Nombre de Local (Vinci)" name="vinci_commercial_name" onChange={handleChange} value={values.vinci_commercial_name || ''} required />
              {errors.vinci_commercial_name && (
                <p className="help is-danger">{errors.vinci_commercial_name}</p>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="shop_name" className="form-label">Nombre de la Tienda (Corto)</label>
              <input type="text" className={`input-form form-control ${errors.shop_name && 'is-danger'}`} placeholder="Nombre" name="shop_name" onChange={handleChange} value={values.shop_name || ''} required />
              {errors.shop_name && (
                <p className="help is-danger">{errors.shop_name}</p>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="local_commercial_name" className="form-label">Nombre de Local Comercial</label>
              <input type="text" className={`input-form form-control ${errors.local_commercial_name && 'is-danger'}`} placeholder="Nombre de Local" name="local_commercial_name" onChange={handleChange} value={values.local_commercial_name || ''} required />
              {errors.local_commercial_name && (
                <p className="help is-danger">{errors.local_commercial_name}</p>
              )}
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="max_invoice_time" className="form-label">Tiempo Máximo de Facturación (Minutos)</label>
              <input type="number" className={`input-form form-control ${errors.max_invoice_time && 'is-danger'}`} name="max_invoice_time" onChange={handleChange} value={values.max_invoice_time || ''} required />
              {errors.max_invoice_time && (
                <p className="help is-danger">{errors.max_invoice_time}</p>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group coriport-input">
              <div className="d-flex align-items-baseline">
                <label htmlFor="email" className="form-label">Correos</label>
                <div data-toggle="tooltip" data-placement="top" title={`Formato: correos separado por ,`} className="ml-2">
                  <svg style={{ width: '20px', height: '20px' }} viewBox="0 0 20 20">
                    <path d={svgToEmails}></path>
                  </svg>
                </div>
              </div>
              <div style={{ marginTop: '-17.5px' }} className="d-flex align-items-baseline">
                <input autoComplete="off" type="email" className={`input-form form-control ${errors.email && 'is-danger'}`} placeholder="email@email.com,email@email.com,.." name="email" onChange={handleChange} value={values.email || ''} required />
              </div>
              {errors.email && (
                <p className="help is-danger">{errors.email}</p>
              )}
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-6">
            <div className="form-group">
              <ComboBox name={"id_agent"} text={"Agente"} options={optionAgent} onChange={comboBoxChange} defaultValueSelect={defaultAgentSelected} />
              {errors.id_agent && (
                <p className="help is-danger">{errors.id_agent}</p>
              )}
            </div>

          </div>
          <div className="col-md-6">
            <div className="form-group">
              <ComboBox name={"id_sender"} text={"Remitente"} options={optionSender} onChange={comboBoxChange} defaultValueSelect={defaultsenderSelected} />
              {errors.id_sender && (
                <p className="help is-danger">{errors.id_sender}</p>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="flow_pax" className="form-label">Flujo de Personas</label>
              <input type="text" className={`input-form form-control ${errors.flow_pax && 'is-danger'}`} placeholder="Flujo de Personas" name="flow_pax" onChange={handleChange} value={values.flow_pax || ''} required />
              {errors.flow_pax && (
                <p className="help is-danger">{errors.flow_pax}</p>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="number_of_pos" className="form-label">Número de Pos</label>
              <input type="number" className="input-form form-control" placeholder="Número de Pos" name="number_of_pos" onChange={handleChange} value={values.number_of_pos || ''} required />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="cnpj" className="form-label">Identificación del Contrato</label>
              <input type="text" className="input-form form-control" placeholder="Identificación" name="cnpj" onChange={handleChange} value={values.cnpj || ''} required />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="location_number" className="form-label">Número de Ubicación</label>
              <input type="number" className={`input-form form-control ${errors.location_number && 'is-danger'}`} placeholder="Número de Ubicación" name="location_number" onChange={handleChange} value={values.location_number || ''} required />
              {errors.location_number && (
                <p className="help is-danger">{errors.location_number}</p>
              )}
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="location" className="form-label">Ubicación</label>
              <input type="text" className={`input-form form-control ${errors.location && 'is-danger'}`} placeholder="Ubicación" name="location" onChange={handleChange} value={values.location || ''} required />
              {errors.location && (
                <p className="help is-danger">{errors.location}</p>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="airport_area" className="form-label">Área del Aeropuerto</label>
              <input type="text" className={`input-form form-control ${errors.airport_area && 'is-danger'}`} placeholder="Área del Aeropuerto" name="airport_area" onChange={handleChange} value={values.airport_area || ''} required />
              {errors.airport_area && (
                <p className="help is-danger">{errors.airport_area}</p>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="terminal" className="form-label">Terminal</label>
              <input type="text" className={`input-form form-control ${errors.terminal && 'is-danger'}`} placeholder="Terminal" name="terminal" onChange={handleChange} value={values.terminal || ''} required />
              {errors.terminal && (
                <p className="help is-danger">{errors.terminal}</p>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="area_sqm" className="form-label">Metros Cuadrados</label>
              <input type="number" className={`input-form form-control ${errors.area_sqm && 'is-danger'}`} placeholder="Metros" name="area_sqm" onChange={handleChange} value={values.area_sqm || ''} required />
              {errors.area_sqm && (
                <p className="help is-danger">{errors.area_sqm}</p>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="shop_floor" className="form-label">Número de Pisos</label>
              <input type="text" className="input-form form-control" placeholder="Piso" name="shop_floor" onChange={handleChange} value={values.shop_floor || ''} required />

            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="type_of_service" className="form-label">Tipo de Servicio</label>
              <input type="text" className="input-form form-control" placeholder="Servicio" name="type_of_service" onChange={handleChange} value={values.type_of_service || ''} required />

            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="airport_iata_code" className="form-label">Código IATA</label>
              <input type="text" className={`input-form form-control ${errors.airport_iata_code && 'is-danger'}`} placeholder="Código" name="airport_iata_code" onChange={handleChange} value={values.airport_iata_code || ''} required />
              {errors.airport_iata_code && (
                <p className="help is-danger">{errors.airport_iata_code}</p>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="pier" className="form-label">Muelle</label>
              <input type="text" className={`input-form form-control ${errors.pier && 'is-danger'}`} placeholder="Muelle" name="pier" onChange={handleChange} value={values.pier || ''} required />
              {errors.pier && (
                <p className="help is-danger">{errors.pier}</p>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="shop_format" className="form-label">Formato</label>
              <input type="text" className={`input-form form-control ${errors.shop_format && 'is-danger'}`} placeholder="Formato" name="shop_format" onChange={handleChange} value={values.shop_format || ''} required />
              {errors.shop_format && (
                <p className="help is-danger">{errors.shop_format}</p>
              )}
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-6">
            <div className="form-group">
              <CustomDatePicker defaultValue={defaultStartDate} type={'date'} onChange={datePickerChange} name='date_activity_started' placeHolder={'mm/dd/aaaa'} getDate={handleStartDate} title={'Fecha Inicio de Actividades'} />
              {errors.date_activity_started && (
                <p className="help is-danger">{errors.date_activity_started}</p>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <CustomDatePicker defaultValue={defaultStoppedDate} type={'date'} onChange={datePickerChange} name='date_activity_stopped' placeHolder={'mm/dd/aaaa'} getDate={handleEndDate} title={'Fecha Finalización de Actividades'} />
              {errors.date_activity_stopped && (
                <p className="help is-danger">{errors.date_activity_stopped}</p>
              )}
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="local_businness_category_l1" className="form-label">Categoría del Local L1</label>
              <input type="text" className={`input-form form-control ${errors.local_businness_category_l1 && 'is-danger'}`} placeholder="Categoría del Local L1" name="local_businness_category_l1" onChange={handleChange} value={values.local_businness_category_l1 || ''} required />
              {errors.local_businness_category_l1 && (
                <p className="help is-danger">{errors.local_businness_category_l1}</p>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="local_businness_category_l2" className="form-label">Categoría del Local L2</label>
              <input type="text" className={`input-form form-control ${errors.local_businness_category_l2 && 'is-danger'}`} placeholder="Categoría del Local L2" name="local_businness_category_l2" onChange={handleChange} value={values.local_businness_category_l2 || ''} required />
              {errors.local_businness_category_l2 && (
                <p className="help is-danger">{errors.local_businness_category_l2}</p>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="local_travel_area" className="form-label">Área de Viaje Local</label>
              <input type="text" className={`input-form form-control ${errors.local_travel_area && 'is-danger'}`} placeholder="Área de Viaje Local" name="local_travel_area" onChange={handleChange} value={values.local_travel_area || ''} required />
              {errors.local_travel_area && (
                <p className="help is-danger">{errors.local_travel_area}</p>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="vinci_businness_category_l1" className="form-label">Categoría del Negocio L1(Vinci)</label>
              <input type="text" className={`input-form form-control ${errors.vinci_businness_category_l1 && 'is-danger'}`} placeholder="Categoría del Negocio L1" name="vinci_businness_category_l1" onChange={handleChange} value={values.vinci_businness_category_l1 || ''} required />
              {errors.vinci_businness_category_l1 && (
                <p className="help is-danger">{errors.vinci_businness_category_l1}</p>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="vinci_businness_category_l2" className="form-label">Categoría del Negocio L2(Vinci)</label>
              <input type="text" className={`input-form form-control ${errors.vinci_businness_category_l2 && 'is-danger'}`} placeholder="Categoría del Negocio L2" name="vinci_businness_category_l2" onChange={handleChange} value={values.vinci_businness_category_l2 || ''} required />
              {errors.vinci_businness_category_l2 && (
                <p className="help is-danger">{errors.vinci_businness_category_l2}</p>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="vinci_travel_area" className="form-label">Área de Viaje (Vinci)</label>
              <input type="text" className={`input-form form-control ${errors.vinci_travel_area && 'is-danger'}`} placeholder="Área de Viaje" name="vinci_travel_area" onChange={handleChange} value={values.vinci_travel_area || ''} required />
              {errors.vinci_travel_area && (
                <p className="help is-danger">{errors.vinci_travel_area}</p>
              )}
            </div>
          </div>
        </div>

        <div className="container-bottons">
          <button disabled={statusButton} id='custom-btn-coriport' className=" btn  btn-coriport btn-add text-white " type="submit">
            {
              statusButton ? <div className="container-spinner-loading pt-2">
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              </div> : <p className="label-button-add"> {render === 'add' ? 'Agregar' : 'Actualizar'}</p>
            }
          </button>
          <button className="btn-coriport btn-cancel" onClick={() => actionChange('table')}>
            Cancelar
          </button>
        </div>

      </form>
    </section >
}
