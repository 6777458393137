import React from "react";
import "./ErrorPage.css";

export class ErrorPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        let { name } = this.props;
        return (
            <>
                <div className="flex-center" style={{ marginTop: '15vh' }}   >
                    <div className="code"> Error </div>
                    <div className="message" >
                        En este momento no esta disponible el mantenimiento de {name}
                    </div>

                </div>
                {
                    this.props.redirect && <div className="flex-center mt-4">
                        <button
                            className="btn  btn-coriport btn-add text-white "
                            onClick={() => { this.props.actionChange('table') }}
                        >
                            Volver
                        </button>
                    </div>
                }


            </>
        );
    }
}