export const productGroupVinciErrors = ({ error, data }) => {
    let message = "";

    if (error === 500) return message = "error";

  
    if (error === 400 && data.message === 'A vinci product group already exists with the submitted group name and id vinci product category') {
        message = 'El nombre y categoría de vinci ingresados ya existen';
    }
    if (error === 400 && data.message === 'The id product category does not exist in the vinci product category table, please check') {
        message = 'La categoría ingresada no existe';
    }
    if (error === 400 && data.message === 'Please contact the site administrator since the category you want to insert is disabled') {
        message = 'La categoría ingresada está desactivada';
    } 
    if (error === 404) {
        message = 'El grupo de vinci ingresado no existe';
    } 

    if (error === 400 && data.message === 'A vinci product group already exists with the submitted group name') {
        message = 'El nombre y la categoría ingresados ya existen';
    }
    if (error === 400 && data.message === 'The id product category does not exist in the vinci product category table, please check') {
        message = 'La categoría ingresada no existe';
    }

    if (error === 400 && data.message === 'The vinci product group you want to disable was already disabled') {
        message = 'El grupo de vinci ya está desactivado';
    }

    if(error === 400 && data.message.includes('record(s) of the table productGroup')) return message = 'El grupo de vinci posee grupo de producto asociados';

    if(error === 400 && data.message.includes('record(s) of the table vinciProductSubgroup')) return message = 'El grupo de vinci posee sub-grupos asociados';

    return message;

};
