import './Loading.css';
import React from 'react';

export class Loading extends React.Component {
    render() {
        return (
            <div className="box">
                <div>    
                    <div className="loading mt-5 mb-4">
                    <div></div>
                    <div></div>
                </div> 
                </div>
            </div>
        );
    }
}