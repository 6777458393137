 
export default function validate(values) {

    let errors = {};
    /* eslint-disable no-useless-escape */
    const regexEmail = /^((([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,}))(,(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,}))*$/g;

  
    if (!values.parameter_type) {
        errors.parameter_type = '*El tipo es requerido';
    }
    if (!values.parameter_value) {
        errors.parameter_value = '*Los correos son requerido';
    }
    if (values.enable === undefined) {
        errors.enable = '*El estado es requerido';
    }
    if (values.parameter_value) {
        if (regexEmail.test(values.parameter_value) === false) {
          errors.parameter_value = "*Debe ingresar un correo valido";
        }
      }

    return errors;
}