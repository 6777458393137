import React, { useEffect, useState } from "react";
import { SideNavBar } from "../SideNavBar/SideNavBar";
import { Content } from "../Content/Content";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";


export const Dashboard = () => {
   
  const [page, setPage] = useState('');
  const [showHideSidenav, setShowHideSidenav] = useState('hidden');

  const toggleSidenav = () => {
    let css = (showHideSidenav === "hidden") ? "toggled" : "hidden";
    setShowHideSidenav(css);
  }

  const changePage = (action) => { 
    setPage(action);

  }

  return (
    <div id="wrapper" className={showHideSidenav}>
      <SideNavBar funtionChangePage={changePage} />
      <Header funtionToggleSideNav={toggleSidenav} />
      <Content funtionChangePage={changePage} action={page} />
      <Footer />
    </div>
  );
}