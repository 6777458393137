import Cookies from "universal-cookie";
import { saveCookie } from "../../service/login";

let urlAuth = process.env.REACT_APP_COGNITO_OAUTH;
let urlReactApp = process.env.REACT_APP_COGNITO_DOMAIN;
let cliendId = process.env.REACT_APP_COGNITO_CLIENT_ID;

const cookies = new Cookies();

export const getUserSession = () => {
  let user = cookies.get("user");
  let idToken = cookies.get("id_token");
  let dateExp = sessionStorage.getItem("exp");
  if (user && idToken && dateExp) {
    return { user, idToken, dateExp };
  }
};

export const authenticate = (credential) => {
  let access_token = credential.accessToken.jwtToken;
  let user = credential.accessToken.payload.username;
  let  id_token = credential.idToken.jwtToken;
  let  refresh_token = credential.refreshToken.token;
  let  exp = credential.accessToken.payload.exp;

  //let paseJSON = JSON.parse(credential);

  let expTemp = exp * 1000;

  sessionStorage.setItem("exp", new Date(expTemp));

  saveCookie("user", user, { path: "/", secure: true });
  saveCookie("access_token", access_token, {
    path: "/",
    secure: true,
  });
  saveCookie("id_token", id_token, { path: "/", secure: true });
  if (refresh_token !== null  || refresh_token !== undefined) {
    saveCookie("refresh_token", refresh_token, {
      path: "/",
      secure: true,
    });
  }
};

export const logout = () => {
  cookies.remove("user");
  cookies.remove("id_token");
  cookies.remove("access_token");
  cookies.remove("refresh_token");
  cookies.remove("code_verifier");
  cookies.remove("code");
  window.location = `${urlAuth}/logout?client_id=${cliendId}&logout_uri=${urlReactApp}`;
};

export const removeCodesAuth = () => {
  cookies.remove("code_verifier");
  cookies.remove("code");
};

export const isAuthenticated = () => {
  let response = false;
  let token = cookies.get("id_token");
  if (token !== undefined ) {
    return (response = true);
  } else {
    return response;
  }
};
