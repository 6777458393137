export const array_equal = (arr1, arr2) => {
    if ((Array.isArray(arr1) && Array.isArray(arr2)) === false) return false;
    return JSON.stringify([...new Set(arr1.flat().sort())]) === JSON.stringify([...new Set(arr2.flat().sort())]);
}

export const statusShop = [
    { value: 'ENA', label: 'Habilitado' },
    { value: 'DIS', label: 'Deshabilitado' },
    { value: 'NTF', label: 'Notificado' },
    { value: 'ATT', label: 'Atención' }
];

export const statusAgent = [
    { value: 'ENA', label: 'Habilitado' },
    { value: 'DIS', label: 'Deshabilitado' },
    { value: 'NTF', label: 'Notificado' },
    { value: 'ATT', label: 'Atención' }
]

export const selectEnable = [
    { value: true, label: "Habilitado" },
    { value: false, label: "Deshabilitado" },
];

export const selectStatusShop = [
    { value: true, label: "Apertura" },
    { value: false, label: "Cierre" },
];

export const selectTypeSearchParams = [
    { value: "invoice_month", label: "Mes" },
    { value: "invoice_year", label: "Año" },
    { value: "date_issue", label: "Fecha" },
    { value: "id_voucher_type", label: "Tipo Documento" },
];

export const selectMonth = [
    { value: "", label: "Seleccione" },
    { value: "1", label: "Enero" },
    { value: "2", label: "Febrero" },
    { value: "3", label: "Marzo" },
    { value: "4", label: "Abril" },
    { value: "5", label: "Mayo" },
    { value: "6", label: "Junio" },
    { value: "7", label: "Julio" },
    { value: "8", label: "Agosto" },
    { value: "9", label: "Septiembre" },
    { value: "10", label: "Octubre" },
    { value: "11", label: "Noviembre" },
    { value: "12", label: "Diciembre" },
];

export const selectDays = [
    { value: "Monday", label: "Lunes" },
    { value: "Tuesday", label: "Martes" },
    { value: "Wednesday", label: "Miercoles" },
    { value: "Thursday", label: "Jueves" },
    { value: "Friday", label: "Viernes" },
    { value: "Saturday", label: "Sábado" },
    { value: "Sunday", label: "Domingo" },
];

export const selectInstalled = [
    { value: true, label: "Colector" },
    { value: false, label: "Web Service" },
];

export const itemsModalShop = [
    {
        title: "Información",
        path: "info",
    },
    {
        title: "Ingresos",
        path: "revenues",
    },
    {
        title: "Horarios",
        path: "schedule",
    },
];

export const typeFilterSelect = {
    get: 'get-all',
    add: 'add',
    edit: 'edit'
}

export const typeFileReportDownload = [
    { value: 'detailedsales', label: "Ventas Detalladas" },
    { value: 'dailysales', label: "Ventas Diarias" },
    { value: 'consolidatedsales', label: "Ventas Consolidadas" },
    { value: 'dailybyagent', label: "Ventas Diarias por Agente" },
    { value: 'consolidatedbyagent', label: "Ventas Consolidadas por Agente" },
    { value: 'products', label: "Productos" },
    { value: 'unclassifiedproducts', label: 'Productos sin clasificar'},
    { value: 'vincicategories', label: 'Categorías VINCI'},
    { value: 'shops', label: "Tiendas" }
]

export const typeFileReportUpload = [
    { value: 'dailysales', label: "Ventas Diarias" },
    { value: 'detailedsales', label: "Ventas Detalladas" },
    { value: 'consolidatedsales', label: "Ventas Consolidadas" },
    { value: 'products', label: "Productos" },
    { value: 'shops', label: "Tiendas" }
]


export const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};
export const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};

export const svgToEmails = "M12.871,9.337H7.377c-0.304,0-0.549,0.246-0.549,0.549c0,0.303,0.246,0.55,0.549,0.55h5.494c0.305,0,0.551-0.247,0.551-0.55C13.422,9.583,13.176,9.337,12.871,9.337z M15.07,6.04H5.179c-0.304,0-0.549,0.246-0.549,0.55c0,0.303,0.246,0.549,0.549,0.549h9.891c0.303,0,0.549-0.247,0.549-0.549C15.619,6.286,15.373,6.04,15.07,6.04z M17.268,1.645H2.981c-0.911,0-1.648,0.738-1.648,1.648v10.988c0,0.912,0.738,1.648,1.648,1.648h4.938l2.205,2.205l2.206-2.205h4.938c0.91,0,1.648-0.736,1.648-1.648V3.293C18.916,2.382,18.178,1.645,17.268,1.645z M17.816,13.732c0,0.607-0.492,1.1-1.098,1.1h-4.939l-1.655,1.654l-1.656-1.654H3.531c-0.607,0-1.099-0.492-1.099-1.1v-9.89c0-0.607,0.492-1.099,1.099-1.099h13.188c0.605,0,1.098,0.492,1.098,1.099V13.732z"