import Cookies from "universal-cookie"; 
import { encode as encodeTemp } from "base64-arraybuffer";

export const generateCodeVerifier = () => {
  let result = "",
    seeds;

  for (let i = 0; i < 50 - 1; i++) {
    seeds = [
      Math.floor(Math.random() * 10) + 48,
      Math.floor(Math.random() * 25) + 65,
      Math.floor(Math.random() * 25) + 97,
    ];
    result += String.fromCharCode(seeds[Math.floor(Math.random() * 3)]);
  }
  return result;
};

export const generateCodeChallenge = async (codeVerifier) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  const digest = await window.crypto.subtle.digest("SHA-256", data);
  const toBase64 = encodeTemp(digest);

  let toBase64Temp = toBase64
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");

  return toBase64Temp;
};

export const saveCookie = (name, value, object) => {
  const cookies = new Cookies();
  cookies.set(name, value, object);
};
