export default function validate(values){
    
    let errors = {};
    
    if(!values.category_name){
        errors.category_name = '*El nombre de la categoría es requerido'
    }
    if(values.enable === undefined){
        errors.enable = '*El estado es requerido'
    }


    return errors;
}