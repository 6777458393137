import React from "react";
import { getAll } from "../../request/Request";
import "./Monitor.css";
import moment from 'moment';
import DataTableMaterial from "../DataTableMaterial/DataTableMaterial";
import { HeaderTable } from "../HeaderTable";
import { customColumn } from "../../service/dataTable";

export class Monitor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            dataTable: {
                data: [] 
            },
            errorsRequest: {
                errorMessage: '',
                errorStatus: false
            },
        } 
    }

    componentDidMount = () => this.getMonitoring();

    async getMonitoring() {

        await getAll(`/monitoring`).then(request => {
            let { data } = request;
            this.setState({ dataTable: { data: data.data } });

        }).catch(error => {
            if (error.error === 500) this.setState({ error: true });

        });
        this.setState({ loading: false });

    }

    materialDataTable() {

        let columns = [
            {
                ...customColumn('Agentes', row => row.client_name),
                cell: row => <p className="capitalize-text">{row.client_name}</p>,
            },
            {
                ...customColumn('Tiendas Activas', row => row.actives),
                cell: row => <p className="capitalize-text">
                    {`${row.actives}/${row.shops?.length > 0 ? `${row.shops?.length}` : 0}`}
                </p>,
            },
            {
                ...customColumn('Última Comunicación', row => row.date_last_ping),
                cell: row => <p className="capitalize-text">
                    {row.date_last_ping ? moment(row.date_last_ping).format('DD/MM/YYYY, h:mm a') : ''}
                </p>
            },
            {
                ...customColumn('Estado'),
                cell: (row) =>
                    <span onClick={() => { (row.active_agent) && this.handleEnable(row) }} className={(row.active_agent) ? `monitor-status` : 'inactive-status'} style={{ width: '76px' }}>
                        {(row.installed) ? 'Colector' : 'Web-Service'}
                    </span>
            }
        ];

        let { dataTable, loading, errorsRequest } = this.state;
        return <DataTableMaterial
            dataTableData={dataTable}
            loading={loading}
            errors={errorsRequest}
            columns={columns}
            paginate={false}
            getRowsPerPage={this.getRowsPerPage}
            getCurrentPage={this.getCurrentPage}
            expandableRowsComponent={true}
        />;
    }

    render() {

        return <div className="col-lg-12 mt-5">
            {<div className="card-table">
                <HeaderTable title={'Monitoreo'} />
                <div className="mb-5 mt-4"> {this.materialDataTable()} </div>
            </div>}
        </div>

    }
}
