import axios from "axios";
import Cookies from "universal-cookie";
import {
  authenticate,
  getUserSession,
  logout,
} from "../components/Accounts/Accounts";

const cookies = new Cookies();

export const createRequest = async (method, uri, body, headers) => {
  const result = { statusResponse: false, data: null, error: null };

  let { dateExp } = getUserSession();
  let dateExpTemp = new Date(dateExp);
  let dateMinutes = dateExpTemp.getMinutes();
  dateExpTemp.setMinutes(dateMinutes - 1);

  if (dateExpTemp <= new Date()) {
    await refreshToken()
      .then((response) => {
        if (response.status === 200) {
          authenticate(response.data);
        }
      })
      .catch((error) => {
        logout();
      });
  }

  let toke = cookies.get("id_token");

  let config = {
    method: method.toLowerCase(),
    url: uri,
    data: body,
    baseURL: process.env.REACT_APP_URL_API_CORIPORT,
    headers: {
      Authorization: "Bearer " + toke,
    },
    validateStatus: function (status) {
      if (status === 401 || status === 403) {
        logout();
      } else {
        return status >= 200 && status < 400;
      }
    },
  };

  return new Promise(function (resolve, reject) {
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          result.statusResponse = true;
          result.data = response.data;
          resolve(result);
        }
      })
      .catch((error) => {
        result.statusResponse = false;
        result.error = error;
        reject(result);
      });
  });
};

export const createRequest2 = async (method, uri, body, headers) => {
  const result = { statusResponse: false, data: null, error: null };

  let { dateExp } = getUserSession();
  let dateExpTemp = new Date(dateExp);
  let dateMinutes = dateExpTemp.getMinutes();
  dateExpTemp.setMinutes(dateMinutes - 1);

  if (dateExpTemp <= new Date()) {
    await refreshToken()
      .then((response) => {
        if (response.status === 200) {
          authenticate(response.data);
        }
      })
      .catch((error) => {
        logout();
      });
  }

  let toke = cookies.get("id_token");

  let config = {
    method: method.toLowerCase(),
    url: uri,
    data: body,
    headers: {
      "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
  };

  return new Promise(function (resolve, reject) {
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          
          result.statusResponse = true;
          result.data = response.data;
          resolve(result);
        }
      })
      .catch((error) => {
        console.log(error);
        result.statusResponse = false;
        result.error = error;
        reject(result);
      });
  });
};
export const uploadFileURL = (uri) => {
  const result = { statusResponse: false, data: null, error: null };
  return new Promise(function (resolve, reject) {
    createRequest("GET", uri)
      .then((request) => {
        resolve(request);
      })
      .catch((error) => {
        if (error.error) {
          result.statusResponse = false;
          result.error = error.error.response?.status;
          result.data = error.error.response?.data;
          reject(result);
        }
      });
  });
};
export const uploadFileName = (uri, body) => {
  const result = { statusResponse: false, data: null, error: null };
  return new Promise(function (resolve, reject) {
    createRequest2("PUT", uri, body)
      .then((request) => {
        resolve(request);
      })
      .catch((error) => {
        if (error.error) {
          result.statusResponse = false;
          result.error = error.error.response?.status;
          result.data = error.error.response?.data;
          reject(result);
        }
      });
  });
};
export const uploadFile = (uri, body) => {
  const result = { statusResponse: false, data: null, error: null };
  return new Promise(function (resolve, reject) {
    createRequest("POST", uri, body)
      .then((request) => {
        resolve(request);
      })
      .catch((error) => {
        if (error.error) {
          result.statusResponse = false;
          result.error = error.error.response?.status;
          result.data = error.error.response?.data;
          reject(result);
        }
      });
  });
};
export const getAll = (uri) => {
  const result = { statusResponse: false, data: null, error: null };
  return new Promise(function (resolve, reject) {
    createRequest("GET", uri)
      .then((request) => {
        resolve(request);
      })
      .catch((error) => {
        if (error.error) {
          result.statusResponse = false;
          result.error = error.error.response?.status;
          result.data = error.error.response.data;
          reject(result);
        }
      });
  });
};
export const deleteRecord = (uri) => {
  const result = { statusResponse: false, data: null, error: null };
  return new Promise(function (resolve, reject) {
    createRequest("DELETE", uri)
      .then((request) => {
        resolve(request);
      })
      .catch((error) => {
        if (error.error) {
          result.statusResponse = false;
          result.error = error.error.response.status;
          result.data = error.error.response.data;
          reject(result);
        }
      });
  });
};
export const getReportServer = (uri, body) => {
  const result = { statusResponse: false, data: null, error: null };
  return new Promise(function (resolve, reject) {
    createRequest("POST", uri, body)
      .then((request) => {
        resolve(request);
      })
      .catch((error) => {
        if (error.error) {
          result.statusResponse = false;
          result.error = error.error.response.status;
          result.data = error.error.response.data;
          reject(result);
        }
      });
  });
};
export const createRecord = (uri, body) => {
  const result = { statusResponse: false, data: null, error: null };
  return new Promise(function (resolve, reject) {
    createRequest("POST", uri, body)
      .then((request) => {
        resolve(request);
      })
      .catch((error) => {
        if (error.error) {
          result.statusResponse = false;
          result.error = error.error.response.status;
          result.data = error.error.response.data;
          reject(result);
        }
      });
  });
};
export const editRecord = (uri, body) => {
  const result = { statusResponse: false, data: null, error: null };
  return new Promise(function (resolve, reject) {
    createRequest("PUT", uri, body)
      .then((request) => {
        resolve(request);
      })
      .catch((error) => {
        if (error.error) {
          result.statusResponse = false;
          result.error = error.error.response.status;
          result.data = error.error.response.data;
          reject(result);
        }
      });
  });
};
export const disableRecord = (uri, body) => {
  const result = { statusResponse: false, data: null, error: null };
  return new Promise(function (resolve, reject) {
    createRequest("PATCH", uri, body)
      .then((request) => {
        resolve(request);
      })
      .catch((error) => {
        if (error.error) {
          result.statusResponse = false;
          result.error = error.error.response.status;
          result.data = error.error.response.data;
          reject(result);
        }
      });
  });
};
export const findRecord = (uri) => {
  const result = { statusResponse: false, data: null, error: null };
  return new Promise(function (resolve, reject) {
    createRequest("GET", uri)
      .then((request) => {
        resolve(request);
      })
      .catch((error) => {
        if (error.error) {
          result.error = error.error.response?.status;
          reject(result);
        }
      });
  });
};
export const sftpDownload = (uri, body) => {
  const result = { statusResponse: false, data: null, error: null };
  return new Promise(function (resolve, reject) {
    createRequest("POST", uri, body)
      .then((request) => {
        resolve(request);
      })
      .catch((error) => {
        if (error.error) {
          result.error = error;
          reject(result);
        }
      });
  });
};

export const login = async () => {
  let cliendId = process.env.REACT_APP_COGNITO_CLIENT_ID;
  let urlReactApp = process.env.REACT_APP_COGNITO_DOMAIN;
  let urlAuth = process.env.REACT_APP_COGNITO_OAUTH;

  const params = new URLSearchParams();
  const cookies = new Cookies();

  let code = cookies.get("code");
  let code_verifier = cookies.get("code_verifier");

  if (code && code_verifier) {
    params.append("client_id", cliendId);
    params.append("code", code);
    params.append("code_verifier", code_verifier);
    params.append("grant_type", "authorization_code");
    params.append("redirect_uri", urlReactApp);

    const response = await axios.post(`${urlAuth}/oauth2/token`, params, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    return response;
  }
};

export const refreshToken = async () => {
  let cliendId = process.env.REACT_APP_COGNITO_CLIENT_ID;
  let urlAuth = process.env.REACT_APP_COGNITO_OAUTH;

  const params = new URLSearchParams();
  const cookies = new Cookies();

  let refresh_token = cookies.get("refresh_token");

  params.append("client_id", cliendId);
  params.append("grant_type", "refresh_token");
  params.append("refresh_token", refresh_token);

  const response = await axios.post(`${urlAuth}/oauth2/token`, params, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });

  return response;
};
