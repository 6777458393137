import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import "./DataTableMaterial.css";
import { Loading } from "../Loading/Loading";
import { ErrorPage } from "../ErrorPage/ErrorPage";
import moment from 'moment';
import { paginationComponentOptions } from "../../service/dataTable";


const DataTableMaterial = (props) => {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const [columns, setColumns] = useState([]);
  const [errors, setErrors] = useState({});
  const [expanded, setexpanded] = useState(false);
  const [shopsExpanded, setShopsExpanded] = useState(false);
  const [paginate, setPaginate] = useState(true);

  useEffect(() => {
    setData(props.dataTableData.data);
    setTotalRows(props.dataTableData.total);
    setPerPage(props.dataTableData.perPage);
    setLoading(props.loading);
    setColumns(props.columns);
    setErrors(props.errors);
    setexpanded(props.expandableRowsComponent);

    if (props.paginate === false) {
      setPaginate(props.paginate);
    } else {
      setPaginate(true);
    }

    data.map(d => {
      if (d.shops?.length !== undefined) {
        setShopsExpanded(true);
      }
    })

  }, [props, paginate]);


  const handlePageChange = page => {
    props.getCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage) => {
    props.getRowsPerPage(newPerPage);
  };

  let columnsExpaded = [
    {
      name: "Tienda",
      selector: row => row.shop_name,
      cell: row => <p className="capitalize-text">{row.shop_name}</p>,
      sortable: true,
      reorder: true
    },
    {
      name: "Fecha Última Factura",
      selector: row => row.added_date,
      cell: row => <p className="capitalize-text">  {row.date_last_invoice ? moment(row.date_last_invoice).format('DD/MM/YYYY, h:mm a') : ''} </p>,
      sortable: true,
      reorder: true
    },
    {
      name: "Monitoreo",
      selector: row => row.active_shop,
      sortable: true,
      reorder: true,
      cell: (row) =>
        <span className={
          (row.active_agent && row.active_shop) ? ` active-status-monitor disabled-cursor-pointer` :
            (!row.active_agent && !row.active_shop) ? 'inactive-status' :
              (row.active_agent && !row.active_shop) ? 'orange-status-monitor' : ''
        }> {
            row.active_shop ? 'Activo' : 'Inactivo'
          } </span>
      ,
    },

  ];

  const ExpandedComponent = ({ data }) => {
    if (data.shops) {

      data?.shops.map(record => {
        record.active_agent = data.active_agent;
      });

      return <div className="ml-4 mb-3">
        <br />
        <span className="ml-3 mt-4">Listado de tiendas</span>
        <DataTable
          columns={columnsExpaded}
          data={data.shops}
        />
      </div>;

    } else {
      return <div className="mt-4 mb-5" style={{ justifyContent: 'center', textAlign: 'center' }}><h5>El agente no posee tiendas asociadas</h5></div>
    }

  };

  return loading ? <Loading /> : errors.errorStatus ? <ErrorPage name={errors.errorMessage} /> : <div>
    <DataTable
      columns={columns}
      data={data}
      progressPending={loading}
      pagination={paginate}
      paginationServer
      paginationTotalRows={totalRows}
      paginationComponentOptions={paginationComponentOptions}
      onChangeRowsPerPage={handlePerRowsChange}
      paginationPerPage={perPage}
      onChangePage={handlePageChange}
      noDataComponent={'No hay registros por mostrar'}
      expandableRows={data.length > 0 && expanded && shopsExpanded}
      expandableRowsComponent={ExpandedComponent}
      defaultSortFieldId={data.length}
    />
  </div>
};
export default DataTableMaterial;