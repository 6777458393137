import React, { useEffect, useState } from 'react';
import './Search.css';

export const Search = ({ handleSearch, defaultValue }) => {

    const [value, setValue] = useState('');
 
    useEffect(() => {
        if (defaultValue) {
            setValue(defaultValue);
            handleSearch(defaultValue);
        }
    }, [defaultValue]);
  
    const handleInput = (e) => {
        setValue(e.target.value);
        handleSearch(e.target.value);
    }

    return (
        <>
            <label htmlFor="group_name" className="form-label">Buscar</label>
            <input
                autoComplete='off'
                type="text"
                className="input-form form-control"
                placeholder="Buscar"
                onChange={handleInput}
                value={value} 
            />
        </>
    )
}
