import React, { useEffect, useState } from "react";
import useForm from "../../../validate-forms/useForm";
import validate from "../../../validate-forms/ProductSubGroup/AddFormValidationRules";
import { Loading } from "../../Loading/Loading";
import { ErrorPage } from "../../ErrorPage/ErrorPage";
import './AddOrEdit.css';
import { ComboBox } from "../../ComboBox/ComboBox";
import { createRecord, editRecord } from "../../../request/Request";
import { selectProductCategory, selectProductGroup, selectProductSubGroupVinci, getDefaultOptionSelect, filterSelectData } from "../../../service/comboBox";
import { selectEnable, typeFilterSelect } from "../../../service/defaultData";
import Warning from "../../Notification/Warning";
import { productSubGroupErrors } from "../../../service/ErrorNotification/productSubGroupErrors";

export const AddOrEdit = ({ actionChange, createdOrEditSubGroup, subGroup, render, agents }) => {

    const [errorMessage, setErrorMessage] = useState(false);
    const [loading, setLoading] = useState(true);

    const [productCategories, setProductCategories] = useState([]);
    const [productGroups, setProductGroups] = useState([]);
    const [productSubGroupVinci, setProductSubGroupVinci] = useState([]);
    const [productCategoryVinci, setProductCategoryVinci] = useState(null);
    const [agentSelected, setAgentSelected] = useState(null);

    const [defaultAgent, setDefaultAgent] = useState('');
    const [defaultCategory, setDefaultCategory] = useState('');
    const [defaultGroup, setDefaultGroup] = useState('');
    const [defaultSubGroupVinci, setDefaultSubGroupVinci] = useState('');
    const [defaultEnable, setDefaultEnable] = useState(null);

    const [filteredAgent, setFilteredAgent] = useState([]);

    const [resetSelectProductCategory, setResetSelectProductCategory] = useState(false);
    const [resetSelectProductGroup, setResetSelectProductGroup] = useState(false);
    const [resetSelectProductSubGroupVinci, setResetSelectProductSubGroupVinci] = useState(false);

    const [errorMessageCategory, setErrorMessageCategory] = useState('');
    const [errorMessageGroup, setErrorMessageGroup] = useState('');
    const [errorMessageSubGroupVinci, setErrorMessageSubGroupVinci] = useState('');
 
    const { values, errors, handleChange, handleSubmit } = useForm(sendForm, validate);

    const [statusButton, setStatusButton] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');

    useEffect(() => {
        if (render === 'edit') {

            (async function () {

                let id_vinci_product_category = 0;
                let id_vinci_product_Group = 0;

                setAgentSelected(subGroup.id_agent);

                let defaultAgentTemp = getDefaultOptionSelect(agents, subGroup.id_agent);
                setDefaultAgent(defaultAgentTemp[0]);

                let enabledAndDisabledAgents = filterSelectData(typeFilterSelect.edit, agents, defaultAgentTemp);
                setFilteredAgent(enabledAndDisabledAgents);

                let productCategories = await selectProductCategory(subGroup.id_agent);
                if (productCategories.statusResponse) {

                    let defaultCategoryTemp = getDefaultOptionSelect(productCategories.data, subGroup.id_product_category);

                    let categoriesEnableAndDisabled = filterSelectData(typeFilterSelect.edit, productCategories.data, defaultCategoryTemp);
                    setProductCategories(categoriesEnableAndDisabled);

                    if (defaultCategoryTemp.length > 0 && subGroup.id_product_category) {
                        id_vinci_product_category = defaultCategoryTemp[0]?.id_vinci_product_category;
                        setDefaultCategory(defaultCategoryTemp[0]);
                    } else
                        setDefaultCategory([]);

                }

                let productGroups = await selectProductGroup(subGroup.id_agent, subGroup.id_product_category);
                if (productGroups.statusResponse) {

                    let defaultGroup = getDefaultOptionSelect(productGroups.data, subGroup.id_product_group);


                    let enableAndDisabledGroups = filterSelectData(typeFilterSelect.edit, productGroups.data, defaultGroup);
                    setProductGroups(enableAndDisabledGroups);

                    if (defaultGroup.length > 0 && subGroup.id_product_group) {
                        id_vinci_product_Group = defaultGroup[0]?.id_vinci_product_group;
                        setDefaultGroup(defaultGroup[0]);
                    } else
                        setDefaultGroup([]);

                }

                let ProductSubGropsVinci = await selectProductSubGroupVinci(id_vinci_product_category, id_vinci_product_Group);
                if (ProductSubGropsVinci.statusResponse) {

                    let defaultSubGroup = getDefaultOptionSelect(ProductSubGropsVinci.data, subGroup.id_vinci_product_subgroup);

                    let enabledAndDisabledSubGroups = filterSelectData(typeFilterSelect.edit, ProductSubGropsVinci.data, defaultSubGroup); 
                    setProductSubGroupVinci(enabledAndDisabledSubGroups);

                    if (defaultSubGroup.length > 0 && subGroup.id_vinci_product_subgroup) {
                        setDefaultSubGroupVinci(defaultSubGroup[0]);
                    } else
                        setDefaultSubGroupVinci([]);

                }

                let defaultEnableTemp = getDefaultOptionSelect(selectEnable, subGroup.enable);
                setDefaultEnable(defaultEnableTemp[0]);


            })();

        }
    }, []);

    useEffect(() => {

        if (render === 'add') {

            let enabledAgents = filterSelectData(typeFilterSelect.add, agents);
            setFilteredAgent(enabledAgents);

            setLoading(false);
        } else {

            values.enable = subGroup.enable;
            values.id_agent = subGroup.id_agent;
            values.id_product_category = subGroup.id_product_category;
            values.id_product_group = subGroup.id_product_group;
            values.id_vinci_product_subgroup = subGroup.id_vinci_product_subgroup;
            values.subgroup_code = subGroup.subgroup_code;
            values.subgroup_name = subGroup.subgroup_name;

        }

    }, [defaultAgent, defaultCategory, defaultGroup, defaultSubGroupVinci]);

    useEffect(() => {
        if (defaultAgent && defaultCategory && defaultGroup && defaultSubGroupVinci && defaultEnable && render === 'edit') {
            setLoading(false);
        }
    }, [defaultAgent, defaultCategory, defaultGroup, defaultSubGroupVinci, defaultEnable])


    async function sendForm() {
        setStatusButton(true);
        setWarningMessage('');
        if (render === 'add') {
            await createRecord(`/productsubgroup`, values).then(request => {
                setStatusButton(false);
                if (request.statusResponse) {
                    redirect('created');
                }
            }).catch(error => getErrors(error));

        } else {
            await editRecord(`/productsubgroup/${subGroup.id_product_subgroup}`, values).then(request => {
                setStatusButton(false);
                if (request.statusResponse) {
                    redirect('edit');
                }
            }).catch(error => getErrors(error));

        }

    }

    const getErrors = (error) => {

        setWarningMessage('');
        setErrorMessage(false);

        let respondeError = productSubGroupErrors(error);

        if (respondeError === 'error') return setErrorMessage(true);

        setWarningMessage(respondeError);
        setStatusButton(false);

    }

    const redirect = (action) => {
        createdOrEditSubGroup(action);
        actionChange('table');
    }

    const getProductCategory = async (id_agent) => {
        let productCategories = await selectProductCategory(id_agent);

        setResetSelectProductCategory(false);
        setResetSelectProductGroup(false);
        setResetSelectProductSubGroupVinci(false);

        if (productCategories.statusResponse) {
            setErrorMessageCategory('');
            setErrorMessageGroup('');
            setErrorMessageSubGroupVinci('');
            let enabledCategories = filterSelectData(typeFilterSelect.add, productCategories.data);
            setProductCategories(enabledCategories);
        } else {
            setProductCategories([]);
            setResetSelectProductCategory(true);
            setErrorMessageCategory('*El producto no posee categorías relacionadas');

            setProductGroups([]);
            setResetSelectProductGroup(true);
            setErrorMessageGroup('*El producto no posee grupos relacionados');

            setProductSubGroupVinci([]);
            setResetSelectProductSubGroupVinci(true);
            setErrorMessageSubGroupVinci('*El producto no posee sub-grupos de vinci relacionados');
        }

    }

    const getProductGroup = async (id_category) => {
        let productGroups = await selectProductGroup(agentSelected, id_category);

        setResetSelectProductCategory(false);
        setResetSelectProductGroup(false);
        setResetSelectProductSubGroupVinci(false);

        if (productGroups.statusResponse) {
            let enabledGroups = filterSelectData(typeFilterSelect.add, productGroups.data);
            setProductGroups(enabledGroups);
            setErrorMessageGroup('');
            setErrorMessageSubGroupVinci('');
        } else {
            setProductGroups([]);
            setResetSelectProductGroup(true);
            setErrorMessageGroup('*El producto no posee grupos relacionados');

            setProductSubGroupVinci([]);
            setResetSelectProductSubGroupVinci(true);
            setErrorMessageSubGroupVinci('*El producto no posee sub-grupos de vinci relacionados');
        }
    }

    const getProductSubGroupVinci = async (id_group) => {

        if (id_group) {
            let productSubGroupsVinci = await selectProductSubGroupVinci(productCategoryVinci, id_group);

            setResetSelectProductCategory(false);
            setResetSelectProductGroup(false);
            setResetSelectProductSubGroupVinci(false);

            if (productSubGroupsVinci.statusResponse) {
                let enabledSubGroupsVinci = filterSelectData(typeFilterSelect.add, productSubGroupsVinci.data);
                setProductSubGroupVinci(enabledSubGroupsVinci);
                setErrorMessageSubGroupVinci('');
            } else {
                setProductSubGroupVinci([]);
                setResetSelectProductSubGroupVinci(true);
                setErrorMessageSubGroupVinci('*El producto no posee sub-grupos de vinci relacionados');
            }
        }

    }

    function comboBoxChange(e) {
        handleChange(e);
        switch (e.target.name) {
            case 'id_agent':
                setAgentSelected(e.target.value);
                getProductCategory(e.target.value);
                break;
            case 'id_product_category':
                setProductCategoryVinci(e.target.data.id_vinci_product_category);
                getProductGroup(e.target.value);
                break;
            case 'id_product_group':
                getProductSubGroupVinci(e.target.data.id_vinci_product_group);
                break;
            default:
                break;
        }
    }

    return loading ? <Loading /> : errorMessage ? <ErrorPage redirect={true} actionChange={actionChange} name={'Sub-Grupo de Productos'} /> :
        <section className="section form-color">
            <form onSubmit={handleSubmit} noValidate>
                <div className="acction-card"><span aria-hidden="true">
                    {render === 'add' ? 'Agregar ' : 'Actualizar '} Sub-Grupo</span>
                </div>

                {warningMessage !== '' && <Warning text={warningMessage} />}

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group coriport-input">
                            <label htmlFor="subgroup_name" className="form-label">Nombre del Sub-Grupo</label>
                            <input
                                type="text"
                                className={`input-form form-control ${errors.subgroup_name && 'is-danger'}`}
                                placeholder="Nombre del Sub-Grupo"
                                name="subgroup_name"
                                onChange={handleChange}
                                value={values.subgroup_name || ''}
                                required
                            />
                            {errors.subgroup_name && (<p className="help is-danger">{errors.subgroup_name}</p>)}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group coriport-input">
                            <label htmlFor="subgroup_code" className="form-label">Código del Sub-Grupo</label>
                            <input
                                type="text"
                                className={`input-form form-control`}
                                placeholder="Nombre del Sub-Grupo"
                                name="subgroup_code"
                                onChange={handleChange}
                                value={values.subgroup_code || ''}
                                required
                            />
                            {errors.subgroup_code && (<p className="help is-danger">{errors.subgroup_code}</p>)}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group coriport-input">
                            <ComboBox
                                name={"id_agent"}
                                text={"Agente"}
                                options={filteredAgent}
                                onChange={comboBoxChange}
                                defaultValueSelect={defaultAgent}
                            />
                            {errors.id_agent && (<p className="help is-danger">{errors.id_agent}</p>)}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group coriport-input">
                            <ComboBox
                                resetSelect={resetSelectProductCategory}
                                name={"id_product_category"}
                                text={"Categoría del Producto"}
                                options={productCategories}
                                onChange={comboBoxChange}
                                defaultValueSelect={defaultCategory}
                            />
                            {errors.id_product_category && (<p className="help is-danger">{errors.id_product_category}</p>)}
                            {errorMessageCategory && (<p className="help is-danger">{errorMessageCategory}</p>)}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group coriport-input">
                            <ComboBox
                                resetSelect={resetSelectProductGroup}
                                name={"id_product_group"}
                                text={"Grupo del Producto"}
                                options={productGroups}
                                onChange={comboBoxChange}
                                defaultValueSelect={defaultGroup}
                            />
                            {errors.id_product_group && (<p className="help is-danger">{errors.id_product_group}</p>)}
                            {errorMessageGroup && (<p className="help is-danger">{errorMessageGroup}</p>)}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group coriport-input">
                            <ComboBox
                                resetSelect={resetSelectProductSubGroupVinci}
                                name={"id_vinci_product_subgroup"}
                                text={"Sub-Grupo del Producto Vinci"}
                                options={productSubGroupVinci}
                                onChange={comboBoxChange}
                                defaultValueSelect={defaultSubGroupVinci}
                            />
                            {errors.id_vinci_product_subgroup && (<p className="help is-danger">{errors.id_vinci_product_subgroup}</p>)}
                            {errorMessageSubGroupVinci && (<p className="help is-danger">{errorMessageSubGroupVinci}</p>)}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group coriport-input">
                            <ComboBox
                                name={"enable"}
                                text={"Estado"}
                                options={selectEnable}
                                onChange={comboBoxChange}
                                defaultValueSelect={defaultEnable}
                            />
                            {errors.enable && (<p className="help is-danger">{errors.enable}</p>)}
                        </div>
                    </div>
                </div>

                <div className="container-bottons">
                    <button disabled={statusButton} id='custom-btn-coriport' className=" btn  btn-coriport btn-add text-white " type="submit">
                        {
                            statusButton ? <div className="container-spinner-loading pt-2">
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </div> : <p className="label-button-add"> {render == 'add' ? 'Agregar' : 'Actualizar'}</p>
                        }
                    </button>
                    <button className="btn-coriport btn-cancel" onClick={() => actionChange('table')}>
                        Cancelar
                    </button>
                </div>

            </form>
        </section>

}