import React, { useEffect, useState } from 'react'
import { createRecord, editRecord } from '../../../request/Request';
import { selectDays, typeFilterSelect } from '../../../service/defaultData';
import { shopScheduleErrors } from '../../../service/ErrorNotification/shopScheduleErrors';
import { selectStatusShop, selectEnable } from '../../../service/defaultData';
import validate from '../../../validate-forms/ShopSchedule/AddFormValidationRules';
import useForm from '../../../validate-forms/useForm';
import { ComboBox } from '../../ComboBox/ComboBox';
import { ErrorPage } from '../../ErrorPage/ErrorPage';
import { Loading } from '../../Loading/Loading';
import Warning from '../../Notification/Warning';
import { filterSelectData, getDefaultDay, getDefaultOptionSelect } from '../../../service/comboBox';

export const AddOrEdit = ({ actionChange, shopSchedule, createdOrEditShopSchedule, render, shops }) => {

    const [errorMessage, setErrorMessage] = useState(false);
    const [loading, setLoading] = useState(true);

    const [allShops, setAllShops] = useState([]);

    const [defaultEnable, setDefaultEnable] = useState(null);
    const [defaultStatus, setDefaultStatus] = useState(null);
    const [defaultShop, setDefaultShop] = useState(null);
    const [defaultDay, setdefaultDay] = useState(null);

    const [statusButton, setStatusButton] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');

    const { values, errors, handleChange, handleSubmit } = useForm(sendForm, validate);

    useEffect(() => {

        if (render === 'add') {
            let enableAndDisabledShops = filterSelectData(typeFilterSelect.add, shops);
            setAllShops(enableAndDisabledShops);
            setLoading(false);
        } else {

            let defaultShopTemp = getDefaultOptionSelect(shops, shopSchedule.id_shop);
            setDefaultShop(defaultShopTemp[0]);
            let disableAndDisabledShops = filterSelectData(typeFilterSelect.edit, shops, defaultShopTemp)
            setAllShops(disableAndDisabledShops);

            let deafultDayTemp = getDefaultDay(shopSchedule); 
            setdefaultDay([deafultDayTemp]);

            let defaultStatusTemp = getDefaultOptionSelect(selectStatusShop, shopSchedule.status);
            setDefaultStatus(defaultStatusTemp);

            let defaultEnableTemp = getDefaultOptionSelect(selectEnable, shopSchedule.enable);
            setDefaultEnable(defaultEnableTemp);

            values.id_shop = shopSchedule.id_shop;
            values.hour = shopSchedule.hour;
            values.day = shopSchedule.day;
            values.status = shopSchedule.status;
            values.enable = shopSchedule.enable;

        }

    }, []);

    useEffect(() => {
        if (defaultShop && defaultStatus && defaultEnable && defaultDay) {
            setLoading(false);
        }
    }, [defaultShop, defaultStatus, defaultEnable, defaultDay]);

    async function sendForm() {
        setWarningMessage('');
        setStatusButton(true);
        if (render !== 'add') {
            await editRecord(`/schedules/${shopSchedule.id_shop_schedule}`, values).then(request => {
                setStatusButton(false);
                if (request.statusResponse) {
                    redirect('edit');
                }
            }).catch(error => getErrors(error));
        } else {
            await createRecord(`/schedules`, values).then(request => {
                setStatusButton(false);
                if (request.statusResponse) {
                    redirect('created');
                }
            }).catch(error => getErrors(error));
        }
    }

    const getErrors = (error) => {

        setWarningMessage('');
        setErrorMessage(false);

        let respondeError = shopScheduleErrors(error);
        if (respondeError === 'error') return setErrorMessage(true);

        setWarningMessage(respondeError);
        setStatusButton(false);

    }

    const redirect = (action) => {
        createdOrEditShopSchedule(action);
        actionChange('table');
    }

    const comboBoxChange = (e) => {
        handleChange(e)};


    return loading ? <Loading /> : errorMessage ? <ErrorPage redirect={true} actionChange={actionChange} name={'Horarios'} /> :
        <section className="section form-color">
            <form onSubmit={handleSubmit} noValidate>
                <div className="acction-card"><span aria-hidden="true">{render === 'add' ? 'Agregar' : 'Actualizar'} Horario</span></div>

                {warningMessage !== '' && <Warning text={warningMessage} />}

                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group coriport-input">
                            <ComboBox
                                name={"id_shop"}
                                text={"Tienda"}
                                options={allShops}
                                onChange={comboBoxChange}
                                defaultValueSelect={defaultShop}
                            />
                            {errors.id_shop && (<p className="help is-danger">{errors.id_shop}</p>)}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group coriport-input">
                            <label htmlFor="hour" className="form-label">Hora</label>
                            <input type="time" className={`input-form form-control ${errors.hour && 'is-danger'}`} placeholder="Horas" name="hour" onChange={handleChange} value={values.hour || ''} required />
                            {errors.hour && (
                                <p className="help is-danger">{errors.hour}</p>
                            )}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group coriport-input">
                            <ComboBox name={"day"} text={"Día"} options={selectDays} onChange={comboBoxChange} defaultValueSelect={defaultDay} />
                            {errors.day && (
                                <p className="help is-danger">{errors.day}</p>
                            )}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group coriport-input">
                            <ComboBox name={"status"} text={"Estado"} options={selectStatusShop} onChange={comboBoxChange} defaultValueSelect={defaultStatus} />
                            {errors.status && (
                                <p className="help is-danger">{errors.status}</p>
                            )}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group coriport-input">
                            <ComboBox name={"enable"} text={"Habilitar"} options={selectEnable} onChange={comboBoxChange} defaultValueSelect={defaultEnable} />
                            {errors.enable && (
                                <p className="help is-danger">{errors.enable}</p>
                            )}
                        </div>
                    </div>
                </div>

                <div className="container-bottons">
                    <button disabled={statusButton} id='custom-btn-coriport' className=" btn  btn-coriport btn-add text-white " type="submit">
                        {
                            statusButton ? <div className="container-spinner-loading pt-2">
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </div> : <p className="label-button-add"> {render == 'add' ? 'Agregar' : 'Actualizar'}</p>
                        }
                    </button>
                    <button className="btn-coriport btn-cancel" onClick={() => actionChange('table')}>
                        Cancelar
                    </button>
                </div>

            </form>
        </section>

}
