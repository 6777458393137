import React, { useEffect, useState } from "react";
import validate from "../../../validate-forms/ProductGroupVinci/AddFormValidationRules";
import { filterSelectData, getDefaultOptionSelect, selectProductCategoryVinci } from "../../../service/comboBox";
import { createRecord, editRecord } from "../../../request/Request";
import useForm from "../../../validate-forms/useForm";
import { ErrorPage } from "../../ErrorPage/ErrorPage";
import { ComboBox } from "../../ComboBox/ComboBox";
import { Loading } from "../../Loading/Loading";
import './AddOrEdit.css';
import Warning from "../../Notification/Warning";
import { productGroupVinciErrors } from "../../../service/ErrorNotification/productGroupVinciErrors";
import { selectEnable, typeFilterSelect } from "../../../service/defaultData";

export const AddOrEdit = ({ actionChange, createdOrEditGroup, group, render }) => {

    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');

    const [productCategoriesVinci, setProductCategoriesVinci] = useState([]);
    const [productCategoriesVinciFiltered, setProductCategoriesVinciFiltered] = useState([]);

    const [defaultEnable, setDefaultEnable] = useState(null);
    const [defaultProductCategoryVinci, setDefaultProductCategoryVinci] = useState(null);

    const [statusButton, setStatusButton] = useState(false);
  
    const [statusPetitionProductCategoriesVinci, setStatusPetitionProductCategoriesVinci] = useState(false);
 
    const { values, errors, handleChange, handleSubmit } = useForm(sendForm, validate);

    useEffect(() => {
        (async function () {
            setStatusPetitionProductCategoriesVinci(true);
            const productCategories = await selectProductCategoryVinci();
            if (productCategories.statusResponse) {
                setProductCategoriesVinci(productCategories.data); 
                setStatusPetitionProductCategoriesVinci(false);
            } else {
                setErrorMessage(true);
                setLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        if (render === 'add') { 

            let enabledCategoriesVinci = filterSelectData(typeFilterSelect.add, productCategoriesVinci);
            setProductCategoriesVinciFiltered(enabledCategoriesVinci);

            setLoading(false);
        } 
        if (render === 'edit' && productCategoriesVinci.length > 0) {

            let defaultProductGroupVinciTemp = getDefaultOptionSelect(productCategoriesVinci, group.id_vinci_product_category);
            (defaultProductGroupVinciTemp.length > 0) ? setDefaultProductCategoryVinci(defaultProductGroupVinciTemp[0]) : setDefaultProductCategoryVinci([]);

            let enabledCategoriesVinci = filterSelectData(typeFilterSelect.edit, productCategoriesVinci, defaultProductGroupVinciTemp);
            setProductCategoriesVinciFiltered(enabledCategoriesVinci);

            let defaultEnableTemp = getDefaultOptionSelect(selectEnable, group.enable);
            setDefaultEnable(defaultEnableTemp[0]);
            
            values.id_vinci_product_category = group.id_vinci_product_category;
            values.group_name = group.group_name;
            values.enable = group.enable;
 
        }
    }, [productCategoriesVinci]);

    useEffect(() => {
      
        if (defaultProductCategoryVinci &&  defaultEnable && render == 'edit') {
            setLoading(false);
        }

    }, [defaultProductCategoryVinci, defaultEnable])
    

    async function sendForm() {
        setWarningMessage('');
        setStatusButton(true);
        if (render === 'add') {
            await createRecord(`/vinciproductgroup`, values).then(request => {
                setStatusButton(false);
                if (request.statusResponse) {
                    redirect('created');
                }
            }).catch(error => getErrors(error));
        } else {
            await editRecord(`/vinciproductgroup/${group.id_vinci_product_group}`, values).then(request => {
                setStatusButton(false);
                if (request.statusResponse) {
                    redirect('edit');
                }
            }).catch(error => getErrors(error));
        }
    }

    const getErrors = (error) => {

        setWarningMessage('');
        setErrorMessage(false);

        let respondeError = productGroupVinciErrors(error);

        if (respondeError === 'error') return setErrorMessage(true);

        setWarningMessage(respondeError);
        setStatusButton(false);

    }

    const redirect = (action) => {
        createdOrEditGroup(action);
        actionChange('table');
    }

    function comboBoxChange(e) {
        handleChange(e);
    }

    return loading ? <Loading /> : errorMessage ? <ErrorPage redirect={true} actionChange={actionChange} name={'Grupo de Productos Vinci'} /> :
        <section className="section form-color">
            <form onSubmit={handleSubmit} noValidate>
                <div className="acction-card"><span aria-hidden="true">
                    {render === 'add' ? 'Agregar ' : 'Actualizar '} Grupo</span>
                </div>

                {warningMessage !== '' && <Warning text={warningMessage} />}

                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group coriport-input">
                            <label htmlFor="group_name" className="form-label">Nombre del Grupo</label>
                            <input
                                type="text"
                                className={`input-form form-control ${errors.group_name && 'is-danger'}`}
                                placeholder="Nombre del Grupo"
                                name="group_name"
                                onChange={handleChange}
                                value={values.group_name || ''}
                                required
                            />
                            {errors.group_name && (<p className="help is-danger">{errors.group_name}</p>)}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group coriport-input">
                            <ComboBox
                                name={"id_vinci_product_category"}
                                text={"Categoría de Productos Vinci"}
                                options={productCategoriesVinciFiltered}
                                onChange={comboBoxChange}
                                isLoading={statusPetitionProductCategoriesVinci}
                                defaultValueSelect={defaultProductCategoryVinci}
                            />
                            {errors.id_vinci_product_category && (<p className="help is-danger">{errors.id_vinci_product_category}</p>)}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group coriport-input">
                            <ComboBox
                                name={"enable"}
                                text={"Estado"}
                                options={selectEnable}
                                onChange={comboBoxChange}
                                defaultValueSelect={defaultEnable}
                            />
                            {errors.enable && (<p className="help is-danger">{errors.enable}</p>)}
                        </div>
                    </div>
                </div>

                <div className="container-bottons">
                    <button disabled={statusButton} id='custom-btn-coriport' className=" btn  btn-coriport btn-add text-white " type="submit">
                        {
                            statusButton ? <div className="container-spinner-loading pt-2">
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </div> : <p className="label-button-add"> {render == 'add' ? 'Agregar' : 'Actualizar'}</p>
                        }
                    </button>
                    <button className="btn-coriport btn-cancel" onClick={() => actionChange('table')}>
                        Cancelar
                    </button>
                </div>

            </form>
        </section>

}