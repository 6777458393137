export default function validate(values){
    
    let errors = {};

    if(!values.description){
        errors.description = '*La descripción es requerida';
    }
    if(!values.country){
        errors.country = '*El país es requerido';
    }
    if(values.enable === undefined){
        errors.enable = '*El estado es requerido'
    }


    return errors;
}