export const productSubGroupVinciErrors = ({ error, data }) => {
    let message = "";

    if (error === 500) return message = "error";
  
    if (error === 400 && data.message === 'A vinci product subgroup already exists with the submitted subgroup name, id vinci product category and id vinci product group')  return message = 'Los datos ingresados ya existen';

    if (error === 404)  return message = 'El sub-grupo de vinci ingresado no existe';

    if (error === 400 && data.message === 'A vinci product subgroup already exists with the submitted subgroup name')  return message = 'El nombre ingresado ya existe';

    if (error === 400 && data.message === 'The id product category does not exist in the vinci product category table, please check')  return message = 'La categoría ingresada no existe';

    if (error === 400 && data.message === 'Please contact the site administrator since the category you want to insert is disabled')  return message = 'La categoría ingresada está desactivada';

    if (error === 400 && data.message === 'The id product group does not exist in the vinci product group table, please check')  return message = 'El grupo ingresado no existe';
 
    if (error === 400 && data.message === 'Please contact the site administrator since the group you want to insert is disabled')  return message = 'El grupo ingresado está desactivado';
    
    if (error === 400 && data.message === 'The vinci product subgroup you want to disable was already disabled')  return message = 'El sub-grupo de vinci ingresado ya está desactivado';
   
    if(error === 400 && data.message.includes('record(s) of the table productSubgroup')) return message = 'El subgrupo de vinci posee sub-grupos de productos asociados';
     
    return message;

};
