import React, { useEffect, useState } from 'react';
import { createRecord, editRecord } from '../../../request/Request';
import { identificationTypeErrors } from '../../../service/ErrorNotification/identificationTypeErrors';
import { selectEnable } from '../../../service/defaultData';
import validate from '../../../validate-forms/IdentificationType/AddFormValidationRules';
import useForm from '../../../validate-forms/useForm';
import { ComboBox } from '../../ComboBox/ComboBox';
import { ErrorPage } from '../../ErrorPage/ErrorPage';
import { Loading } from '../../Loading/Loading';
import Warning from '../../Notification/Warning';
import './AddOrEdit.css';
import { getDefaultOptionSelect } from '../../../service/comboBox';

export const AddOrEdit = ({ actionChange, identification, createdOrEditIdentification, render }) => {

    const [errorMessage, setErrorMessage] = useState(false);
    const [loading, setLoading] = useState(true);
    const [defaultEnable, setDefaultEnable] = useState(null);
    const [statusButton, setStatusButton] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');

    const { values, errors, handleChange, handleSubmit } = useForm(sendForm, validate);

    useEffect(() => {

        if (render === 'add') {
            setLoading(false);
        } else {

            let defaultEnableTemp = getDefaultOptionSelect(selectEnable, identification.enable);
            setDefaultEnable(defaultEnableTemp);

            values.id_identification_type = identification.id_identification_type;
            values.enable = identification.enable;
            values.description = identification.description;
            setLoading(false);
        }

    }, [])

    async function sendForm() {
        setStatusButton(true);
        let parseId = parseInt(values.id_identification_type);
        values.id_identification_type = parseId;

        if (render === 'add') {
            await createRecord(`/identificationtype`, values).then(request => {
                setStatusButton(false);
                if (request.statusResponse) {
                    redirect('created');
                }
            }).catch(error => getErrors(error));
        } else {
            await editRecord(`/identificationtype/${identification.id_identification_type}`, values).then(request => {
                setStatusButton(false);
                if (request.statusResponse) {
                    redirect('edit');
                }
            }).catch(error => getErrors(error));
        }

    }

    const getErrors = (error) => {

        setWarningMessage('');
        setErrorMessage(false);
        
        let respondeError = identificationTypeErrors(error);
 
        if (respondeError === 'error') return setErrorMessage(true);

        setWarningMessage(respondeError);
        setStatusButton(false);

    }

    const redirect = (action) => {
        createdOrEditIdentification(action);
        actionChange('table');
    }

    const comboBoxChange = (e) => handleChange(e);

    return loading ? <Loading /> : errorMessage ? <ErrorPage redirect={true} actionChange={actionChange} name={'Tipo de identificación'} /> :
        <section className="section form-color">
            <form onSubmit={handleSubmit} noValidate>
                <div id="acction-card"><span aria-hidden="true"> {render === 'add' ? 'Agregar' : 'Actualizar'} Identificación</span></div>

                {warningMessage !== '' && <Warning text={warningMessage} />}

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group coriport-input">
                            <label htmlFor="id_identification_type" className="form-label">Identificación</label>
                            <input readOnly={render === 'edit' && true} type="number" className={`input-form form-control ${errors.id_identification_type && 'is-danger'}`} name="id_identification_type" onChange={handleChange} value={values.id_identification_type || ''} required />
                            {errors.id_identification_type && (
                                <p className="help is-danger">{errors.id_identification_type}</p>
                            )}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group coriport-input">
                            <ComboBox name={"enable"} text={"Estado"} options={selectEnable} onChange={comboBoxChange} defaultValueSelect={defaultEnable} />
                            {errors.enable && (
                                <p className="help is-danger">{errors.enable}</p>
                            )}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="form-group coriport-input">
                            <label htmlFor="description" className="form-label">Descripción</label>
                            <input type="text" className={`input-form form-control ${errors.description && 'is-danger'}`} placeholder="Descripción" name="description" onChange={handleChange} value={values.description || ''} required />
                            {errors.description && (
                                <p className="help is-danger">{errors.description}</p>
                            )}
                        </div>
                    </div>
                </div>

                <div className="container-bottons">
                    <button disabled={statusButton} id='custom-btn-coriport' className=" btn  btn-coriport btn-add text-white " type="submit">
                        {
                            statusButton ? <div className="container-spinner-loading pt-2">
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </div> : <p className="label-button-add"> {render === 'add' ? 'Agregar' : 'Actualizar'}</p>
                        }
                    </button>
                    <button className="btn-coriport btn-cancel" onClick={() => actionChange('table')}>
                        Cancelar
                    </button>
                </div>

            </form>
        </section>

}
