import React from 'react';

export const ButtonAdd = ({ actionChange }) => {
    return (
        <div className="container-bottons">
            <button className="btn-coriport btn-add" onClick={() => actionChange('add')}>
                Agregar
            </button>
        </div>
    )
}
