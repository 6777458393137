import React, { useEffect, useState } from 'react'
import './CustomDatePicker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const CustomDatePicker = (props) => {
 
    const [date, setDate] = useState('');

    useEffect(() => {
      if(props.defaultValue){
          setDate(props.defaultValue);
      }
    
    }, [])
     
    useEffect(() => {
        props.getDate(date);
    }, [date]);

    const handleChange = (date) => {
        let eventDatePicker = { target: { name: '', value: '', label: '' } };
        eventDatePicker.target.name = props.name;
        eventDatePicker.target.value = date;
        eventDatePicker.target.label = props.title;
        eventDatePicker.target.data = {}; 
        props.onChange(eventDatePicker);
        setDate(date);
    }

    return (
        <div>
            <label className="form-label"> {props.title} </label>
            {
                props.type === 'date-time' && (<DatePicker
                    selected={date}
                    placeholderText={props.placeHolder}
                    onChange={handleChange}
                    timeInputLabel="Time:"
                    dateFormat="dd/MM/yyyy h:mm:ss aa"
                    showTimeInput
                    className='input-form form-control'
                /> )
            }
             {
                props.type === 'date' && (<DatePicker
                    selected={date}
                    placeholderText={props.placeHolder}
                    onChange={handleChange} 
                    className='input-form form-control'
                /> )
            }
        </div>
    )
}
