import React, { useState } from 'react'
import { useEffect } from 'react';
import { getAll } from '../../../request/Request';
import { selectDays } from '../../../service/defaultData';
import { Loading } from '../../Loading/Loading';
import './ScheduleSection.css';

export const ScheduleSection = (props) => {

    const [schedule, setSchedule] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(false);

    useEffect(() => {
        getAllSchedule();
    }, []);

    const getAllSchedule = async () => {
        await getAll(`/schedules?page=0&perPage=10&idShop=${props.shop.id_shop}&order=desc&paginated=false`).then(request => {
            let { statusResponse, data } = request;
            if (statusResponse) {
                if (data?.data) {
                    setSchedule(data.data);
                }else{
                    setErrorMessage(true);
                }
                setLoading(false);
            }
        }).catch(error => {
            setLoading(false);
            setErrorMessage(true);
        });
    }

    const getDay = ( schedule ) => {
        let response = '';
        selectDays.map(day => {
           if (day.value === schedule.day) {
            response = day.label;
            }
        })
        return response;
    }

    return (
        <>
            {
                loading ? <Loading /> : errorMessage ? <div id='not-records' className='col-12' >
                    <h3 className='mt-5'>No hay registros por mostrar</h3>
                </div> : <div>
                    <div className="row">
                        {
                            schedule.length > 0 && schedule.map((s, index) => (
                                <div key={index} className="col-md-3 mt-3 mb-4 pl-4 text-left">
                                    <span className='style-span'>Día: </span> <span>{ getDay(s) }</span><br />
                                    <span className='style-span'>Hora: </span> <span>{s.hour}</span><br />
                                    <span className='style-span'>Estado: </span> <span>{s.status ? 'Apertura' : 'Cierre'}</span>
                                </div>
                            ))
                        }
                    </div>
                </div>
            }
        </>
    )
}
