import React, { useEffect, useState } from "react";
import useForm from "../../../validate-forms/useForm";
import validate from "../../../validate-forms/ProductGroup/AddFormValidationRules";
import { Loading } from "../../Loading/Loading";
import { ErrorPage } from "../../ErrorPage/ErrorPage";
import './AddOrEdit.css';
import { ComboBox } from "../../ComboBox/ComboBox";
import { createRecord, editRecord } from "../../../request/Request";
import { selectProductCategory, selectProductGroupVinci, getDefaultOptionSelect, filterSelectData } from "../../../service/comboBox";
import Warning from "../../Notification/Warning";
import { productGroupErrors } from "../../../service/ErrorNotification/productGroupErrors";
import { selectEnable, typeFilterSelect } from "../../../service/defaultData";

export const AddOrEdit = ({ agents, actionChange, createdOrEditGroup, group, render }) => {

    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(false);

    const [productCategories, setProductCategories] = useState([]);
    const [productGroupVinci, setProductGroupVinci] = useState([]);

    const [defaultAgent, setDefaultAgent] = useState(null);
    const [defaultCategory, setDefaultCategory] = useState(null);
    const [defaultGroupVinci, setDefaultGroupVinci] = useState(null);
    const [defaultEnable, setDefaultEnable] = useState(null);

    const [resetSelectProductCategory, setResetSelectProductCategory] = useState(false);
    const [resetSelectProductGroupVinci, setResetSelectProductGroupVinci] = useState(false);

    const [errorMessageCategory, setErrorMessageCategory] = useState('');
    const [errorMessageGroupVinci, setErrorMessageGroupVinci] = useState('');

    const [statusPetitionProductCategories, setStatusPetitionProductCategories] = useState(false);
    const [statusPetitionProductGroupsVinci, setStatusPetitionProductGroupsVinci] = useState(false);

    const [statusButton, setStatusButton] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');

    const [filteredAgent, setFilteredAgent] = useState([]);

    const { values, errors, handleChange, handleSubmit } = useForm(sendForm, validate);

    useEffect(() => {

        if (render === 'edit') {

            (async function () {

                let defaultAgentTemp = getDefaultOptionSelect(agents, group.id_agent);
                setDefaultAgent(defaultAgentTemp[0]);

                let enabledAndDisabledAgents = filterSelectData(typeFilterSelect.edit, agents, defaultAgentTemp);
                setFilteredAgent(enabledAndDisabledAgents);

                let productCategories = await selectProductCategory(group.id_agent);
                if (productCategories.statusResponse) {

                    let defaultCategory = getDefaultOptionSelect(productCategories.data, group.id_product_category);
                    setDefaultCategory(defaultCategory[0]);

                    let enabledAndDisabledCategories = filterSelectData(typeFilterSelect.edit, productCategories.data, defaultCategory);
                    setProductCategories(enabledAndDisabledCategories);
                }

                let getVinciCategory = productCategories.data.filter(c => c.value === group.id_product_category);
                let vinciCategoryTemp = getVinciCategory[0]?.id_vinci_product_category;
                let productSubGroups = await selectProductGroupVinci(vinciCategoryTemp);
                if (productSubGroups.statusResponse) {

                    let defaultSubGroup = getDefaultOptionSelect(productSubGroups.data, group.id_vinci_product_group);
                    setDefaultGroupVinci(defaultSubGroup[0]);

                    let enabledAndDisabledSubGroups = filterSelectData(typeFilterSelect.edit, productSubGroups.data, defaultSubGroup);
                    setProductGroupVinci(enabledAndDisabledSubGroups);
                }

                let defaultEnableTemp = getDefaultOptionSelect(selectEnable, group.enable);
                setDefaultEnable(defaultEnableTemp[0]);

            })();
        }

    }, [])

    useEffect(() => {

        if (render === 'add') {

            let EnabledAgents = filterSelectData(typeFilterSelect.add, agents);
            setFilteredAgent(EnabledAgents);

            setLoading(false);
        } else {
 
            values.id_vinci_product_group = group?.id_vinci_product_group;
            values.id_product_category = group?.id_product_category;
            values.group_name = group?.group_name;
            values.group_code = group?.group_code;
            values.id_agent = group.id_agent;
            values.enable = group?.enable;

        }

    }, [])

    useEffect(() => {
        if (defaultAgent && defaultCategory && defaultGroupVinci && defaultEnable && render === 'edit') {
            setLoading(false);
        }
    }, [defaultAgent, defaultCategory, defaultGroupVinci, defaultEnable])

    async function sendForm() {

        setStatusButton(true);
        setWarningMessage('');

        if (render === 'add') {
            await createRecord(`/productgroup`, values).then(request => {
                setStatusButton(false);
                if (request.statusResponse) {
                    redirect('created');
                }
            }).catch(error => getErrors(error));
        } else {
            await editRecord(`/productgroup/${group.id_product_group}`, values).then(request => {
                setStatusButton(false);
                if (request.statusResponse) {
                    redirect('edit');
                }
            }).catch(error => getErrors(error));

        }

    }

    const getErrors = (error) => {

        setWarningMessage('');
        setErrorMessage(false);

        let respondeError = productGroupErrors(error);

        if (respondeError === 'error') return setErrorMessage(true);

        setWarningMessage(respondeError);
        setStatusButton(false);

    }

    const redirect = (action) => {
        createdOrEditGroup(action);
        actionChange('table');
    }

    const getProductCategory = async (id_agent) => {

      
        if (id_agent !== values.id_agent) {

            setStatusPetitionProductCategories(true);
            setResetSelectProductCategory(false);
            setResetSelectProductGroupVinci(false);

            let productCategories = await selectProductCategory(id_agent);

            if (productCategories.statusResponse) {
                let enabledCategories = filterSelectData(typeFilterSelect.add, productCategories.data);
                setProductCategories(enabledCategories);

                setResetSelectProductCategory(true);
                setResetSelectProductGroupVinci(true);
                setStatusPetitionProductCategories(false);
                setErrorMessageCategory('');
                setErrorMessageGroupVinci('');
            } else {
                setStatusPetitionProductCategories(false);
                setProductCategories([]);
                setResetSelectProductCategory(true);
                setErrorMessageCategory('*El producto no posee categorías relacionadas');

                setProductGroupVinci([]);
                setResetSelectProductGroupVinci(true);
                setErrorMessageGroupVinci('*El producto no posee grupos de vinci relacionados');
            }

        }

    }

    const getProductGroupVinci = async (id_vinci_product_category) => {
        
        if (id_vinci_product_category !== values.id_vinci_product_category) {

            setStatusPetitionProductGroupsVinci(true);

            let productGroupsVinci = await selectProductGroupVinci(id_vinci_product_category);
            setResetSelectProductCategory(false);
            setResetSelectProductGroupVinci(false);

            if (productGroupsVinci.statusResponse) {

                setStatusPetitionProductGroupsVinci(false);

                let enabledGroupsVinci = filterSelectData(typeFilterSelect.add, productGroupsVinci.data);
                setProductGroupVinci(enabledGroupsVinci);

                setErrorMessageGroupVinci('');
                setResetSelectProductGroupVinci(true);
            } else {
                setStatusPetitionProductGroupsVinci(false);
                setProductGroupVinci([]);
                setErrorMessageGroupVinci('*El producto no posee grupos de vinci relacionados');
            }
        }

    }

    function comboBoxChange(e) {
        handleChange(e);
        switch (e.target.name) {
            case 'id_agent':
                getProductCategory(e.target.value);
                break;
            case 'id_product_category':
                getProductGroupVinci(e.target.data.id_vinci_product_category);
                break;
            default:
                break;
        }
    }

    return loading ? <Loading /> : errorMessage ? <ErrorPage redirect={true} actionChange={actionChange} name={'Grupo de Productos'} /> :
        <section className="section form-color">
            <form onSubmit={handleSubmit} noValidate>
                <div className="acction-card"><span aria-hidden="true">
                    {render === 'add' ? 'Agregar ' : 'Actualizar '} Grupo</span>
                </div>

                {warningMessage !== '' && <Warning text={warningMessage} />}

                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group coriport-input">
                            <label htmlFor="group_name" className="form-label">Nombre del Grupo</label>
                            <input
                                type="text"
                                className={`input-form form-control ${errors.group_name && 'is-danger'}`}
                                placeholder="Nombre del Grupo"
                                name="group_name"
                                onChange={handleChange}
                                value={values.group_name || ''}
                                required
                            />
                            {errors.group_name && (<p className="help is-danger">{errors.group_name}</p>)}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group coriport-input">
                            <label htmlFor="group_code" className="form-label">Código del Grupo</label>
                            <input
                                type="text"
                                className={`input-form form-control ${errors.group_code && 'is-danger'}`}
                                placeholder="Código del Grupo"
                                name="group_code"
                                onChange={handleChange}
                                value={values.group_code || ''}
                                required
                            />
                            {errors.group_code && (<p className="help is-danger">{errors.group_code}</p>)}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group coriport-input">
                            <ComboBox
                                name={"id_agent"}
                                text={"Agente"}
                                options={filteredAgent}
                                onChange={comboBoxChange}
                                defaultValueSelect={defaultAgent}
                            />
                            {errors.id_agent && (<p className="help is-danger">{errors.id_agent}</p>)}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group coriport-input">
                            <ComboBox
                                resetSelect={resetSelectProductCategory}
                                name={"id_product_category"}
                                text={"Categoría  del Producto"}
                                options={productCategories}
                                onChange={comboBoxChange}
                                defaultValueSelect={defaultCategory}
                                isLoading={statusPetitionProductCategories}
                            />
                            {errors.id_product_category && (<p className="help is-danger">{errors.id_product_category}</p>)}
                            {errorMessageCategory && (<p className="help is-danger">{errorMessageCategory}</p>)}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group coriport-input">
                            <ComboBox
                                resetSelect={resetSelectProductGroupVinci}
                                name={"id_vinci_product_group"}
                                text={"Grupo de Productos Vinci"}
                                options={productGroupVinci}
                                onChange={comboBoxChange}
                                defaultValueSelect={defaultGroupVinci}
                                isLoading={statusPetitionProductGroupsVinci}
                            />
                            {errors.id_vinci_product_group && (<p className="help is-danger">{errors.id_vinci_product_group}</p>)}
                            {errorMessageGroupVinci && (<p className="help is-danger">{errorMessageGroupVinci}</p>)}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group coriport-input">
                            <ComboBox
                                name={"enable"}
                                text={"Estado"}
                                options={selectEnable}
                                onChange={comboBoxChange}
                                defaultValueSelect={defaultEnable}
                            />
                            {errors.enable && (<p className="help is-danger">{errors.enable}</p>)}
                        </div>
                    </div>
                </div>

                <div className="container-bottons">
                    <button disabled={statusButton} id='custom-btn-coriport' className=" btn  btn-coriport btn-add text-white " type="submit">
                        {
                            statusButton ? <div className="container-spinner-loading pt-2">
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </div> : <p className="label-button-add"> {render == 'add' ? 'Agregar' : 'Actualizar'}</p>
                        }
                    </button>
                    <button className="btn-coriport btn-cancel" onClick={() => actionChange('table')}>
                        Cancelar
                    </button>
                </div>

            </form>
        </section>

}