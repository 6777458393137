export const shopsErrors = ({ error, data }) => {
    let message = "";

    if (error === 500) return message = "error";

    if (error === 404 && data.message === 'Error, the shop with the sent shop id is not found') return message = 'La tienda ingresada no es válida';
    
    if (error === 400 && data.message === 'The shop you want to disable was already disabled') return message = 'La tienda ya está desactivada';
    
    if (error === 400 && data.message === 'The sent sender id does not exist') return message = 'El remitente ingresado no existe';

    if (error === 400 && data.message === 'The agent id does not exist') return message = 'El agente ingresado no existe';

    if (error === 400 && data.message === 'The store code does not have the requested structure. The expected structure is comma separated numbers without any white space') return message = 'El correo ingresado no es válido';

    if (error === 400 && data.message === 'The email does not have the requested structure. The expected structure is: username@MailServer.domain If you want to add more than one email you must separate them by comma without space') return message = 'El correo ingresado no es válido';

    if (error === 400 && data.message === 'The date activity started is greater than the date activity stopped') return message = 'Fechas no validas';

    if (error === 400 && data.message === 'A shop already exists with the submitted shop name') return message = 'El nombre ingresado ya existe';

    if(error === 400 && data.message.includes('records of the revenues table')) return message = 'La tienda a eliminar posee ingresos asociados';

    if(error === 400 && data.message.includes('records of the shop schedule table')) return message = 'La tienda a eliminar posee horarios asociados'
    
    if(error === 400 && data.message.includes('records of the invoice table')) return message = 'La tienda a eliminar posee facturas asociadas'

    return message;

};
