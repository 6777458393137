export default function validate(values){
    
    let errors = {};

    if(!values.group_name){
        errors.group_name = '*El nombre es requerido';
    }
    if(!values.id_agent){
        errors.id_agent = '*El agente es requerido';
    } 
    if(values.enable === undefined){
        errors.enable = '*El estado es requerido';
    }
    if(!values.id_vinci_product_group){
        errors.id_vinci_product_group = '*El grupo de vinci es requerido';
    }
    if(!values.id_product_category){
        errors.id_product_category = '*La categoría es requerida';
    }
    if(!values.group_code){
        errors.group_code = '*La grupo es requerido';
    } 

    return errors;
}