import React, { useEffect, useState } from 'react'
import { createRecord, editRecord } from '../../../request/Request';
import { filterSelectData, getDefaultOptionSelect, selectIdentificationType } from '../../../service/comboBox';
import { selectEnable, typeFilterSelect } from '../../../service/defaultData';
import useForm from '../../../validate-forms/useForm';
import validate from "../../../validate-forms/Sender/AddFormValidationRules";
import { ComboBox } from '../../ComboBox/ComboBox';
import { ErrorPage } from '../../ErrorPage/ErrorPage';
import { Loading } from '../../Loading/Loading';
import Warning from '../../Notification/Warning';
import { senderErrors } from '../../../service/ErrorNotification/senderErrors';

export const AddOrEdit = ({ actionChange, sender, createdOrEditSender, render }) => {

  const [errorMessage, setErrorMessage] = useState(false);
  const [defaultEnable, setDefaultEnable] = useState(null);
  const [loading, setLoading] = useState(true);

  const [identificationType, setIdentificationType] = useState([]);
  const [identificationTypeFiltered, setIdentificationTypeFiltered] = useState([]);
  const [defaultIdentificationType, setDefaultIdentificationType] = useState('');

  const [statusPetitionIdentifications, setStatusPetitionIdentifications] = useState(false);

  const [statusButton, setStatusButton] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');

  const { values, errors, handleChange, handleSubmit } = useForm(sendForm, validate);

  useEffect(() => {
    (async function () {
      setStatusPetitionIdentifications(true);
      const identifications = await selectIdentificationType();

      if (identifications.statusResponse) {
        setIdentificationType(identifications.data);
        setStatusPetitionIdentifications(false); 
      } else {
        setStatusPetitionIdentifications(false);
        setErrorMessage(true);
        setLoading(false);
      }

    })();
  }, []);

  useEffect(() => {
    if (render === 'add') {
      let identificationTypeEnable = filterSelectData(typeFilterSelect.add, identificationType);
      setIdentificationTypeFiltered(identificationTypeEnable);
      setLoading(false);
    } else {
      let defaultIdentificationTypeTemp = getDefaultOptionSelect(identificationType, sender.id_identification_type);
      setDefaultIdentificationType(defaultIdentificationTypeTemp[0]);

      let identificationTypeEnableAndDisabled = filterSelectData(typeFilterSelect.edit, identificationType, defaultIdentificationTypeTemp);
      setIdentificationTypeFiltered(identificationTypeEnableAndDisabled);

      let defaultEnableTemp = getDefaultOptionSelect(selectEnable, sender.enable);
      setDefaultEnable(defaultEnableTemp);

      values.id_sender = sender.id_sender;
      values.id_identification_type = sender.id_identification_type;
      values.sender_email = sender.sender_email;
      values.sender_name = sender.sender_name;
      values.enable = sender.enable;
 
      
    }
  }, [identificationType])

  useEffect(() => {
    if (defaultIdentificationType && defaultEnable && render === 'edit') {
      setLoading(false); 
    }
  }, [defaultIdentificationType, defaultEnable])
  
  async function sendForm() {
    setWarningMessage('');
    setStatusButton(true);
    if (render !== 'add') {
      await editRecord(`/sender/${sender.id_sender}`, values).then(request => {
        setStatusButton(false);
        if (request.statusResponse) {
          redirect('edit');
        }
      }).catch(error => getErrors(error));
    } else {
      await createRecord(`/sender`, values).then(request => {
        setStatusButton(false);
        if (request.statusResponse) {
          redirect('created');
        }
      }).catch(error => getErrors(error));
    }

  }

  const getErrors = (error) => {

    setWarningMessage('');
    setErrorMessage(false);

    let respondeError = senderErrors(error);

    if (respondeError === 'error') return setErrorMessage(true);

    setWarningMessage(respondeError);
    setStatusButton(false);

  }

  const redirect = (action) => {
    createdOrEditSender(action);
    actionChange('table');
  }

  const comboBoxChange = (e) => handleChange(e);

  return loading ? <Loading /> : errorMessage ? <ErrorPage redirect={true} actionChange={actionChange} name={'Remitente'} /> :
    <section className="section form-color">
      <form onSubmit={handleSubmit} noValidate>
        <div className="acction-card"><span aria-hidden="true">{render === 'add' ? 'Agregar' : 'Actualizar'} Remitente</span></div>

        {warningMessage !== '' && <Warning text={warningMessage} />}

        <div className="row">
          <div className="col">
            <div className="form-group coriport-input">
              <label htmlFor="sender_name" className="form-label">Nombre</label>
              <input
                type="text"
                className={`input-form form-control ${errors.sender_name && 'is-danger'}`}
                placeholder="Nombre"
                name="sender_name"
                onChange={handleChange}
                value={values.sender_name || ''}
                required
              />
              {errors.sender_name && (<p className="help is-danger">{errors.sender_name}</p>)}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group coriport-input">
              <label htmlFor="sender_email" className="form-label">Email</label>
              <input
                type="text"
                className={`input-form form-control ${errors.sender_email && 'is-danger'}`}
                placeholder="email@email.com"
                name="sender_email"
                onChange={handleChange}
                value={values.sender_email || ''}
                required
              />
              {errors.sender_email && (<p className="help is-danger">{errors.sender_email}</p>)}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group coriport-input">
              <label htmlFor="id_sender" className="form-label">Identificación</label>
              <input
                readOnly={render === 'edit' && true}
                type="text" className={`input-form form-control ${errors.id_sender && 'is-danger'}`}
                placeholder="Identificación"
                name="id_sender"
                onChange={handleChange}
                value={values.id_sender || ''}
                required
              />
              {errors.id_sender && (<p className="help is-danger">{errors.id_sender}</p>)}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="form-group coriport-input">
              <ComboBox
                name={"id_identification_type"}
                text={"Tipo de Identificación"}
                options={identificationTypeFiltered}
                onChange={comboBoxChange}
                defaultValueSelect={defaultIdentificationType}
                isLoading={statusPetitionIdentifications}
              />
              {errors.id_identification_type && (<p className="help is-danger">{errors.id_identification_type}</p>)}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group coriport-input">
              <ComboBox
                name={"enable"}
                text={"Estado"}
                options={selectEnable}
                onChange={comboBoxChange}
                defaultValueSelect={defaultEnable}
              />
              {errors.enable && (<p className="help is-danger">{errors.enable}</p>)}
            </div>
          </div>
        </div>

        <div className="container-bottons">
          <button disabled={statusButton} id='custom-btn-coriport' className=" btn  btn-coriport btn-add text-white " type="submit">
            {
              statusButton ? <div className="container-spinner-loading pt-2">
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              </div> : <p className="label-button-add"> {render == 'add' ? 'Agregar' : 'Actualizar'}</p>
            }
          </button>
          <button className="btn-coriport btn-cancel" onClick={() => actionChange('table')}>
            Cancelar
          </button>
        </div>

      </form>
    </section>

} 