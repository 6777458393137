import React from "react";
import { getAll, sftpDownload } from "../../request/Request";
import { getReportServer } from "../../request/Request";

import DataTableMaterial from "../DataTableMaterial/DataTableMaterial";
import moment from 'moment';
import { HeaderTable } from "../HeaderTable";
import Success from "../Notification/Success";
import { ParamSearch } from "./ParamSearch/ParamSearch";
import ModalInvoice from "../Modal/ModalInvoice";
import { selectShop } from "../../service/comboBox"; 
import { customColumn } from "../../service/dataTable";

export class Invoice extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            dataTable: {
                data: [],
                perPage: 10,
                total: 0,
                currentPage: 1,
            },
            searchedParams: {
                idShop: '',
                invoiceYear: '',
                invoiceMonth: '',
                idVoucherType: '',
                dateIssueStart: '',
                dateIssueEnd: '',
                documentNumber: '',
                fileType: ''
            },
            errorsRequest: {
                errorMessage: '',
                errorStatus: false
            },
            successRequest: {
                successMessage: '',
                successStatus: false
            },
            shops: [],
            shopSelected: {
                value: 0,
                label: ''
            },
            modalInvoice: {
                show: false,
                data: {}
            },
            allInvoice: [],
            allVoucher: [],
            statusButton: false,
            statusPetition: false
        };
        this.getRowsPerPage = this.getRowsPerPage.bind(this);
        this.getCurrentPage = this.getCurrentPage.bind(this);
        this.handleSeeInvoice = this.handleSeeInvoice.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }

    componentDidMount() {
        this.getallVoucherType();
        this.getAllShop();
    }

    componentDidUpdate(prevProps, prevState) {
        let { dataTable, successRequest } = this.state;
        if (prevState.dataTable.perPage !== dataTable.perPage || prevState.dataTable.currentPage !== dataTable.currentPage) {
            this.getAllInvoice(dataTable.currentPage);            
        }
    }

    getCurrentPage(page) {
        let { dataTable, statusPetition } = this.state;
        if (!statusPetition) {
            this.setState({ dataTable: { ...dataTable, currentPage: page } });
        }
    }

    getRowsPerPage(perPage) {
        let { dataTable } = this.state;
        this.setState({ dataTable: { ...dataTable, perPage: perPage } });
    }

    handleSearch(values) {

        if (values.idShop) {
            let newState = this.state.searchedParams;
            newState.idShop = values.idShop;
        }
        if (values.documentNumber) {
            let newState = this.state.searchedParams;
            newState.documentNumber = values.documentNumber;
        }else{
            let newState = this.state.searchedParams;
            newState.documentNumber = '';
        }
        if (values.idVoucherType) {
            let newState = this.state.searchedParams;
            newState.idVoucherType = values.idVoucherType;
        } else {
            let newState = this.state.searchedParams;
            newState.idVoucherType = '';
        }
        if (values.invoiceMonth) {
            let newState = this.state.searchedParams;
            newState.invoiceMonth = values.invoiceMonth;
        } else {
            let newState = this.state.searchedParams;
            newState.invoiceMonth = '';
        }
        if (values.invoiceYear) {
            let newState = this.state.searchedParams;
            newState.invoiceYear = values.invoiceYear;
        } else {
            let newState = this.state.searchedParams;
            newState.invoiceYear = '';
        }
        if (values.dateIssueStart && values.dateIssueEnd && values.dateIssueStart < values.dateIssueEnd) {
            let newStateStartdate = this.state.searchedParams;
            let newStateEndDate = this.state.searchedParams;
            newStateStartdate.dateIssueStart = values.dateIssueStart;
            newStateEndDate.dateIssueEnd = values.dateIssueEnd;
        } else {
            let newStateStartdate = this.state.searchedParams;
            let newStateEndDate = this.state.searchedParams;
            newStateStartdate.dateIssueStart = '';
            newStateEndDate.dateIssueEnd = '';
        }
 
        this.setState({ loading: true }); 
        if(values.action == 'search' ){ 
            this.getAllInvoice(1);
        }else {
            this.getReport();
        }
        
    }

    async getallVoucherType() {
        await getAll(`/vouchertype?page=0&perPage=10&order=desc&paginated=false`).then(request => {
            if (request.statusResponse) {
                this.setState({ allVoucher: request.data.data });
            }
        }).catch(error => {
            if (error.error !== 404) {
                this.setState({ errorsRequest: { errorMessage: 'Facturas', errorStatus: true } });
            }
        });
    }

    async getAllShop() {
        const selectShops = await selectShop();
        if (selectShops.statusResponse) {
            this.setState({ shops: selectShops.data });
            this.setState({ loading: false });
        } else {
            this.setState({ errorsRequest: { errorMessage: 'Facturas', errorStatus: true } });
            this.setState({ loading: false });
        }
    }

    async getReport() {        
        this.setState({ successMessage: 'Archivo enviado por correo electrónico', successStatus: true });
        let {  searchedParams } = this.state;
        searchedParams.fileType = 'reportinvoice'
        this.setState({ statusPetition: true });
        await sftpDownload(`/sftp/report/invoice`, searchedParams).then(request => {
            let { statusResponse } = request;
            if (statusResponse) {               
                this.successNotifiaction('Archivo enviado por correo electrónico', true);
            } 
        }).catch(error => {
            if (error.error === 404) { 
                this.setState({ successMessage: 'no se pudo enviar el archivo', successStatus: true });
            } else {
                this.setState({ errorsRequest: { errorMessage: 'reporte de facturas', errorStatus: true } });
            }
            
        });
        this.setState({ statusPetition: false });
        this.setState({ loading: false });
    }

    async getAllInvoice(currentPage) {

        this.setState({ statusButton: true });

        let { dataTable, searchedParams } = this.state;
        if (!dataTable.data.length) this.setState({ loading: true });

        let voucherType = '';

        if (searchedParams.idVoucherType !== '') {
            voucherType = `&idVoucherType=${searchedParams.idVoucherType}`
        } else {
            voucherType = ``;
        }
        this.setState({ statusPetition: true });
        await getAll(`/invoice?page=${currentPage - 1}&perPage=${dataTable.perPage}&idShop=${searchedParams.idShop}&order=desc&invoiceYear=${searchedParams.invoiceYear}&invoiceMonth=${searchedParams.invoiceMonth}${voucherType}&dateIssueStart=${searchedParams.dateIssueStart}&dateIssueEnd=${searchedParams.dateIssueEnd}&documentNumber=${searchedParams.documentNumber}`).then(request => {
            let { statusResponse, data } = request;
            if (statusResponse && request.data.data) {
                this.setState({ dataTable: { ...dataTable, total: data.total, data: data.data } }); 
            } else { 
                this.setState({ dataTable: { ...dataTable, total: 0, data: [] } });
            } 

        }).catch(error => {
            if (error.error === 404) { 
                this.setState({ dataTable: { ...dataTable, total: 0, data: [] } });
            } else {
                this.setState({ errorsRequest: { errorMessage: 'Facturas', errorStatus: true } });
            }
            
        });
        this.setState({ statusPetition: false });
        this.setState({ statusButton: false });
        this.setState({ loading: false });
    }

    async handleSeeInvoice(invoice) {

        let { modalInvoice } = this.state;

        await getAll(`/invoice/${invoice.id_invoice}`).then(request => {
            let { statusResponse, data } = request;
            if (statusResponse && request.data.payload) {
                this.setState({ modalInvoice: { ...modalInvoice, show: true, data: data.payload } })
            }
        }).catch(error => {
            this.setState({ errorsRequest: { errorMessage: 'Facturas', errorStatus: true } });
        });

    }

    successNotifiaction(msg, status) {
        this.setState({ ...this.state, successRequest: { successMessage: msg, successStatus: status } });
        setTimeout(() => {
          this.setState({ ...this.state, successRequest: { successMessage: '', successStatus: false } });
        }, 2500);
      }

    materialDataTable() {
        let { dataTable, loading, errorsRequest, allVoucher } = this.state;
        let columns = [
            {
                ...customColumn('N. de Documento', row => row.document_number),
                cell: row => <p className="capitalize-text"> {`${row.document_number}`}  </p>,
            },
            {
                ...customColumn('Consecutivo', row => row.consecutive_number),
                cell: row => <p className="capitalize-text"> {`${row.consecutive_number}`}  </p>,
            },
            {
                ...customColumn('Tipo de Documento', row => row.id_voucher_type),
                cell: row => <p className="capitalize-text">  {allVoucher.map(v => {
                    if (v.id_voucher_type === row.id_voucher_type) {
                        return `${v.description}`
                    }
                })} </p>,
            },
            {
                ...customColumn('Fecha', row => row.date_issue),
                cell: row => <p className="capitalize-text"> {row.date_issue ? moment(row.date_issue).format('DD/MM/YYYY, h:mm a') : ''}</p>,
            },
            {
                ...customColumn('Moneda', row => row.currency_code),
                cell: row => <div> {row.currency_code}  </div>,
            },
            {
                ...customColumn('Monto Neto', row => row.total_net),
                cell: row =>
                    <div id="format-amounts"> {
                        `${Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2, })
                            .format(row.total_net)}`
                    } </div>,
            },
            {
                ...customColumn('Monto Total', row => row.total_check),
                cell: row => <div id="format-amounts">{
                    `${Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2, })
                        .format(row.total_check)}`
                }</div>,
            },
            {
                ...customColumn('Acciones'),
                cell: (row) => (
                    <button onClick={() => { this.handleSeeInvoice(row) }} className="botton-find">
                        <svg className="svg-icon" viewBox="0 0 20 20">
                            <path d="M10,6.978c-1.666,0-3.022,1.356-3.022,3.022S8.334,13.022,10,13.022s3.022-1.356,3.022-3.022S11.666,6.978,10,6.978M10,12.267c-1.25,0-2.267-1.017-2.267-2.267c0-1.25,1.016-2.267,2.267-2.267c1.251,0,2.267,1.016,2.267,2.267C12.267,11.25,11.251,12.267,10,12.267 M18.391,9.733l-1.624-1.639C14.966,6.279,12.563,5.278,10,5.278S5.034,6.279,3.234,8.094L1.609,9.733c-0.146,0.147-0.146,0.386,0,0.533l1.625,1.639c1.8,1.815,4.203,2.816,6.766,2.816s4.966-1.001,6.767-2.816l1.624-1.639C18.536,10.119,18.536,9.881,18.391,9.733 M16.229,11.373c-1.656,1.672-3.868,2.594-6.229,2.594s-4.573-0.922-6.23-2.594L2.41,10l1.36-1.374C5.427,6.955,7.639,6.033,10,6.033s4.573,0.922,6.229,2.593L17.59,10L16.229,11.373z"></path>
                        </svg>
                    </button>
                ),
            },

        ];
        return <DataTableMaterial
            dataTableData={dataTable}
            loading={loading}
            errors={errorsRequest}
            columns={columns}
            getRowsPerPage={this.getRowsPerPage}
            getCurrentPage={this.getCurrentPage}
        />;
    }

    render() {

        let { dataTable, successRequest, errorsRequest, shops, shopSelected, modalInvoice, allVoucher, statusButton } = this.state;

        return (
            <div className="col-lg-12 mt-5">
                {
                    <div className="card-table">

                        <ModalInvoice dataInvoice={modalInvoice} />
                        <HeaderTable title={'Facturas'} />

                        {successRequest.successStatus && <Success text={successRequest.successMessage} />}

                        {!errorsRequest.errorStatus &&
                            <div className="mt-3" >
                                <ParamSearch
                                    dataTable={dataTable.data.length}
                                    statusButton={statusButton}
                                    vouchers={allVoucher}
                                    shops={shops}
                                    defaultValueSelect={shopSelected.label}
                                    handleSearch={this.handleSearch} />
                            </div>
                        }

                        <div className="mb-5 mt-4">
                            {this.materialDataTable()}
                        </div>

                    </div>
                }
            </div>
        )
    }

}   