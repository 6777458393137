import React, { useEffect, useState } from 'react'
import { createRecord, editRecord } from '../../../request/Request';
import { voucherTypeErrors } from '../../../service/ErrorNotification/voucherTypeErrors';
import { selectEnable } from '../../../service/defaultData';
import useForm from '../../../validate-forms/useForm';
import validate from "../../../validate-forms/VoucherType/AddFormValidationRules";
import { ComboBox } from '../../ComboBox/ComboBox';
import { ErrorPage } from '../../ErrorPage/ErrorPage';
import { Loading } from '../../Loading/Loading';
import Warning from '../../Notification/Warning';
import { getDefaultOptionSelect } from '../../../service/comboBox';

export const AddOrEdit = ({ actionChange, voucher, createdOrEditVoucher, render }) => {

  const [errorMessage, setErrorMessage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [defaultEnable, setDefaultEnable] = useState(null);
  const [statusButton, setStatusButton] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');

  const { values, errors, handleChange, handleSubmit } = useForm(sendForm, validate);

  useEffect(() => {
    if (render === 'edit') {

      let defaultEnableTemp = getDefaultOptionSelect(selectEnable, voucher.enable);
      setDefaultEnable(defaultEnableTemp[0]);

      values.id_voucher_type = voucher.id_voucher_type;
      values.description = voucher.description;
      values.enable = voucher.enable;
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [])


  async function sendForm() {
    setWarningMessage('');
    setStatusButton(true);
    if (render !== 'add') {
      await editRecord(`/vouchertype/${voucher.id_voucher_type}`, values).then(request => {
        setStatusButton(false);
        if (request.statusResponse) {
          redirect('edit');
        }
      }).catch(error => getErrors(error));

    } else {
      await createRecord(`/vouchertype`, values).then(request => {
        setStatusButton(false);
        if (request.statusResponse) {
          redirect('created');
        }
      }).catch(error => getErrors(error));
    }

  }

  const getErrors = (error) => {

    setWarningMessage('');
    setErrorMessage(false);

    let respondeError = voucherTypeErrors(error);

    if (respondeError === 'error') return setErrorMessage(true);

    setWarningMessage(respondeError);
    setStatusButton(false);

  }

  const redirect = (action) => {
    createdOrEditVoucher(action);
    actionChange('table');
  }

  const comboBoxChange = (e) => handleChange(e);

  return loading ? <Loading /> : errorMessage ? <ErrorPage redirect={true} actionChange={actionChange} name={'Tipo de documento'} /> :
    <section className="section form-color">
      <form onSubmit={handleSubmit} noValidate>
        <div className="acction-card"><span className='ml-2' aria-hidden="true"> {render === 'add' ? 'Agregar' : 'Editar'} T. de Documento </span></div>

        {warningMessage !== '' && <Warning text={warningMessage} />}

        <div className="row">
          <div className="col-md-4">
            <div className="form-group coriport-input">
              <label htmlFor="id_voucher_type" className="form-label">Tipo</label>
              <input readOnly={render === 'edit'} type="text" className={`input-form form-control ${errors.id_voucher_type && 'is-danger'}`} placeholder="Nombre" name="id_voucher_type" onChange={handleChange} value={values.id_voucher_type || ''} required />
              {errors.id_voucher_type && (
                <p className="help is-danger">{errors.id_voucher_type}</p>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group coriport-input">
              <label htmlFor="description" className="form-label">Descripción</label>
              <input type="text" className={`input-form form-control ${errors.description && 'is-danger'}`} max="100" placeholder="Descripción" name="description" onChange={handleChange} value={values.description || ''} required />
              {errors.description && (
                <p className="help is-danger">{errors.description}</p>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group coriport-input">
              <ComboBox name={"enable"} text={"Estado"} options={selectEnable} onChange={comboBoxChange} defaultValueSelect={defaultEnable} />
              {errors.enable && (
                <p className="help is-danger">{errors.enable}</p>
              )}
            </div>
          </div>
        </div>

        <div className="container-bottons">
          <button disabled={statusButton} id='custom-btn-coriport' className=" btn  btn-coriport btn-add text-white " type="submit">
            {
              statusButton ? <div className="container-spinner-loading pt-2">
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              </div> : <p className="label-button-add"> {render === 'add' ? 'Agregar' : 'Actualizar'}</p>
            }
          </button>
          <button className="btn-coriport btn-cancel" onClick={() => actionChange('table')}>
            Cancelar
          </button>
        </div>

      </form>
    </section>

} 