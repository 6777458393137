export const parameterErrors = ({ error, data }) => {
    let message = "";

    if (error === 500) return message = "error";

    if (error === 400) return message = 'El tipo ingresado ya existe';

    if (error === 404) return message = 'El parámetro ingresado no es válido';

    return message;

};
