import React, { useEffect, useState } from "react";
import validate from "../../../validate-forms/ProductCategoryVinci/AddFormValidationRules";
import { createRecord, editRecord } from "../../../request/Request";
import { selectEnable } from "../../../service/defaultData";
import useForm from "../../../validate-forms/useForm";
import { ErrorPage } from "../../ErrorPage/ErrorPage";
import { ComboBox } from "../../ComboBox/ComboBox";
import { Loading } from "../../Loading/Loading";
import './AddOrEdit.css';
import Warning from "../../Notification/Warning";
import { productCategoryVinciErrors } from "../../../service/ErrorNotification/productCategoryVinciErrors";
import { getDefaultOptionSelect } from "../../../service/comboBox";

export const AddOrEdit = ({ actionChange, createdOrEditProductCategory, productCategory, render }) => {

    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(false);

    const [defaultEnable, setDefaultEnable] = useState(null);

    const [statusButton, setStatusButton] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');

    const { values, errors, handleChange, handleSubmit } = useForm(sendForm, validate);

    useEffect(() => {
        if (render === 'add') {
            setLoading(false);
        } else {
            values.category_name = productCategory.category_name;
            let defaultEnableTemp = getDefaultOptionSelect(selectEnable, productCategory.enable);
            setDefaultEnable(defaultEnableTemp[0]);

            setLoading(false);
        }
    }, []);


    async function sendForm() {
        setWarningMessage('');
        setStatusButton(true);
        if (render === 'add') {
            await createRecord(`/vinciproductcategory`, values).then(request => {
                setStatusButton(false);
                if (request.statusResponse) {
                    redirect('created');
                }
            }).catch(error => getErrors(error));
        } else {
            await editRecord(`/vinciproductcategory/${productCategory.id_vinci_product_category}`, values).then(request => {
                setStatusButton(false);
                if (request.statusResponse) {
                    redirect('edit');
                }
            }).catch(error => getErrors(error));
        }
    }

    const getErrors = (error) => {

        setWarningMessage('');
        setErrorMessage(false);

        let respondeError = productCategoryVinciErrors(error);

        if (respondeError === 'error') return setErrorMessage(true);

        setWarningMessage(respondeError);
        setStatusButton(false);

    }

    const redirect = (action) => {
        createdOrEditProductCategory(action);
        actionChange('table');
    }

    function comboBoxChange(e) {
        handleChange(e);
    }

    return loading ? <Loading /> : errorMessage ? <ErrorPage redirect={true} actionChange={actionChange} name={'Categoría de Productos Vinci'} /> :
        <section className="section form-color">
            <form onSubmit={handleSubmit} noValidate>
                <div className="acction-card"><span aria-hidden="true">
                    {render === 'add' ? 'Agregar ' : 'Actualizar '} Categoría</span>
                </div>

                {warningMessage !== '' && <Warning text={warningMessage} />}

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group coriport-input">
                            <label htmlFor="category_name" className="form-label">Nombre de la Categoría</label>
                            <input
                                type="text"
                                className={`input-form form-control ${errors.category_name && 'is-danger'}`}
                                placeholder="Nombre de la Categoría"
                                name="category_name"
                                onChange={handleChange}
                                value={values.category_name || ''}
                                required
                            />
                            {errors.category_name && (<p className="help is-danger">{errors.category_name}</p>)}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group coriport-input">
                            <ComboBox
                                name={"enable"}
                                text={"Estado"}
                                options={selectEnable}
                                onChange={comboBoxChange}
                                defaultValueSelect={defaultEnable}
                            />
                            {errors.enable && (<p className="help is-danger">{errors.enable}</p>)}
                        </div>
                    </div>
                </div>

                <div className="container-bottons">
                    <button disabled={statusButton} id='custom-btn-coriport' className=" btn  btn-coriport btn-add text-white " type="submit">
                        {
                            statusButton ? <div className="container-spinner-loading pt-2">
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </div> : <p className="label-button-add"> {render == 'add' ? 'Agregar' : 'Actualizar'}</p>
                        }
                    </button>
                    <button className="btn-coriport btn-cancel" onClick={() => actionChange('table')}>
                        Cancelar
                    </button>
                </div>

            </form>
        </section>

}