import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { getAll } from '../../../request/Request';
import { Loading } from '../../Loading/Loading';
import moment from 'moment';

export const RevenueSection = (props) => {

    const [revenues, setRevenues] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(false);

    useEffect(() => {
        getAllRevenues();
    }, []);

    const getAllRevenues = async () => {
        await getAll(`/revenues?page=0&perPage=10&idShop=${props.shop.id_shop}&order=desc&paginated=false`).then(request => {
            let { statusResponse, data } = request;
            if (statusResponse) {
                if (data?.data) {
                    setRevenues(data.data);
                } else {
                    setErrorMessage(true);
                }
                setLoading(false);
            }
        }).catch(error => {
            if (error.error === 404) {
                setRevenues(null);
                setErrorMessage(true);
            } else {
                setErrorMessage(true);
            }
            setLoading(false);
        });
    }

    return (
        <>
            {
                loading ? <Loading /> : errorMessage ? <div id='not-records' className='col-12' >
                    <h3 className='mt-5'>No hay registros por mostrar</h3>
                </div> : <div>
                    <div className="row">
                        {
                            revenues?.length > 0 && revenues.map((d, index) => (
                                <div key={index} className="col-md-12 mt-3 mb-4 pl-4 text-left list-group-flush">
                                    <div className="row ">
                                        <div className="col-6">
                                            <span className='style-span'>-Fecha Inicio: </span> <span> {moment(d.date_from).format('DD/MM/YYYY')} </span><br />
                                            <span className='style-span'>-Fecha Final: </span> <span> {moment(d.date_to).format('DD/MM/YYYY')} </span><br />
                                            <span className='style-span'>-Mag: </span> <span>{`${Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(d.mag)}`}</span><br />
                                            <span className='style-span'>-Ingresos Fijos Brutos: </span> <span>{`${Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(d.mag)}`} </span><br />
                                            <span className='style-span'>-Ingresos Fijos Netos: </span> <span>{`${Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(d.mag)}`} </span><br />
                                            <span className='style-span'>-Ingresos Mínimos Brutos: </span> <span>{`${Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(d.mag)}`} </span><br />
                                        </div>
                                        <div className="col-6">
                                            <span className='style-span'>-Ingresos Mínimos Netos: </span> <span>{`${Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(d.minimun_revenues_net)}`} </span><br />
                                            <span className='style-span'>-Ventas: </span> <span>{`${Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(d.sales)}`} </span><br />
                                            <span className='style-span'>-Ingresos Totales Brutos: </span> <span>{`${Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(d.total_revenues_gross)}`} </span><br />
                                            <span className='style-span'>-Ingresos Totales Netos: </span> <span>{`${Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(d.total_revenues_net)}`} </span><br />
                                            <span className='style-span'>-Ingresos Variables Brutos: </span> <span>{`${Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(d.variable_revenues_gross)}`} </span><br />
                                            <span className='style-span'>-Ingresos Variables Netos: </span> <span>{`${Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(d.variable_revenues_net)}`} </span><br />
                                        </div>
                                    </div>
                                    {
                                        revenues?.length > (index + 1) && <div>  <br /><hr /></div>
                                    }

                                </div>
                            ))
                        }
                    </div>
                </div>
            }
        </>
    )
}
