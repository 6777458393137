import React, { useState, useEffect } from 'react';
import { sftpDownload } from '../../request/Request';
import { selectAgent } from '../../service/comboBox';
import { typeFileReportDownload } from '../../service/defaultData';
import validate from '../../validate-forms/Report/DownloadReportFormValidationRules copy';
import useForm from '../../validate-forms/useForm';
import { ComboBox } from '../ComboBox/ComboBox';
import { ErrorPage } from '../ErrorPage/ErrorPage';
import './Report.css';
import { CustomDatePicker } from '../CustomDatePicker/CustomDatePicker';
import moment from 'moment';
import Success from '../Notification/Success'; 

export const Download = () => {

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [successRequest, setSuccessRequest] = useState({ successMessage: '', successStatus: false });
    const [agents, setAgents] = useState(null);
    const [disabledCombo, setDisabledCombo] = useState(true);  

    const [resetSelectAgents, setResetSelectAgents] = useState(false);

    const { values, errors, handleChange, handleSubmit } = useForm(sendForm, validate);

    useEffect(() => {
        getAllAgent();
    }, []);

    const getAllAgent = async () => {
        const agentTemp = await selectAgent();
        if (agentTemp.statusResponse) {
            setAgents(agentTemp.data)
        } else {
            setErrorMessage(true);
        }
    }

    async function sendForm() {

        setLoading(true);
        setSuccessRequest({ successMessage: '', successStatus: false });

        values.frequency = 'weekly';
        let dateIssueStartTemp = moment(values.startDate).format('YYYY-MM-DDTHH:mm:ss');
        let dateIssueEndTemp = moment(values.endDate).format('YYYY-MM-DDTHH:mm:ss');
        
        if (values.fileType === 'unclassifiedproducts' || values.fileType === 'vincicategories') { // Este reporte no necesita fecha
            dateIssueStartTemp = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
            dateIssueEndTemp = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');    
        }

        values.startDate = dateIssueStartTemp;
        values.endDate = dateIssueEndTemp;

        await sftpDownload(`/sftp/download`, values).then(request => {
            if (request.statusResponse) {
                setLoading(false);
                setSuccessRequest({ successMessage: 'Archivo enviado por correo electrónico', successStatus: true });
            }
        }).catch(error => {
            setErrorMessage(true);
            setLoading(false);
        });

    }

    const comboBoxChangeTypeReport = (e) => { 
        setResetSelectAgents(false);
        if ((e.target.value === "consolidatedbyagent" || e.target.value === "dailybyagent" || e.target.value === "unclassifiedproducts") && e.target.name === "fileType") {
            setDisabledCombo(false);
        } else {
            setResetSelectAgents(true);
            setDisabledCombo(true); 
            
        } 
        handleChange(e);
    };

    const comboBoxChangeAgent = (e) => handleChange(e);
 
    const datePickerChange = (e) => handleChange(e);

    const handleStartDate = (date) => values.startDate = date;

    const handleEndDate = (date) => values.endDate = date;

    return errorMessage ? <ErrorPage name={'Reportes'} /> : <div className="container-dates-report">

        <form onSubmit={handleSubmit} noValidate>
            <div className="period-name-report"><h5>Descarga de Archivos </h5></div>

            {successRequest.successStatus && <Success text={successRequest.successMessage} />}

            <div className="row">
                <div className="col-md-3">
                    <div className="form-group">
                        <CustomDatePicker type={'date-time'} onChange={datePickerChange} name='startDate' placeHolder={'dd/mm/aaaa --:--'} getDate={handleStartDate} title={'Fecha de Inicio'} />
                        {errors.startDate && (
                            <p className="help is-danger">{errors.startDate}</p>
                        )}
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <CustomDatePicker type={'date-time'} onChange={datePickerChange} name='endDate' placeHolder={'dd/mm/aaaa --:--'} getDate={handleEndDate} title={'Fecha Final'} />
                        {errors.endDate && (
                            <p className="help is-danger">{errors.endDate}</p>
                        )}
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group coriport-input">
                        <ComboBox name={"fileType"} text={"Tipo de Reporte"} options={typeFileReportDownload} onChange={comboBoxChangeTypeReport} />
                        {errors.fileType && (
                            <p className="help is-danger">{errors.fileType}</p>
                        )}
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group coriport-input">
                        <ComboBox resetSelect={resetSelectAgents} isDisabled={disabledCombo} name={"idAgent"} text={"Seleccionar Agente"} options={agents} onChange={comboBoxChangeAgent} />
                        {errors.idAgent && (
                            <p className="help is-danger">{errors.idAgent}</p>
                        )}
                    </div>
                </div>
            </div>

            <div className="container-bottons-report" >
                <button disabled={loading} className="btn btn-coriport btn-add text-white btn-download" type="submit">
                    {
                        loading ? <div className="container-spinner-loading pt-2">
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </div> : 'Enviar'
                    }
                </button>
            </div>

        </form>

    </div>

}
