import React from "react";
import { deleteRecord, disableRecord, getAll } from "../../request/Request";
import { customColumn } from "../../service/dataTable";
import { agentErrors } from "../../service/ErrorNotification/agentErrors";
import { ButtonAdd } from "../ButtonAdd";
import DataTableMaterial from "../DataTableMaterial/DataTableMaterial";
import { HeaderTable } from "../HeaderTable";
import { ModalAgents } from "../Modal/ModalAgents";
import ModalToDelete from "../Modal/ModalToDelete";
import ModalToEnable from "../Modal/ModalToEnable";
import Success from "../Notification/Success";
import Warning from "../Notification/Warning";
import { Search } from "../Search/Search";
import Add from "./Add/Add";
import { Edit } from "./Edit";

export class Agent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      render: 'table',
      loading: true,
      dataTable: {
        data: [],
        perPage: 10,
        total: 0,
        currentPage: 1,
      },
      errorsRequest: {
        errorMessage: '',
        errorStatus: false
      },
      successRequest: {
        successMessage: '',
        successStatus: false
      },
      warningRequest: {
        successMessage: '',
        successStatus: false
      },
      dataToDelete: {
        show: false,
        data: {}
      },
      dataToDisable: {
        show: false,
        data: {}
      },
      agentSelected: {},
      searchParam: '',
      defaultSearchParam: '',
      modalAgent: {
        show: false,
        data: {}
      },
      statusPetition: false,
    };
    this.getRowsPerPage = this.getRowsPerPage.bind(this);
    this.actionChange = this.actionChange.bind(this);
    this.getCurrentPage = this.getCurrentPage.bind(this);
    this.disableAgent = this.disableAgent.bind(this);
    this.deleteAgent = this.deleteAgent.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.searchAgent = this.searchAgent.bind(this);
    this.handleOpenModalAgent = this.handleOpenModalAgent.bind(this);
    this.handleCloseModalAgent = this.handleCloseModalAgent.bind(this);

  }

  componentDidMount() {
    this.getAllAgent();
    this.setState({ modalAgent: { show: false, data: {} } });
    this.setState({ dataToDelete: { show: false, data: {} } });
    this.setState({ dataToDisable: { show: false, data: {} } });
  }

  componentDidUpdate(prevProps, prevState) {
    let { dataTable, render, searchParam } = this.state;
    if ((prevState.dataTable.perPage !== dataTable.perPage ||
      prevState.dataTable.currentPage !== dataTable.currentPage) ||
      (prevState.render !== render && render === 'table')) {
      this.setState({ defaultSearchParam: searchParam });
      this.getAllAgent();
    }
  }

  getCurrentPage(page) {
    let { dataTable, statusPetition } = this.state;
    if (!statusPetition) {
      this.setState({ dataTable: { ...dataTable, currentPage: page } });
    }
  }

  getRowsPerPage(perPage) {
    this.setState({ dataTable: { ...this.state.dataTable, perPage: perPage } });
  }

  handleDelete(product) {
    this.setState({ dataToDelete: { show: true, data: { ...product, name: product.client_name } } });
  }

  async getAllAgent() {

    let { dataTable, searchParam } = this.state;

    if (!dataTable.data.length) this.setState({ loading: true });

    this.setState({ statusPetition: true });
    await getAll(`/agent?page=${this.state.dataTable.currentPage - 1}&perPage=${this.state.dataTable.perPage}&order=desc&paginated=true&search=${searchParam}`).then(request => {
      let { data } = request;
      this.setState({ dataTable: { ...dataTable, total: data.total, data: data.data } });

    }).catch(error => {
      if (error.error === 404) {
        this.setState({ dataTable: { ...dataTable, total: 0, data: [] } });
      } else {
        this.setState({ errorsRequest: { errorMessage: 'Agentes', errorStatus: true } });
      }
    });
    this.setState({ statusPetition: false });
    this.setState({ loading: false });
  }

  async deleteAgent(agent) {

    await deleteRecord(`/agent/${agent.id_agent}`).then(request => {
      let { dataTable } = this.state;
      let agentTemp = dataTable.data.filter(a => a.id_agent !== agent.id_agent);
      this.setState({ dataTable: { ...dataTable, total: agentTemp.length, data: agentTemp } });
      this.successNotifiaction('Agente eliminado', true);

    }).catch(error => {
      let respondeError = agentErrors(error);
      if (respondeError === 'error') return this.setState({ errorsRequest: { errorMessage: 'Agentes', errorStatus: true } });
      this.warningNotification(respondeError, true);

    });
    this.setState({ dataToDelete: { show: false, data: {} } });

  }

  handleEnable(agent) {
    this.setState({ dataToDisable: { show: true, data: { ...agent, name: agent.client_name } } });
  }
  async disableAgent(agent) {
    await disableRecord(`/agent/${agent.id_agent}`).then(request => {

      let { dataTable } = this.state;
      dataTable.data.map(function (a) {
        if (a.id_agent === agent.id_agent) a.enable = 'DIS';
      });
      this.successNotifiaction('Agente desactivado', true);
      this.setState({ ...dataTable, data: dataTable.data });

    }).catch(error => {
      let respondeError = agentErrors(error);
      if (respondeError === 'error') return this.setState({ errorsRequest: { errorMessage: 'Agentes', errorStatus: true } });
      this.warningNotification(respondeError, true);
    });
    this.setState({ dataToDisable: { show: false, data: {} } });
  }

  createdOrEditAgent = (action) => {
    if (action === 'created') {
      this.successNotifiaction('Agente agregado', true);
    } else {
      this.successNotifiaction('Agente actualizado', true);
    }
  }

  successNotifiaction(msg, status) {
    this.setState({ ...this.state, successRequest: { successMessage: msg, successStatus: status } });
    setTimeout(() => {
      this.setState({ ...this.state, successRequest: { successMessage: '', successStatus: false } });
    }, 2500);
  }

  warningNotification(msg, status) {
    this.setState({ ...this.state, warningRequest: { successMessage: msg, successStatus: status } });
    setTimeout(() => {
      this.setState({ ...this.state, warningRequest: { successMessage: '', successStatus: false } });
    }, 2500);
  }

  handleSearch(value) {
    this.setState({ searchParam: value });
  }

  actionChange(action) {
    if (action === 'add') this.setState({ agentSelected: {} });
    this.setState({ render: action });
  }

  searchAgent() {
    this.getAllAgent();
  }

  async handleOpenModalAgent(agent) {

    let { modalAgent } = this.state;

    await getAll(`/agent/${agent.id_agent}`).then(request => {
      let { statusResponse, data } = request;
      if (statusResponse && request.data.payload) {
        this.setState({ modalAgent: { ...modalAgent, show: true, data: data.payload } });
      }
    }).catch(error => {
      this.setState({ errorsRequest: { errorMessage: 'Agentes', errorStatus: true } });
    });
  }

  handleCloseModalAgent() {
    let { modalAgent } = this.state;
    this.setState({ modalAgent: { ...modalAgent, show: false, data: {} } });
  }

  materialDataTable() {

    let columns = [
      {
        ...customColumn('Identificación', row => row.id_client),
        cell: row => <p className="capitalize-text">{row.id_client}</p>,
      },
      {
        ...customColumn('Cliente', row => row.client_name),
        cell: row => <p className="capitalize-text">{row.client_name}</p>,
      },
      {
        ...customColumn('Nombre de Usuario', row => row.username),
        cell: row => <p className="capitalize-text">{row.username}</p>,
      },
      {
        ...customColumn('Tipo', row => row.installed),
        cell: (row) =>
          <div>
            {row.installed && (
              <span id="ena-status" className={row.installed && `att-nft-status`}>
                {row.installed && 'Colector'}
              </span>
            )}
            {!row.installed && (
              <span id="dis-status" className={!row.installed && `att-nft-status`}>
                {!row.installed && 'Web Service'}
              </span>
            )}
          </div>
      },
      {
        ...customColumn('Estado', row => row.enable),
        cell: (row) =>
          <div>
            {row.enable === 'ENA' && (
              <span id="ena-status" onClick={() => { row.enable === 'ENA' && this.handleEnable(row) }} className={row.enable === 'ENA' && ` active-status`}>
                {row.enable === 'ENA' && 'Activo'}
              </span>
            )}

            {row.enable === 'DIS' && (
              <span id="dis-status" className={row.enable === 'DIS' && `inactive-status`}>
                {row.enable === 'DIS' && 'Inactivo'}
              </span>
            )}

            {row.enable === 'ATT' && (
              <span id="att-status" className={row.enable === 'ATT' && `att-nft-status`}>
                {row.enable === 'ATT' && 'Atención'}
              </span>
            )}

            {row.enable === 'NTF' && (
              <span className={row.enable === 'NTF' && `att-nft-status`}>
                {row.enable === 'NTF' && 'Notificado'}
              </span>
            )}

          </div>

        ,
      },
      {
        name: "Acciones",
        cell: (row) => (
          <div className="actions-buttons">
            <button className="botton-delete">
              <svg onClick={() => { this.handleDelete(row) }} className="svg-icon" viewBox="0 0 20 20">
                <path d="M17.114,3.923h-4.589V2.427c0-0.252-0.207-0.459-0.46-0.459H7.935c-0.252,0-0.459,0.207-0.459,0.459v1.496h-4.59c-0.252,0-0.459,0.205-0.459,0.459c0,0.252,0.207,0.459,0.459,0.459h1.51v12.732c0,0.252,0.207,0.459,0.459,0.459h10.29c0.254,0,0.459-0.207,0.459-0.459V4.841h1.511c0.252,0,0.459-0.207,0.459-0.459C17.573,4.127,17.366,3.923,17.114,3.923M8.394,2.886h3.214v0.918H8.394V2.886z M14.686,17.114H5.314V4.841h9.372V17.114z M12.525,7.306v7.344c0,0.252-0.207,0.459-0.46,0.459s-0.458-0.207-0.458-0.459V7.306c0-0.254,0.205-0.459,0.458-0.459S12.525,7.051,12.525,7.306M8.394,7.306v7.344c0,0.252-0.207,0.459-0.459,0.459s-0.459-0.207-0.459-0.459V7.306c0-0.254,0.207-0.459,0.459-0.459S8.394,7.051,8.394,7.306"></path>
              </svg>
            </button>
            <button className="botton-edit">
              <svg onClick={() => { this.actionChange('edit'); this.setState({ agentSelected: row }); }} className="svg-icon" viewBox="0 0 20 20">
                <path d="M19.404,6.65l-5.998-5.996c-0.292-0.292-0.765-0.292-1.056,0l-2.22,2.22l-8.311,8.313l-0.003,0.001v0.003l-0.161,0.161c-0.114,0.112-0.187,0.258-0.21,0.417l-1.059,7.051c-0.035,0.233,0.044,0.47,0.21,0.639c0.143,0.14,0.333,0.219,0.528,0.219c0.038,0,0.073-0.003,0.111-0.009l7.054-1.055c0.158-0.025,0.306-0.098,0.417-0.211l8.478-8.476l2.22-2.22C19.695,7.414,19.695,6.941,19.404,6.65z M8.341,16.656l-0.989-0.99l7.258-7.258l0.989,0.99L8.341,16.656z M2.332,15.919l0.411-2.748l4.143,4.143l-2.748,0.41L2.332,15.919z M13.554,7.351L6.296,14.61l-0.849-0.848l7.259-7.258l0.423,0.424L13.554,7.351zM10.658,4.457l0.992,0.99l-7.259,7.258L3.4,11.715L10.658,4.457z M16.656,8.342l-1.517-1.517V6.823h-0.003l-0.951-0.951l-2.471-2.471l1.164-1.164l4.942,4.94L16.656,8.342z"></path>
              </svg>
            </button>
            <button style={{ marginLeft: '-30px' }} className=" botton-find">
              <svg onClick={() => { this.handleOpenModalAgent(row) }} className="svg-icon" viewBox="0 0 20 20">
                <path d="M10,6.978c-1.666,0-3.022,1.356-3.022,3.022S8.334,13.022,10,13.022s3.022-1.356,3.022-3.022S11.666,6.978,10,6.978M10,12.267c-1.25,0-2.267-1.017-2.267-2.267c0-1.25,1.016-2.267,2.267-2.267c1.251,0,2.267,1.016,2.267,2.267C12.267,11.25,11.251,12.267,10,12.267 M18.391,9.733l-1.624-1.639C14.966,6.279,12.563,5.278,10,5.278S5.034,6.279,3.234,8.094L1.609,9.733c-0.146,0.147-0.146,0.386,0,0.533l1.625,1.639c1.8,1.815,4.203,2.816,6.766,2.816s4.966-1.001,6.767-2.816l1.624-1.639C18.536,10.119,18.536,9.881,18.391,9.733 M16.229,11.373c-1.656,1.672-3.868,2.594-6.229,2.594s-4.573-0.922-6.23-2.594L2.41,10l1.36-1.374C5.427,6.955,7.639,6.033,10,6.033s4.573,0.922,6.229,2.593L17.59,10L16.229,11.373z"></path>
              </svg>
            </button>
          </div>

        ),
      },
    ];
    let { dataTable, loading, errorsRequest } = this.state;
    return <DataTableMaterial
      dataTableData={dataTable}
      loading={loading}
      errors={errorsRequest}
      columns={columns}
      getRowsPerPage={this.getRowsPerPage}
      getCurrentPage={this.getCurrentPage}
    />;
  }

  render() {
    let { render, agentSelected, dataToDisable, dataToDelete, successRequest, warningRequest, errorsRequest, defaultSearchParam, modalAgent } = this.state;

    return (
      <div className="col-lg-12 mt-5">
        {
          render === 'add' ?
            <Add actionChange={this.actionChange} createdOrEditAgent={this.createdOrEditAgent} /> :

            render === 'edit' ?
              <Edit agent={agentSelected} actionChange={this.actionChange} createdOrEditAgent={this.createdOrEditAgent} /> :

              <div className="card-table">
                <HeaderTable title={'Agentes'} />
                <ModalAgents closeModal={this.handleCloseModalAgent} dataAgent={modalAgent} />
                <ModalToEnable record={dataToDisable} disableRecord={this.disableAgent} />
                <ModalToDelete record={dataToDelete} deleteRecord={this.deleteAgent} />
                {warningRequest.successStatus && <Warning text={warningRequest.successMessage} />}
                {successRequest.successStatus && <Success text={successRequest.successMessage} />}
                {!errorsRequest.errorStatus &&
                  <div className="flex">
                    <div className="row mt-3">
                      <div className="col-md-8">
                        <div className="row">
                          <div className="col-md-6" id="container-input-search" >
                            <Search defaultValue={defaultSearchParam} handleSearch={this.handleSearch} />
                          </div>

                          <div className="col-md-6 ">
                            <div className=" container-bottons">
                              <button onClick={this.searchAgent} className="btn-coriport btn-add">Buscar</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4" >
                        <div className="float-right">
                          <ButtonAdd actionChange={this.actionChange} />
                        </div>
                      </div>
                    </div>
                  </div>
                }
                <div className="mb-5 mt-4">
                  {this.materialDataTable()}
                </div>
              </div>

        }
      </div>
    )

  }

}