import React, { useEffect, useState } from 'react';
import { createRecord, editRecord } from '../../../request/Request';
import { selectEnable, typeFilterSelect } from '../../../service/defaultData';
import validate from '../../../validate-forms/Revenues/AddFormValidationRules';
import useForm from '../../../validate-forms/useForm';
import { ComboBox } from '../../ComboBox/ComboBox';
import { ErrorPage } from '../../ErrorPage/ErrorPage';
import { Loading } from '../../Loading/Loading';
import moment from 'moment';
import { CustomDatePicker } from '../../CustomDatePicker/CustomDatePicker';
import Warning from '../../Notification/Warning';
import { revenuesErrors } from '../../../service/ErrorNotification/revenuesErrors';
import { filterSelectData, getDefaultOptionSelect } from '../../../service/comboBox';

export const AddOrEdit = ({ revenue, actionChange, createdOrEditRevenues, render, shops }) => {

  const [errorMessage, setErrorMessage] = useState(false);
  const [loading, setLoading] = useState(true);

  const [allShops, setAllShops] = useState([]);

  const [defaultEnable, setDefaultEnable] = useState(null);
  const [defaultShop, setDefaultShop] = useState(null);

  const [statusButton, setStatusButton] = useState(false);

  const [defaultDateFrom, setDefaultDateFrom] = useState(null);
  const [defaultDateTo, setDefaultDateTo] = useState(null);

  const [warningMessage, setWarningMessage] = useState('');

  const { values, errors, handleChange, handleSubmit } = useForm(sendForm, validate);
  
  useEffect(() => {
    if (render === 'add') {
      let enableAndDisabledShops = filterSelectData(typeFilterSelect.add, shops);
      setAllShops(enableAndDisabledShops);
      setLoading(false);
    } else { 

      let defaultShopTemp = getDefaultOptionSelect(shops, revenue.id_shop);
      setDefaultShop(defaultShopTemp[0]);
      let disableAndDisabledShops = filterSelectData(typeFilterSelect.edit, shops, defaultShopTemp)
      setAllShops(disableAndDisabledShops);

      let defaultEnableTemp = getDefaultOptionSelect(selectEnable, revenue.enable);
      setDefaultEnable(defaultEnableTemp);

      values.enable = revenue.enable;
      values.fixed_revenues_gross = revenue.fixed_revenues_gross;
      values.fixed_revenues_net = revenue.fixed_revenues_net;
      values.id_shop = revenue.id_shop;
      values.mag = revenue.mag;
      values.minimun_revenues_gross = revenue.minimun_revenues_gross;
      values.minimun_revenues_net = revenue.minimun_revenues_net;
      values.sales = revenue.sales;
      values.total_revenues_gross = revenue.total_revenues_gross;
      values.total_revenues_net = revenue.total_revenues_net;
      values.variable_revenues_gross = revenue.variable_revenues_gross;
      values.variable_revenues_net = revenue.variable_revenues_net;

      if (revenue?.date_from !== undefined) {
        let starDateTemp = moment(revenue.date_from).add(6, 'h');
        setDefaultDateFrom(new Date(starDateTemp));
        values.date_from = moment(revenue.date_from).add(6, 'h').format('YYYY-MM-DDTHH:mm:ss');
      } else {
        values.date_from = null;
      }

      if (revenue?.date_to !== undefined) {
        let stoppedDateTemp = moment(revenue.date_to).add(6, 'h');
        setDefaultDateTo(new Date(stoppedDateTemp));
        values.date_to = moment(revenue.date_to).add(6, 'h').format('YYYY-MM-DDTHH:mm:ss')
      } else {
        values.date_to = null;
      }

    }
  }, []);

  useEffect(() => {
    if (defaultShop && defaultEnable ) {
        setLoading(false);
    }
}, [defaultShop, defaultEnable]);
 
  async function sendForm() {
    setWarningMessage('');
    setStatusButton(true);
  
    values?.date_from && (values.date_from = moment(values.date_from).format('YYYY-MM-DDTHH:mm:ss[Z]'));
    values?.date_to && (values.date_to = moment(values.date_to).format('YYYY-MM-DDTHH:mm:ss[Z]'));
   
    if (render !== 'add') {
      await editRecord(`/revenues/${revenue.id_revenues}`, values).then(request => {
        setStatusButton(false);
        if (request.statusResponse) {
          redirect('edit');
        }
      }).catch(error => getErrors(error));
    } else {
      await createRecord(`/revenues`, values).then(request => {
        setStatusButton(false);
        if (request.statusResponse) {
          redirect('created');
        }
      }).catch(error => getErrors(error));
    }
  }

  const getErrors = (error) => {
 
    setWarningMessage('');
    setErrorMessage(false);
    
    let respondeError = revenuesErrors(error);

    if (respondeError === 'error') return setErrorMessage(true);

    setWarningMessage(respondeError);
    setStatusButton(false);

}

  function comboBoxChange(e) {
    handleChange(e);
  }

  const redirect = (action) => {
    createdOrEditRevenues(action);
    actionChange('table');
  }

  const datePickerChange = (e) => handleChange(e);
  const handleDateFrom = (date) => values.date_from = date;
  const handleDateTo = (date) => values.date_to = date;
  
  return loading ? <Loading /> : errorMessage ? <ErrorPage redirect={true} actionChange={actionChange} name={'Ingresos'} /> :
    <section className="section form-color">
      <form onSubmit={handleSubmit} noValidate>
        <div className="acction-card"><span aria-hidden="true"> {render == 'add' ? 'Agregar' : 'Actualizar'} Ingresos</span></div>

        {warningMessage !== '' && <Warning text={warningMessage} />}

        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <ComboBox name={"id_shop"} text={"Tienda"} options={allShops} onChange={comboBoxChange} defaultValueSelect={defaultShop} />
              {errors.id_shop && (
                <p className="help is-danger">{errors.id_shop}</p>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group coriport-input">
              <CustomDatePicker defaultValue={defaultDateFrom} type={'date'} onChange={datePickerChange} name='date_from' placeHolder={'mm/dd/aaaa'} getDate={handleDateFrom} title={'Fecha de Inicio'} />
              {errors.date_from && (
                <p className="help is-danger">{errors.date_from}</p>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="form-group coriport-input">
              <CustomDatePicker defaultValue={defaultDateTo} type={'date'} onChange={datePickerChange} name='date_to' placeHolder={'dd/mm/aaaa'} getDate={handleDateTo} title={'Fecha de fin'} />
              {errors.date_to && (
                <p className="help is-danger">{errors.date_to}</p>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group coriport-input">
              <label htmlFor="mag" className="form-label">Mag</label>
              <input type="text" className={`input-form form-control `} placeholder="Mag" name="mag" onChange={handleChange} value={values.mag || ''} required />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="form-group coriport-input">
              <label htmlFor="sales" className="form-label">Ventas</label>
              <input type="number" className={`input-form form-control`} name="sales" onChange={handleChange} value={values.sales || ''} required />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group coriport-input">
              <label htmlFor="minimun_revenues_gross" className="form-label">Ingresos Mínimos Brutos</label>
              <input type="number" className={`input-form form-control`} name="minimun_revenues_gross" onChange={handleChange} value={values.minimun_revenues_gross || ''} required />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="form-group coriport-input">
              <label htmlFor="variable_revenues_gross" className="form-label">Ingresos Variables Brutos</label>
              <input type="number" className={`input-form form-control`} name="variable_revenues_gross" onChange={handleChange} value={values.variable_revenues_gross || ''} required />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group coriport-input">
              <label htmlFor="fixed_revenues_gross" className="form-label">Ingresos Fijos Brutos</label>
              <input type="number" className={`input-form form-control`} name="fixed_revenues_gross" onChange={handleChange} value={values.fixed_revenues_gross || ''} required />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="form-group coriport-input">
              <label htmlFor="total_revenues_gross" className="form-label">Ingresos Totales Brutos</label>
              <input type="number" className={`input-form form-control`} name="total_revenues_gross" onChange={handleChange} value={values.total_revenues_gross || ''} required />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group coriport-input">
              <label htmlFor="minimun_revenues_net" className="form-label">Ingresos Mínimos Netos</label>
              <input type="number" className={`input-form form-control ${errors.minimun_revenues_net && 'is-danger'}`} name="minimun_revenues_net" onChange={handleChange} value={values.minimun_revenues_net || ''} required />
              {errors.minimun_revenues_net && (
                <p className="help is-danger">{errors.minimun_revenues_net}</p>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="form-group coriport-input">
              <label htmlFor="variable_revenues_net" className="form-label">Ingresos Variables Netos </label>
              <input type="number" className={`input-form form-control ${errors.variable_revenues_net && 'is-danger'}`} name="variable_revenues_net" onChange={handleChange} value={values.variable_revenues_net || ''} required />
              {errors.variable_revenues_net && (
                <p className="help is-danger">{errors.variable_revenues_net}</p>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group coriport-input">
              <label htmlFor="fixed_revenues_net" className="form-label">Ingresos Fijos Netos</label>
              <input type="number" className={`input-form form-control ${errors.fixed_revenues_net && 'is-danger'}`} name="fixed_revenues_net" onChange={handleChange} value={values.fixed_revenues_net || ''} required />
              {errors.fixed_revenues_net && (
                <p className="help is-danger">{errors.fixed_revenues_net}</p>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="form-group coriport-input">
              <label htmlFor="total_revenues_net" className="form-label">Ingresos Totales Netos</label>
              <input type="number" className={`input-form form-control ${errors.total_revenues_net && 'is-danger'}`} name="total_revenues_net" onChange={handleChange} value={values.total_revenues_net || ''} required />
              {errors.total_revenues_net && (
                <p className="help is-danger">{errors.total_revenues_net}</p>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <ComboBox name={"enable"} text={"Estado"} options={selectEnable} onChange={comboBoxChange} defaultValueSelect={defaultEnable} />
            {errors.enable && (
              <p className="help is-danger">{errors.enable}</p>
            )}
          </div>
        </div>

        <div className="container-bottons">
          <button disabled={statusButton} id='custom-btn-coriport' className=" btn  btn-coriport btn-add text-white " type="submit">
            {
              statusButton ? <div className="container-spinner-loading pt-2">
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              </div> : <p className="label-button-add"> {render == 'add' ? 'Agregar' : 'Actualizar'}</p>
            }
          </button>
          <button className="btn-coriport btn-cancel" onClick={() => actionChange('table')}>
            Cancelar
          </button>
        </div>

      </form>
    </section>

}