export default function validate(values){
    
    let errors = {};
 
    if(!values.id_agent){
        errors.id_agent = '*El agente es requerido';
    }
    if(!values.category_name){
        errors.category_name = '*El nombre de la categoría es requerido';
    } 
    if(values.enable === undefined){
        errors.enable = '*El estado es requerido';
    }
    if(!values.id_vinci_product_category){
        errors.id_vinci_product_category = '*La categoría de vinci es requerida';
    } 
    if(!values.category_code){
        errors.category_code = '*El código es requerido';
    } 

    return errors;
}