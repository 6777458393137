 

export default function validate(values){
    
    let errors = {};
  /* eslint-disable no-useless-escape */
 const regexEmail = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

    if (values.sender_email) {
      if (regexEmail.test(values.sender_email) === false) {
        errors.sender_email = "*Debe ingresar un correo valido";
      }
    }
    
    if(!values.id_sender){
        errors.id_sender = '*El ID es requerida';
    }
    if(!values.sender_email){
        errors.sender_email = '*El email es requerido';
    }
    if(!values.sender_name){
        errors.sender_name = '*El nombre es requerido'
    }
    if(!values.id_identification_type){
        errors.id_identification_type = '*La identificación es requerida'
    }
    if(values.enable === undefined){
        errors.enable = '*El estado es requerido'
    }


    return errors;
}