import React from "react";
import "./modal.css";

export default class ModalToDelete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      data: "",
      statusButton: false,
    };
  }

  componentDidMount(){

  }

  componentDidUpdate(prevProps, prevState) {
    let { record } = this.props;
    if (prevProps.record !== record) {
      this.setState({ show: record.show, data: record.data });
      this.setState({ statusButton: false });
    }
  }

  deleteAction(data) {
    let { deleteRecord } = this.props;
    this.setState({ statusButton: true });
    deleteRecord(data);
  
  }

  render() {
    let { data, show, statusButton } = this.state;
    let { record } = this.props;

    return (
      <>
        {show && (
          <div
            className="Modal"
            style={{ display: "block" }}
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <span className="custom-title-modal">
                    Registro a eliminar:{" "}
                    <span className="text-capitalize">{data.name}</span>
                  </span>
                </div>
                <div className="modal-body">
                  <button
                    onClick={() =>
                      this.setState({ ...record, show: false })
                    }
                    type="button"
                    className="btn"
                    id="btn-cancel"
                    // style={{}}
                  >
                    Cancelar
                  </button>
                  <button
                    disabled={statusButton}
                    onClick={() => this.deleteAction(data)}
                    type="button"
                    className="btn"
                    id="btn-success"
                  >
                    {statusButton ? (
                      <div className="container-spinner-loading pt-2">
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </div>
                    ) : (
                      <p>Eliminar</p>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
