import React, { useState, useRef } from 'react';
import Success from '../Notification/Success';
import Error from '../Notification/Error';
import Warning from '../Notification/Warning';
import { uploadFile, uploadFileName, uploadFileURL } from "../../request/Request";

export const MassiveUpload = () => {
  const ref = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorRequest, setErrorRequest] = useState({ errorMessage: '', errorStatus: false });
  const [successRequest, setSuccessRequest] = useState({ successMessage: '', successStatus: false });
  const [warningsRequest, setWarningRequest] = useState({ warningMessage: '', warningStatus: false });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const allowedTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

    if (file && allowedTypes.includes(file.type)) {
      const formData = file.name.replaceAll(" ", '_');
      const cleanedFile = new File([file], formData, { type: file.type });
      setSelectedFile(cleanedFile);
    } else {
      setSelectedFile(null);
    }
  };

  async function uploadXLSXFile() {
    let uploadName = false;
    var uploadURL = null;

    const body = {
      "fileName": selectedFile.name
    }
    setSuccessRequest({ successMessage: '', successStatus: false });
    setErrorRequest({ errorMessage: '', errorStatus: false });
    setWarningRequest({ warningMessage: '', warningStatus: false });
    setLoading(true);

    await uploadFileURL(`/presignedUrl/${selectedFile.name}`)
        .then(response => {
          if (response.statusResponse) {
            uploadURL = response.data.url;
          }
          else {
            uploadURL = null;
          }
        })
        .catch(error => {
          console.log(error);
          setErrorRequest({ errorMessage: 'Error al obtener el URL del archivo', errorStatus: true });
          ref.current.value = "";
          setSelectedFile(null);
          setLoading(false);
        });

    if (uploadURL) {
      await uploadFileName(uploadURL, selectedFile)
        .then(response => {
          if (response.statusResponse) {
            uploadName = true;
          }
          else {
            uploadName = false;
          }
        })
        .catch(error => {
          console.log(error);
          setErrorRequest({ errorMessage: 'Error al enviar el nombre del archivo', errorStatus: true });
          ref.current.value = "";
          setSelectedFile(null);
          setLoading(false);
        });

      if (uploadName) {
        await uploadFile('/uploadFile', body)
          .then(response => {
            console.log(response);
            if (response.statusResponse) {
              if (response.data.headerError) {
                setErrorRequest({ errorMessage: 'El encabezado tiene que contar con una sola aparición de las siguientes columnas (id_product, product_code, id_vinci_product_category, id_vinci_product_group, id_vinci_product_subgroup)', errorStatus: true });
              }
              else {
                if (response.data.errorList && response.data.errorList.length) {
                  setSuccessRequest({ successMessage: 'Se actualizaron correctamente ' + response.data.updatedRecords + ' productos', successStatus: true });
                  setWarningRequest({ warningMessage: 'Errores en las siguientes líneas: ' + response.data.errorList, warningStatus: true });
                }
                else {
                  setSuccessRequest({ successMessage: 'Todos los productos se actualizaron correctamente', successStatus: true });
                }
              }
              ref.current.value = "";
              setSelectedFile(null);
              setLoading(false);
            } else {
              setErrorRequest({ errorMessage: 'Error al enviar el archivo', errorStatus: true });
              ref.current.value = "";
              setSelectedFile(null);
              setLoading(false);
            }
          })
          .catch(error => {
            setErrorRequest({ errorMessage: 'Error al enviar el archivo', errorStatus: true });
            ref.current.value = "";
            setSelectedFile(null);
            setLoading(false);
          });
      }
    }
  }

  const handleUpload = (event) => {
    event.preventDefault();
    if (!selectedFile) {
      return;
    }

    uploadXLSXFile();

  };

  return (
    <div className="container-dates-report">
      <form>
        <div className="period-name-report"><h5>Clasificación de productos </h5></div>
        {successRequest.successStatus && <Success text={successRequest.successMessage} className="MassiveUpload" />}
        {errorRequest.errorStatus && <Error text={errorRequest.errorMessage} className="MassiveUpload" />}
        {warningsRequest.warningStatus && <Warning text={warningsRequest.warningMessage} className="MassiveUpload" />}
        <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} ref={ref} />
        {selectedFile && (
          <div class="container-bottons-report">
            <button disabled={loading} className="btn  btn-coriport btn-add text-white" onClick={handleUpload}>
              {
                loading ? <div className="container-spinner-loading pt-2">
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </div> : 'Subir'
              }
            </button>
          </div>
        )}
      </form>
    </div>
  );
}