
import React, { useEffect, useState } from "react";
import "./Add.css";
import useForm from "../../../validate-forms/useForm";
import validate from "../../../validate-forms/Agent/AddFormValidationRules";
import { ComboBox } from "../../ComboBox/ComboBox";
import { ErrorPage } from "../../ErrorPage/ErrorPage";
import { selectInstalled, statusAgent, svgToEmails } from "../../../service/defaultData";
import { createRecord } from "../../../request/Request";
import Warning from "../../Notification/Warning"; 
import { agentErrors } from "../../../service/ErrorNotification/agentErrors";

const Add = ({ createdOrEditAgent, actionChange }) => {

    const [errorMessage, setErrorMessage] = useState(false);
    const [optionStatusShop, setOptionStatusShop] = useState([]);
    const [statusButton, setStatusButton] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');

    const { values, errors, handleChange, handleSubmit } = useForm(sendForm, validate);
  
    useEffect(() => {
        let newStatus = statusAgent.filter(s => s.value === 'ENA')
        setOptionStatusShop(newStatus);
    }, []);


    async function sendForm() {
        setWarningMessage('');
        setStatusButton(true);
        delete values.passwordConfirmation;
        values.status = 1;

        await createRecord(`/agent`, values).then(request => {
            setStatusButton(false);
            if (request.statusResponse) {
                redirect('created');
            }
        }).catch(error => {
            let respondeError = agentErrors(error);
            
            if (respondeError === 'error') return  setErrorMessage(true);
            
            setWarningMessage(respondeError);
            setStatusButton(false);

        });

    }

    function comboBoxChange(e) {
        handleChange(e);
    }

    const redirect = (action) => {
        createdOrEditAgent(action);
        actionChange('table');
    }

    return errorMessage ? <ErrorPage redirect={true} actionChange={actionChange}name={'Agentes'} /> :
        <section className="section form-color">
            <form onSubmit={handleSubmit} noValidate>
                <div className="acction-card"><span aria-hidden="true">Agregar Agente</span></div>

                {warningMessage !== '' && <Warning text={warningMessage} />}

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group coriport-input">
                            <label htmlFor="client_name" className="form-label">Nombre</label>
                            <input type="text" className={`input-form form-control ${errors.name && 'is-danger'}`} placeholder="Nombre" name="client_name" onChange={handleChange} value={values.client_name || ''} required />
                            {errors.client_name && (
                                <p className="help is-danger">{errors.client_name}</p>
                            )}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group coriport-input">
                            <label htmlFor="username" className="form-label">Nombre de Usuario</label>
                            <input type="text" className={`input-form form-control ${errors.username && 'is-danger'}`} placeholder="Nombre de Usuario" name="username" onChange={handleChange} value={values.username || ''} required />
                            {errors.username && (
                                <p className="help is-danger">{errors.username}</p>
                            )}
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group coriport-input">
                            <label htmlFor="max_ping_time" className="form-label">Tiempo Máximo de Conexión (Minutos)</label>
                            <input type="number" className={`input-form form-control ${errors.max_ping_time && 'is-danger'}`} name="max_ping_time" onChange={handleChange} value={values.max_ping_time || ''} required />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group coriport-input">
                            <label htmlFor="id_client" className="form-label">Identificación</label>
                            <input type="number" className={`input-form form-control ${errors.id_client && 'is-danger'}`} placeholder="ID" name="id_client" onChange={handleChange} value={values.id_client || ''} required />
                            {errors.id_client && (
                                <p className="help is-danger">{errors.id_client}</p>
                            )}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group coriport-input">
                            <div className="d-flex align-items-baseline">
                                <label htmlFor="email" className="form-label">Correos</label>
                                <div data-toggle="tooltip" data-placement="top" title={`Formato: correos separado por ,`} className="ml-2">
                                    <svg style={{ width: '20px', height: '20px' }} viewBox="0 0 20 20">
                                        <path d={svgToEmails}></path>
                                    </svg>
                                </div>
                            </div>
                            <div style={{ marginTop: '-17.5px' }} className="d-flex align-items-baseline">
                                <input autoComplete="off" type="email" className={`input-form form-control ${errors.email && 'is-danger'}`} placeholder="email@email.com,email@email.com,.." name="email" onChange={handleChange} value={values.email || ''} required />
                            </div>
                            {errors.email && (
                                <p className="help is-danger">{errors.email}</p>
                            )}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group coriport-input">
                            <label htmlFor="agent_password" className="form-label">Contraseña</label>
                            <input type="password" className={`input-form form-control ${errors.agent_password && 'is-danger'}`} placeholder="Contraseña" name="agent_password" onChange={handleChange} value={values.agent_password || ''} required />
                            {errors.agent_password && (
                                <p className="help is-danger">{errors.agent_password}</p>
                            )}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group coriport-input">
                            <label htmlFor="passwordConfirmation" className="form-label">Repita Contraseña</label>
                            <input type="password" className={`input-form form-control ${errors.passwordConfirmation && 'is-danger'}`} placeholder="Repita Contraseña" name="passwordConfirmation" onChange={handleChange} value={values.passwordConfirmation || ''} required />
                            {errors.passwordConfirmation && (
                                <p className="help is-danger">{errors.passwordConfirmation}</p>
                            )}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group coriport-input">
                            <ComboBox name={"installed"} text={"Instalado"} options={selectInstalled} onChange={comboBoxChange} />
                            {errors.installed && (
                                <p className="help is-danger">{errors.installed}</p>
                            )}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group coriport-input">
                            <ComboBox name={"enable"} text={"Estado"} options={optionStatusShop} onChange={comboBoxChange} />
                            {errors.enable && (
                                <p className="help is-danger">{errors.enable}</p>
                            )}
                        </div>
                    </div>
                </div>

                <div className="container-bottons" >
                    <button disabled={statusButton} id='custom-btn-coriport' className=" btn  btn-coriport btn-add text-white " type="submit">
                        {
                            statusButton ? <div className="container-spinner-loading pt-2">
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </div> : <p className="label-button-add">Agregar</p>
                        }
                    </button>
                    <button className="btn-coriport btn-cancel" onClick={() => actionChange('table')}>
                        Cancelar
                    </button>
                </div>

            </form>
        </section>;

};
export default Add;