export default function validate(values){
    
    let errors = {};

    if(!values.id_shop){
        errors.id_shop = '*La tienda es requerida';
    }
    if(!values.date_from){
        errors.date_from = '*La fecha inicial es requerida';
    }
    if(!values.date_to){
        errors.date_to = '*La fecha final es requerida';
    }
    if(values.date_from > values.date_to){
        errors.date_to = '*Verificar fecha final';
    }
    if(!values.minimun_revenues_net){
        errors.minimun_revenues_net = '*Ingresos Mínimos Netos es requerido';
    }
    if(!values.variable_revenues_net){
        errors.variable_revenues_net = '*Ingresos Variables Netos es requerido';
    }
    if(!values.fixed_revenues_net){
        errors.fixed_revenues_net = '*Ingresos Fijos Netos es requerido';
    }
    if(!values.total_revenues_net){
        errors.total_revenues_net = '*Ingresos Totales Netos es requerido';
    }
    if(values.enable === undefined){
        errors.enable = '*El estado es requerido'
    }
    if(values.sales < 0){
        errors.sales = '*Ingrese un número valido';
    }
    if(values.minimun_revenues_gross < 0){
        errors.minimun_revenues_gross = '*Ingrese un número valido';
    }
    if(values.variable_revenues_gross < 0){
        errors.variable_revenues_gross = '*Ingrese un número valido';
    }
    if(values.fixed_revenues_gross < 0){
        errors.fixed_revenues_gross = '*Ingrese un número valido';
    }
    if(values.total_revenues_gross < 0){
        errors.total_revenues_gross = '*Ingrese un número valido';
    }
    if(values.minimun_revenues_net < 0){
        errors.minimun_revenues_net = '*Ingrese un número valido';
    }
    if(values.variable_revenues_net < 0){
        errors.variable_revenues_net = '*Ingrese un número valido';
    }
    if(values.fixed_revenues_net < 0){
        errors.fixed_revenues_net = '*Ingrese un número valido';
    }
    if(values.total_revenues_net < 0){
        errors.total_revenues_net = '*Ingrese un número valido';
    }

    return errors;
}