export const productSubGroupErrors = ({ error, data }) => {
    let message = "";

    if (error === 500) return message = "error";

    if (error === 404 && data.message === 'The group does not exist') return  message = 'El grupo enviado no existe';

    if (error === 404 && data.message === 'The category does not exist') return  message = 'La categoría enviada no existe';

    if (error === 404 && data.message === 'The product subgroup with the sent id is not found') return  message = 'El sub-grupo enviado no existe';

    if (error === 404 && data.message === 'The sent vinciProductSubgroup does not exist') return  message = 'El sub-grupo de vinci enviado no existe';
    
    if (error === 404 && data.message === 'The sent agent does not exist') return  message = 'El agente enviado no existe';
    
    if (error === 400 && data.message === 'The sent agent is not enabled') return  message = 'El agente ingresado está desactivado';

    if (error === 400 && data.message === 'There is already a record with the selected name and with the null code, please go to the corresponding screen to modify the record') return message = 'Ya existe el nombre y código en nulo';
    
    if (error === 400 && data.message === 'There is already a record with the selected code and name') return  message = 'El nombre y código enviado ya existen';

    if (error === 400 && data.message === 'The category sent is not related to the agent') return  message = 'La categoría enviada no está relacionada con el agente';
  
    if (error === 400 && data.message === 'There is already a record with the selected code and name but it is disabled, go to the corresponding screen to enable it') return  message = 'Ya existe un registro con el código y nombre seleccionado pero está deshabilitado, acceda a la pantalla correspondiente para habilitarlo';
  
    if (error === 400 && data.message === 'Please contact the site administrator since the category you want to insert is disabled') return  message = 'La categoría enviada está desactivada';
  
    if (error === 400 && data.message === 'The group sent is not related to the agent') return  message = 'El grupo enviado no tiene relación con el agente';

    if (error === 400 && data.message === 'Please contact the site administrator since the group you want to insert is disabled') return  message = 'El grupo enviado está desactivado';

    if (error === 400 && data.message === 'The selected group does not belong to the previously selected category') return  message = 'El grupo seleccionado no pertenece a la categoría seleccionada anteriormente';

    if (error === 400 && data.message === 'Please contact the site administrator since the vinci product subgroup you want to insert is disabled') return  message = 'El sub-grupo de vinci enviado está desactivado';

    if (error === 400 && data.message === 'The selected category has another related vinci subgroup') return  message = 'La categoría enviada no tiene relación con el sub-grupo de vinci';

    if (error === 400 && data.message === 'The selected group has another related vinci subgroup') return  message = 'El grupo enviado no tiene relación con el sub-grupo de vinci';

    if (error === 400 && data.message === 'The product subgroup you want to disable was already disabled') return  message = 'El sub-grupo enviado ya está desactivado';
    
    if (error === 400 && data.message.includes('record(s) of the table invoice product')) return message = 'El sub-grupo a eliminar posee productos asociados';

    if (error === 400 && data.message.includes('record(s) of the table product')) return message = 'El sub-grupo a eliminar posee productos asociados';

    return message;

};
