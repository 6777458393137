import React, { useEffect, useState } from "react";
import validate from "../../../validate-forms/Parameter/AddFormValidationRules";
import { createRecord, editRecord } from "../../../request/Request";
import { selectEnable, svgToEmails } from "../../../service/defaultData";
import useForm from "../../../validate-forms/useForm";
import { ErrorPage } from "../../ErrorPage/ErrorPage";
import { ComboBox } from "../../ComboBox/ComboBox";
import { Loading } from "../../Loading/Loading";
import './AddOrEdit.css';
import Warning from "../../Notification/Warning";
import { parameterErrors } from "../../../service/ErrorNotification/parameterErrors";
import { getDefaultOptionSelect } from "../../../service/comboBox";

export const AddOrEdit = ({ actionChange, createdOrEditParameter, parameter, render }) => {

    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(false);
    const { values, errors, handleChange, handleSubmit } = useForm(sendForm, validate);
    const [defaultEnable, setDefaultEnable] = useState(null);
    const [statusButton, setStatusButton] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');

    useEffect(() => {
        if (render === 'add') {
            setLoading(false);
        } else {

            let defaultEnableTemp = getDefaultOptionSelect(selectEnable, parameter.enable);
            setDefaultEnable(defaultEnableTemp);

            values.id_shop = parameter.id_shop;
            values.parameter_type = parameter.parameter_type;
            values.parameter_value = parameter.parameter_value;
            values.description = parameter.description;
            values.enable = parameter.enable;
            setLoading(false);
        }
    }, []);

    async function sendForm() {
        setWarningMessage('');
        setStatusButton(true);
        if (render !== 'add') {
            await editRecord(`/parameters/${parameter.id_parameter}`, values).then(request => {
                setStatusButton(false);
                if (request.statusResponse) {
                    redirect('edit');
                }
            }).catch(error => getErrors(error));
        } else {
            await createRecord(`/parameters`, values).then(request => {
                setStatusButton(false);
                if (request.statusResponse) {
                    redirect('created');
                }
            }).catch(error => getErrors(error));
        }
    }

    const getErrors = (error) => {

        setWarningMessage('');
        setErrorMessage(false);

        let respondeError = parameterErrors(error);
 
        if (respondeError === 'error') return setErrorMessage(true);

        setWarningMessage(respondeError);
        setStatusButton(false);

    }

    const redirect = (action) => {
        createdOrEditParameter(action);
        actionChange('table');
    }

    const comboBoxChange = (e) => handleChange(e);

    return loading ? <Loading /> : errorMessage ? <ErrorPage redirect={true} actionChange={actionChange} name={'Parámetro'} /> :
        <section className="section form-color">
            <form onSubmit={handleSubmit} noValidate>
                <div className="acction-card"><span aria-hidden="true">{render === 'add' ? 'Agregar' : 'Actualizar'} Parámetro</span></div>

                {warningMessage !== '' && <Warning text={warningMessage} />}

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group coriport-input">
                            <label htmlFor="parameter_type" className="form-label">Tipo de Parámetro</label>
                            <input type="text" className={`input-form form-control ${errors.parameter_type && 'is-danger'}`} placeholder="Tipo de Parámetro" name="parameter_type" onChange={handleChange} value={values.parameter_type || ''} required />
                            {errors.parameter_type && (
                                <p className="help is-danger">{errors.parameter_type}</p>
                            )}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group coriport-input">
                            <div className="d-flex align-items-baseline">
                                <label htmlFor="email" className="form-label">Valor del Parámetro</label>
                                <div data-toggle="tooltip" data-placement="top" title={`Formato: correos separado por ,`} className="ml-2">
                                    <svg style={{ width: '20px', height: '20px' }} viewBox="0 0 20 20">
                                        <path d={svgToEmails}></path>
                                    </svg>
                                </div>
                            </div>
                            <div style={{ marginTop: '-17.5px' }} className="d-flex align-items-baseline">
                                <input autoComplete="off" type="email" className={`input-form form-control ${errors.parameter_value && 'is-danger'}`} placeholder="email@email.com,email@email.com,.." name="parameter_value" onChange={handleChange} value={values.parameter_value || ''} required />
                            </div>
                            {errors.parameter_value && (
                                <p className="help is-danger">{errors.parameter_value}</p>
                            )}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group coriport-input">
                            <div className="form-group coriport-input">
                                <label htmlFor="description" className="form-label">Descripción</label>
                                <input type="text" className={`input-form form-control`} maxLength='100' placeholder="Descripción" name="description" onChange={handleChange} value={values.description || ''} required />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group coriport-input">
                            <ComboBox name={"enable"} text={"Estado"} options={selectEnable} onChange={comboBoxChange} defaultValueSelect={defaultEnable} />
                            {errors.enable && (
                                <p className="help is-danger">{errors.enable}</p>
                            )}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">

                    </div>
                </div>

                <div className="container-bottons">
                    <button disabled={statusButton} id='custom-btn-coriport' className=" btn  btn-coriport btn-add text-white " type="submit">
                        {
                            statusButton ? <div className="container-spinner-loading pt-2">
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </div> : <p className="label-button-add"> {render === 'add' ? 'Agregar' : 'Actualizar'}</p>
                        }
                    </button>
                    <button className="btn-coriport btn-cancel" onClick={() => actionChange('table')}>
                        Cancelar
                    </button>
                </div>

            </form>
        </section>
}
