import React from "react";
import "./Notification.css";

export default class Warning extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: true
        };
    }

    componentDidMount() {
        const className = this.props.className;
        const classNameValue = className || '';
        if (classNameValue === '') {
            setTimeout(() => {
                this.setState({ show: false })
            }, 3000);
        }
    }

    handleClose() {
        this.setState({ show: false });
    }

    render() {

        let { show } = this.state;
        let { text } = this.props;

        return (
            <>
                {
                    show && (
                        <div className="container-success">
                            <div className="warning-notification">
                                <div className="notification-text">
                                    <p className="">
                                        {text}
                                    </p>
                                </div>
                                <span onClick={() => this.handleClose()} className="notification-x ">×</span>
                            </div>
                        </div>
                    )
                }
            </>
        );
    }
}
