 
export default function validate(values) {
  let errors = {};
  /* eslint-disable no-useless-escape */
  const regexEmail = /^((([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,}))(,(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,}))*$/g;

  if (values.email) {
    if (regexEmail.test(values.email) === false) {
      errors.email = "*Debe ingresar un correo valido";
    }
  }

  if (!values.contract_code) {
    errors.contract_code = "*El código es requerido";
  }

  if (!values.airport_iata_code) {
    errors.airport_iata_code = "*El código es requerido";
  }

  if (!values.location) {
    errors.location = "*La ubicación es requerida";
  }

  if (!values.local_commercial_name) {
    errors.local_commercial_name = "*El nombre es requerido";
  }

  if (!values.vinci_commercial_name) {
    errors.vinci_commercial_name = "*El nombre es requerido";
  }

  if (values.area_sqm < 0) {
    errors.area_sqm = "*Ingrese un número valido";
  }

  if (!values.area_sqm) {
    errors.area_sqm = "*El número es requerido";
  }

  if (!values.shop_format) {
    errors.shop_format = "*El formato es requerido";
  }

  if (!values.local_businness_category_l1) {
    errors.local_businness_category_l1 = "*La categoría es requerida";
  }

  if (!values.local_businness_category_l2) {
    errors.local_businness_category_l2 = "*La categoría es requerida";
  }

  if (!values.vinci_businness_category_l1) {
    errors.vinci_businness_category_l1 = "*La categoría es requerida";
  }

  if (!values.vinci_businness_category_l2) {
    errors.vinci_businness_category_l2 = "*La categoría es requerida";
  }

  if (!values.flow_pax) {
    errors.flow_pax = "*El flujo es requerido";
  }

  if (!values.terminal) {
    errors.terminal = "*La terminal es requerida";
  }

  if (!values.pier) {
    errors.pier = "*El muelle es requerido";
  }

  if (!values.airport_area) {
    errors.airport_area = "*El área es requerida";
  }

  if (!values.local_travel_area) {
    errors.local_travel_area = "*El área es requerida";
  }

  if (!values.vinci_travel_area) {
    errors.vinci_travel_area = "*El área es requerida";
  }

  if (!values.location_number) {
    errors.location_number = "*El número es requerido";
  }

  if (values.location_number < 0) {
    errors.location_number = "*El número es requerido";
  }

  if (!values.date_activity_started) {
    errors.date_activity_started = "*La fecha es requerida";
  }

  if (!values.date_activity_stopped) {
    errors.date_activity_stopped = "*La fecha es requerida";
  }

  if (values.code_shop && values.code_shop.substr(-1) === ",") {
    errors.code_shop = "*Finalizar con número";
  }

  if (!values.shop_name) {
    errors.shop_name = "*El nombre es requerido";
  }

  if (!values.shop_long_name) {
    errors.shop_long_name = "*El nombre es requerido";
  }
  if (values.enable === undefined) {
    errors.enable = "*El estado es requerido";
  }

  if (values.id_sender === undefined) {
    errors.id_sender = "*El emitente es requerido";
  }

  if (values.id_agent === undefined) {
    errors.id_agent = "*El agente es requerido";
  }

  if (values.date_activity_started > values.date_activity_stopped) {
    errors.date_activity_stopped = "*Ingrese una fecha válida";
  }

  if (values.max_invoice_time < 0) {
    errors.max_invoice_time = "*Ingrese un número valido";
  }

  return errors;
}
