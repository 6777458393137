export default function validate(values){
    
    let errors = {};

    if(!values.startDate && (values.fileType !== 'unclassifiedproducts' && values.fileType !== 'vincicategories')){
        errors.startDate = '*Fecha de inicio requerida';
    }
    if(!values.endDate && (values.fileType !== 'unclassifiedproducts' && values.fileType !== 'vincicategories')){
        errors.endDate = '*Fecha final requerida';
    }
    if (values.startDate > values.endDate) {
        errors.endDate = '*Verificar fecha final';
    }
    if(!values.fileType){
        errors.fileType = '*Tipo de documento requerido';
    }
    if(values.fileType === 'consolidatedbyagent' && !values.idAgent){
        errors.idAgent = '*El agente es requerido';
    } 
    if(values.fileType === 'unclassifiedproducts' && !values.idAgent){
        errors.idAgent = '*El agente es requerido';
    } 

    return errors;

}