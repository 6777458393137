import React, { useEffect, useState } from 'react'
import { createRecord, editRecord } from '../../../request/Request';
import { currencyErrors } from '../../../service/ErrorNotification/currenyErrors';
import { selectEnable } from '../../../service/defaultData';
import validate from '../../../validate-forms/Currency/AddFormValidationRules';
import useForm from '../../../validate-forms/useForm';
import { ComboBox } from '../../ComboBox/ComboBox';
import { ErrorPage } from '../../ErrorPage/ErrorPage';
import { Loading } from '../../Loading/Loading';
import Warning from '../../Notification/Warning';
import { getDefaultOptionSelect } from '../../../service/comboBox';

export const AddOrEdit = ({ actionChange, currency, createdOrEditCurrency, render }) => {

    const [errorMessage, setErrorMessage] = useState(false);
    const [loading, setLoading] = useState(true);
    const [defaultEnable, setDefaultEnable] = useState(null);
    const [statusButton, setStatusButton] = useState(false);
    const { values, errors, handleChange, handleSubmit } = useForm(sendForm, validate);
    const [warningMessage, setWarningMessage] = useState('');

    useEffect(() => {

        if (render === 'add') {
            setLoading(false);
        } else {

            let defaultEnableTemp = getDefaultOptionSelect(selectEnable, currency.enable);
            setDefaultEnable(defaultEnableTemp[0]);

            values.country = currency.country;
            values.description = currency.description;
            values.currency_code = currency.currency_code;
            values.enable = currency.enable;
            setLoading(false);
        }

    }, []);

    async function sendForm() {
        setWarningMessage('');
        setStatusButton(true);
        if (render !== 'add') {
            await editRecord(`/currency/${currency.currency_code}`, values).then(request => {
                setStatusButton(false);
                if (request.statusResponse) {
                    redirect('edit');
                }
            }).catch(error => getErrors(error));

        } else {
            await createRecord(`/currency`, values).then(request => {
                setStatusButton(false);
                if (request.statusResponse) {
                    redirect('created');
                }
            }).catch(error => getErrors(error));
        }

    }

    const getErrors = (error) => {

        setWarningMessage('');
        setErrorMessage(false);

        let respondeError = currencyErrors(error);

        if (respondeError === 'error') return setErrorMessage(true);

        setWarningMessage(respondeError);
        setStatusButton(false);

    }

    const redirect = (action) => {
        createdOrEditCurrency(action);
        actionChange('table');
    }

    const comboBoxChange = (e) => handleChange(e);

    return loading ? <Loading /> : errorMessage ? <ErrorPage redirect={true} actionChange={actionChange} name={'moneda'} /> :
        <section className="section form-color">
            <form onSubmit={handleSubmit} noValidate>

                <div className="acction-card"><span aria-hidden="true">
                    {render == 'add' ? 'Agregar' : 'Actualizar'} Moneda
                </span></div>

                {warningMessage !== '' && <Warning text={warningMessage} />}

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group coriport-input">
                            <label htmlFor="country" className="form-label">País</label>
                            <input type="text" className={`input-form form-control ${errors.country && 'is-danger'}`} placeholder="País" name="country" onChange={handleChange} value={values.country || ''} required />
                            {errors.country && (
                                <p className="help is-danger">{errors.country}</p>
                            )}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group coriport-input">
                            <label htmlFor="currency_code" className="form-label">Código</label>
                            <input type="text" className={`input-form form-control ${errors.currency_code && 'is-danger'}`} placeholder="Código" name="currency_code" onChange={handleChange} value={values.currency_code || ''} required />
                            {errors.currency_code && (
                                <p className="help is-danger">{errors.currency_code}</p>
                            )}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group coriport-input">
                            <label htmlFor="description" className="form-label">Descripción</label>
                            <input type="text" className={`input-form form-control ${errors.description && 'is-danger'}`} maxLength="100" placeholder="Descripción" name="description" onChange={handleChange} value={values.description || ''} required />
                            {errors.description && (
                                <p className="help is-danger">{errors.description}</p>
                            )}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group coriport-input">
                            <ComboBox name={"enable"} text={"Estado"} options={selectEnable} onChange={comboBoxChange} defaultValueSelect={defaultEnable} />
                            {errors.enable && (
                                <p className="help is-danger">{errors.enable}</p>
                            )}
                        </div>
                    </div>
                </div>

                <div className="container-bottons">
                    <button disabled={statusButton} id='custom-btn-coriport' className=" btn  btn-coriport btn-add text-white " type="submit">
                        {
                            statusButton ? <div className="container-spinner-loading pt-2">
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </div> : <p className="label-button-add"> {render == 'add' ? 'Agregar' : 'Actualizar'}</p>
                        }
                    </button>
                    <button className="btn-coriport btn-cancel" onClick={() => actionChange('table')}>
                        Cancelar
                    </button>
                </div>

            </form>
        </section>

}
