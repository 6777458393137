export default function validate(values){
    
    let errors = {};

    if(!values.day){
        errors.day = '*El día es requerido';
    }
    if(!values.hour){
        errors.hour = '*Las hora es requerida';
    } 
    if(values.hour <= 0){
        errors.hour = '*Número invalido';
    } 
    if(values.enable === undefined){
        errors.enable = '*El estado es requerido'
    }
    if(values.status === undefined){
        errors.status = '*El estado es requerido'
    }


    return errors;
}