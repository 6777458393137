export default function validate(values){
    
    let errors = {};
    if(!values.subgroup_name){
        errors.subgroup_name = '*El nombre es requerido';
    }
    if(!values.id_agent){
        errors.id_agent = '*El agente es requerido';
    }
    if(values.enable === undefined){
        errors.enable = '*El estado es requerido';
    } 
    if(!values.id_product_category){
        errors.id_product_category = '*La categoría es requerida';
    }
    if(!values.id_product_group){
        errors.id_product_group = '*El grupo es requerido';
    }
    if(!values.id_vinci_product_subgroup){
        errors.id_vinci_product_subgroup = '*El sub-grupo de vinci es requerido';
    }
    if(!values.subgroup_code){
        errors.subgroup_code = '*El sub-grupo es requerido';
    }
    
    return errors;
}