import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Callback from './routes/Callback'
import { createBrowserHistory } from 'history'
import Home from "./components/Home/Home";
const history = createBrowserHistory()
function App() {
  return (
      <div className="App">
              <Router history={history}>
              <Route exact path="/" component={Home}/>
              <Route exact path="/Home" component={Home}/>
              <Route exact path="/login" component={Callback}/>    
              </Router>
      </div>
  );
}

export default App;

