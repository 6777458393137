export const currencyErrors = ({ error, data }) => {

    let message = "";

    if (error === 500) return message = "error";

    if (error === 404 && data.message === 'Error, the currency with the sent currency code is not found') return message = "La moneda ingresada no es válida";

    if (error === 400 && data.message === 'A currency with the country sent is already registered') return message = "El país ingresado ya existe";

    if (error === 400 && data.message === 'A currency already exists with the submitted currency code') return message = "El código ingresado ya existe";

    if (error === 400 && data.message === 'A currency already exists with the submitted Country name') return message = "El país ingresado ya existe";
   
    if (error === 400 && data.message === ' The currency you want to disable was already disabled') return message = "La moneda ingresada ya está desactivada";
   
    if(error === 400 && data.message.includes('The currency id cannot be deleted as it is associated in')) return message = 'La moneda a eliminar posee facturas asociadas'

    return message;
    
}