import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { selectVoucherType } from '../../../service/comboBox';
import { Loading } from '../../Loading/Loading';
import './ShopSection.css';

export const ShopSection = (props) => {

    const [shop, setShop] = useState(null);
    const [vouchers, setVouchers] = useState(null);
    const [defaultAgent, setDefaultAgent] = useState(null);
    const [defaultSender, setDefaultSender] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getAllVoucherType();
    }, [])
    

    useEffect(() => {
        setShop(props.shop);
        if (props.defaultAgent !== defaultAgent && props.defaultSender !== defaultSender) {
            setDefaultAgent(props.defaultAgent[0]?.label);
            setDefaultSender(props.defaultSender[0]?.label);
        }
        if (defaultAgent && defaultSender) setLoading(false);
    }, [props, defaultAgent, defaultSender, shop, loading]);

    const getAllVoucherType = async () => {
        let vouchers = await selectVoucherType();
        if (vouchers.statusResponse) {
            setVouchers(vouchers.data);
        }
    }

    return (
        <>
            {loading ? <Loading /> : <div className="col-lg-12 mt-2 container-shop-section">

                <div className="row mt-2">
                    <div className="col-md-4 container-span">
                        <span className='style-span' >-Código de Tienda: </span> {shop?.code_shop}
                    </div>
                    <div className="col-md-4 container-span">
                        <span className='style-span'  >-Código de Contrato: </span> {shop?.contract_code}
                    </div>
                    <div className="col-md-4 container-span">
                        <span className='style-span'  >-Estado: </span> {shop?.enable ? 'Habilitado' : 'Deshabilitado'}
                    </div>
                </div>

                <div className="row mt-4 ">
                    <div className="col-12 container-span">
                        <span className='style-span'  >-Nombre de la Tienda (Corto): </span> {shop?.shop_name}
                    </div>
                </div>

                <div className="row mt-2 ">
                    <div className="col-12 container-span">
                        <span className='style-span'>-Nombre de la Tienda (largo): </span> {shop?.shop_long_name}
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-12 container-span">
                        <span className='style-span'>-Nombre Local Comercial: </span> {shop?.local_commercial_name}
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-12 container-span">
                        <span className='style-span'>-Nombre Local Comercial (Vinci): </span> {shop?.vinci_commercial_name}
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-md-4 container-span">
                        <span className='style-span'>-Agente: </span> {defaultAgent}
                    </div>
                    <div className="col-md-4 container-span">
                        <span className='style-span'>-Remitente: </span> {defaultSender}
                    </div>
                    <div className="col-md-4 container-span">
                        <span className='style-span'>-Flujo de Personas: </span> {shop?.flow_pax}
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-md-4 ">
                        <span className='style-span'>-Fecha de Última Factura: <br /><span className='format-span'></span></span>&nbsp;&nbsp;{shop?.date_last_invoice ? moment(shop?.date_last_invoice).format('DD/MM/YYYY, h:mm a') : ''}<span>  </span>
                    </div>
                    <div className="col-md-8">
                        <span className='style-span'>-Última Factura: </span> <br /><span className='pl-2'>{shop?.document_number}</span>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-md-4">
                        <span className='style-span'>-Tiempo Máximo de Facturación: <br /> </span> <span className='pl-2'>{shop?.max_invoice_time ? shop?.max_invoice_time + ' minutos' : ''} </span>
                    </div>
                    <div className="col-md-4">
                        <span className='style-span'>-Correos de Notificación: </span> <br /> {shop?.email ? shop?.email.replace(",", ", ") : ''}
                    </div>
                    <div className="col-md-4">
                        <div className='d-inline-flex'>
                            <span className='style-span'>-Tipo de Última Factura: </span>

                            <span>
                                {
                                    vouchers && vouchers.map(v => {
                                        if (v.value === shop.id_voucher_type) {
                                            return (
                                                <p key={v.value} className='pt-1 pl-2'> {v.label} </p>
                                            )
                                        }
                                    })
                                }
                            </span>
                        </div>

                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-md-4 container-span">
                        <span className='style-span'  >-Número de Pos: </span> {shop?.number_of_pos}
                    </div>
                    <div className="col-md-4 container-span">
                        <span className='style-span'  >-Identifiación del Contrato: </span> {shop?.cnpj}
                    </div>
                    <div className="col-md-4 container-span">
                        <span className='style-span'  >-Número de Ubicación: </span> {shop?.location_number}
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-md-4 container-span">
                        <span className='style-span'  >-Ubicación: </span> {shop?.location}
                    </div>
                    <div className="col-md-4 container-span">
                        <span className='style-span'  >-Área del Aeropuerto: </span> {shop?.airport_area}
                    </div>
                    <div className="col-md-4 container-span">
                        <span className='style-span'  >-Terminal: </span> {shop?.terminal}
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-md-4 container-span">
                        <span className='style-span'  >-Metros Cuadrados: </span> {shop?.area_sqm}m<sup>2</sup>
                    </div>
                    <div className="col-md-4 container-span">
                        <span className='style-span'  >-Pisos: </span> {shop?.shop_floor}
                    </div>
                    <div className="col-md-4 container-span">
                        <span className='style-span'  >-Tipo de Servicio: </span> {shop?.type_of_service}
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-md-4 container-span">
                        <span className='style-span'  >-Código IATA: </span> {shop?.airport_iata_code}
                    </div>
                    <div className="col-md-4 container-span">
                        <span className='style-span'  >-Área: </span> {shop?.pier}
                    </div>
                    <div className="col-md-4 container-span">
                        <span className='style-span'  >-Formato: </span> {shop?.shop_format}
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-md-4 container-span">
                        <span className='style-span'>-Fecha de Inicio <br /><span className='format-span' style={{ marginTop: '-7px' }}>&nbsp;de actividades: </span></span> {moment(shop?.date_activity_started).format('DD/MM/YYYY')}<span>  </span>
                    </div>
                    <div className="col-md-6 container-span">
                        <span className='style-span'>-Fecha de Finalización <br /><span className='format-span'>&nbsp;de actividades: </span></span> {moment(shop?.date_activity_stopped).format('DD/MM/YYYY')}<span>  </span>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-md-4 container-span">
                        <span className='style-span'>-Categoría del local <br /><span className='format-span'>&nbsp;de negocios L1: </span></span>{shop?.local_businness_category_l1}<span>  </span>
                    </div>
                    <div className="col-md-4 container-span">
                        <span className='style-span'>-Categoría del local <br /><span>&nbsp;de negocios L2: </span></span>{shop?.local_businness_category_l2}<span>  </span>
                    </div>
                    <div className="col-md-4 container-span">
                        <span className='style-span'  >-Área de viaje local: </span>{shop?.local_travel_area}
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-md-4 container-span">
                        <span className='style-span'>-Categoría de negocios <br /><span className='format-span'>&nbsp;L1 (Vinci): </span></span>{shop?.vinci_businness_category_l1}<span>  </span>
                    </div>
                    <div className="col-md-4 container-span">
                        <span className='style-span'>-Categoría de negocios <br /><span className='format-span'>&nbsp;L2 (Vinci): </span></span>{shop?.vinci_businness_category_l2}<span>  </span>
                    </div>
                    <div className="col-md-4 container-span">
                        <span className='style-span'  >-Área de viaje local (Vinci): </span>{shop?.vinci_travel_area}
                    </div>
                </div>

            </div>}
        </>
    )
}