import React from 'react'
import { HeaderTable } from '../HeaderTable'
import { Download } from './Download'
import { Upload } from './Upload'
import { MassiveUpload } from './MassiveUpload'

export const Report = () => {
    return (

        <div className="col-lg-12 mt-5">
            <div className="card-table">
                <HeaderTable title={'Reportes'} />
                <div className="mb-5 mt-4">
                    <Download />
                    <hr />
                    <MassiveUpload />
                    <hr />
                    <Upload />
                </div>
            </div>
        </div>
    )
}
