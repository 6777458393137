import React from "react";
import { deleteRecord, disableRecord, getAll } from "../../request/Request";
import { selectAgent, selectSender } from "../../service/comboBox";
import { ButtonAdd } from "../ButtonAdd/ButtonAdd";
import DataTableMaterial from "../DataTableMaterial/DataTableMaterial";
import { HeaderTable } from "../HeaderTable";
import { ModalShop } from "../Modal/ModalShop";
import ModalToDelete from "../Modal/ModalToDelete";
import ModalToEnable from "../Modal/ModalToEnable";
import Success from "../Notification/Success";
import { Search } from "../Search/Search";
import { AddOrEdit } from "./AddOrEdit/AddOrEdit";
import moment from 'moment';
import Warning from "../Notification/Warning";
import { customColumn } from "../../service/dataTable";
import { shopsErrors } from "../../service/ErrorNotification/shopsErrors";

export class Shop extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            render: 'table',
            loading: true,
            dataTable: {
                data: [],
                perPage: 10,
                total: 0,
                currentPage: 1,
            },
            errorsRequest: {
                errorMessage: '',
                errorStatus: false
            },
            successRequest: {
                successMessage: '',
                successStatus: false
            },
            warningRequest: {
                successMessage: '',
                successStatus: false
            },
            dataToDelete: {
                show: false,
                data: {}
            },
            dataToDisable: {
                show: false,
                data: {}
            },
            dataModalShop: {
                show: false,
                data: {}
            },
            shopSelected: {},
            defaultSender: null,
            defaultAgent: null,
            allShop: [],
            allAgent: [],
            allSender: [],
            defaultSearchParam: '',
            searchParam: '',
            statusPetition: false
        };

        this.getRowsPerPage = this.getRowsPerPage.bind(this);
        this.actionChange = this.actionChange.bind(this);
        this.getCurrentPage = this.getCurrentPage.bind(this);
        this.deleteShop = this.deleteShop.bind(this);
        this.disableShop = this.disableShop.bind(this);
        this.closeSeeShop = this.closeSeeShop.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.searchShop = this.searchShop.bind(this);

    }

    componentDidMount() {
        this.getAllShop();
        this.setState({ dataToDelete: { show: false, data: {} } });
        this.setState({ dataToDisable: { show: false, data: {} } });
        this.setState({ dataModalShop: { show: false, data: {} } });
        this.setState({ defaultAgent: null });
        this.setState({ defaultSender: null });
    }

    componentDidUpdate(prevProps, prevState) {
        let { dataTable, render, searchParam } = this.state;
        if ((prevState.dataTable.perPage !== dataTable.perPage || prevState.dataTable.currentPage !== dataTable.currentPage)
            ||
            (prevState.render !== render && render === 'table')) {
            this.setState({ defaultSearchParam: searchParam });
            this.getAllShop();
        }
    }

    actionChange(action) {
        if (action === 'add') this.setState({ shopSelected: {} });
        this.setState({ render: action });
    }

    getCurrentPage(page) {
        let { dataTable, statusPetition } = this.state;
        if (!statusPetition) {
            this.setState({ dataTable: { ...dataTable, currentPage: page } });
        }
    }

    handleDelete(shop) {
        this.setState({ dataToDelete: { show: true, data: { ...shop, name: shop.shop_name } } });
    }

    getRowsPerPage(perPage) {
        let { dataTable } = this.state;
        this.setState({ dataTable: { ...dataTable, perPage: perPage } });
    };

    async getAllShop() {

        let { dataTable, searchParam } = this.state;

        if (!dataTable.data.length) this.setState({ loading: true });

        this.setState({ statusPetition: true });
        await getAll(`/shop?page=${dataTable.currentPage - 1}&perPage=${dataTable.perPage}&order=desc&search=${searchParam}&paginated=true`).then(request => {
            let { data } = request;
            this.setState({ dataTable: { ...dataTable, total: data.total, data: data.data } });

        }).catch(error => {
            if (error.error === 404) {
                this.setState({ dataTable: { ...dataTable, total: 0, data: [] } });
            } else {
                this.setState({ errorsRequest: { errorMessage: 'Tiendas', errorStatus: true } });
            }
        });
        this.setState({ statusPetition: false });
        this.setState({ loading: false });
    }

    async handleEdit(shop) {
        let { shopSelected } = this.state;
        this.setState({ shopSelected: shop });
        if (this.getDefaultAgentAndSender(shop)) {
            if (shopSelected !== {}) {
                this.actionChange('edit');
            }
        }
    }
    async deleteShop(shop) {
        await deleteRecord(`/shop/${shop.id_shop}`).then(request => {

            let { dataTable } = this.state;
            let shopTemp = dataTable.data.filter(x => x.id_shop !== shop.id_shop);
            this.setState({ dataTable: { ...dataTable, total: shopTemp.length, data: shopTemp } });
            this.successNotifiaction('Tienda eliminada', true);

        }).catch(error => {
            let respondeError = shopsErrors(error);
            if (respondeError === 'error') return this.setState({ errorsRequest: { errorMessage: 'Tiendas', errorStatus: true } });
            this.warningNotification(respondeError, true);

        });
        this.setState({ dataToDelete: { show: false, data: {} } });
    }

    handleEnable(shop) {
        this.setState({ dataToDisable: { show: true, data: { ...shop, name: shop.shop_name } } });
    }
    async disableShop(shop) {
        await disableRecord(`/shop/${shop.id_shop}`).then(request => {
            let { dataTable } = this.state;
            dataTable.data.map(function (a) {
                if (a.id_shop === shop.id_shop) a.enable = 'DIS';
            });
            this.successNotifiaction('Tienda desactivada', true);
            this.setState({ ...dataTable, data: dataTable.data });

        }).catch(error => {
            let respondeError = shopsErrors(error);
            if (respondeError === 'error') return this.setState({ errorsRequest: { errorMessage: 'Tiendas', errorStatus: true } });
            this.warningNotification(respondeError, true);

        });
        this.setState({ dataToDisable: { show: false, data: {} } });
    }

    handleSeeShop(shop) {
        if (this.getDefaultAgentAndSender(shop)) {
            this.setState({ dataModalShop: { data: shop, show: true } });
        }
    }
    closeSeeShop() {
        this.setState({ dataModalShop: { show: false, data: {} } });
    }

    async getDefaultAgentAndSender(shop) {
        let { defaultAgent, defaultSender } = this.state;
        this.setState({ defaultAgent: null });
        this.setState({ defaultSender: null });
        let response = false;
        const senders = await selectSender();
        const agents = await selectAgent();
        if (senders.statusResponse && agents.statusResponse) {
            const sender = senders.data.filter(c => c.value === shop.id_sender);
            this.setState({ allSender: senders.data });
            if (sender.length > 0) {
                this.setState({ defaultSender: sender });
            } else {
                this.setState({ defaultSender: '' });
            }

            const agent = agents.data.filter(c => c.value === shop.id_agent);
            this.setState({ allAgent: agents.data });

            if (agent.length > 0) {
                this.setState({ defaultAgent: agent });
            } else {
                this.setState({ defaultAgent: '' });
            }

        } else {
            this.setState({ errorsRequest: { errorMessage: 'Tiendas', errorStatus: true } });
        }
        if (defaultAgent && defaultSender) {
            return response = true;
        }
    }

    successNotifiaction(msg, status) {
        this.setState({ ...this.state, successRequest: { successMessage: msg, successStatus: status } });
        setTimeout(() => {
            this.setState({ ...this.state, successRequest: { successMessage: '', successStatus: false } });
        }, 2500);
    }

    warningNotification(msg, status) {
        this.setState({ ...this.state, warningRequest: { successMessage: msg, successStatus: status } });
        setTimeout(() => {
            this.setState({ ...this.state, warningRequest: { successMessage: '', successStatus: false } });
        }, 2500);
    }

    createdOrEditShop = (action) => {
        if (action === 'created') {
            this.successNotifiaction('Tienda agregada', true);
        } else {
            this.successNotifiaction('Tienda actualizada', true);
        }
    }

    handleSearch(value) {
        this.setState({ searchParam: value });
    }

    searchShop() {
        this.getAllShop();
    }

    materialDataTable() {
        let columns = [
            {
                ...customColumn('Nombre', row => row.shop_name),
                cell: row => <p className="capitalize-text">{row.shop_name}</p>,
            },
            {
                ...customColumn('Ubicación', row => row.location),
                cell: (row) => <p className="capitalize-text">{row.location ? row.location : '-'}</p>,
            },
            {
                ...customColumn('Última Factura', row => row.document_number),
                cell: (row) => <p className="capitalize-text">{row.document_number ? row.document_number : '-'}</p>,
            },
            {
                ...customColumn('Fecha Última Factura', row => row.date_last_invoice),
                cell: (row) => <p className="capitalize-text">{row.date_last_invoice ? moment(row.date_last_invoice).format('DD/MM/YYYY, h:mm a') : '-'}</p>,
            },
            {
                ...customColumn('Estado', row => row.enable),
                cell: (row) =>
                    <div>
                        {row.enable === 'ENA' && (
                            <span id="ena-status" onClick={() => { row.enable === 'ENA' && this.handleEnable(row) }} className={row.enable === 'ENA' && ` active-status`}>
                                {row.enable === 'ENA' && 'Activo'}
                            </span>
                        )}

                        {row.enable === 'DIS' && (
                            <span id="dis-status" className={row.enable === 'DIS' && `inactive-status`}>
                                {row.enable === 'DIS' && 'Inactivo'}
                            </span>
                        )}

                        {row.enable === 'ATT' && (
                            <span id="att-status" className={row.enable === 'ATT' && `att-nft-status`}>
                                {row.enable === 'ATT' && 'Atención'}
                            </span>
                        )}

                        {row.enable === 'NTF' && (
                            <span className={row.enable === 'NTF' && `att-nft-status`}>
                                {row.enable === 'NTF' && 'Notificado'}
                            </span>
                        )}

                    </div>

                ,
            },
            {
                ...customColumn('Acciones'),
                cell: (row) => (
                    <>
                        <button className="botton-delete">
                            <svg onClick={() => { this.handleDelete(row) }} className="svg-icon" viewBox="0 0 20 20">
                                <path d="M17.114,3.923h-4.589V2.427c0-0.252-0.207-0.459-0.46-0.459H7.935c-0.252,0-0.459,0.207-0.459,0.459v1.496h-4.59c-0.252,0-0.459,0.205-0.459,0.459c0,0.252,0.207,0.459,0.459,0.459h1.51v12.732c0,0.252,0.207,0.459,0.459,0.459h10.29c0.254,0,0.459-0.207,0.459-0.459V4.841h1.511c0.252,0,0.459-0.207,0.459-0.459C17.573,4.127,17.366,3.923,17.114,3.923M8.394,2.886h3.214v0.918H8.394V2.886z M14.686,17.114H5.314V4.841h9.372V17.114z M12.525,7.306v7.344c0,0.252-0.207,0.459-0.46,0.459s-0.458-0.207-0.458-0.459V7.306c0-0.254,0.205-0.459,0.458-0.459S12.525,7.051,12.525,7.306M8.394,7.306v7.344c0,0.252-0.207,0.459-0.459,0.459s-0.459-0.207-0.459-0.459V7.306c0-0.254,0.207-0.459,0.459-0.459S8.394,7.051,8.394,7.306"></path>
                            </svg>
                        </button>
                        <button className="botton-edit">
                            <svg onClick={() => { this.handleEdit(row) }} className="svg-icon" viewBox="0 0 20 20">
                                <path d="M19.404,6.65l-5.998-5.996c-0.292-0.292-0.765-0.292-1.056,0l-2.22,2.22l-8.311,8.313l-0.003,0.001v0.003l-0.161,0.161c-0.114,0.112-0.187,0.258-0.21,0.417l-1.059,7.051c-0.035,0.233,0.044,0.47,0.21,0.639c0.143,0.14,0.333,0.219,0.528,0.219c0.038,0,0.073-0.003,0.111-0.009l7.054-1.055c0.158-0.025,0.306-0.098,0.417-0.211l8.478-8.476l2.22-2.22C19.695,7.414,19.695,6.941,19.404,6.65z M8.341,16.656l-0.989-0.99l7.258-7.258l0.989,0.99L8.341,16.656z M2.332,15.919l0.411-2.748l4.143,4.143l-2.748,0.41L2.332,15.919z M13.554,7.351L6.296,14.61l-0.849-0.848l7.259-7.258l0.423,0.424L13.554,7.351zM10.658,4.457l0.992,0.99l-7.259,7.258L3.4,11.715L10.658,4.457z M16.656,8.342l-1.517-1.517V6.823h-0.003l-0.951-0.951l-2.471-2.471l1.164-1.164l4.942,4.94L16.656,8.342z"></path>
                            </svg>
                        </button>
                        <button className="botton-modal-invoice">
                            <svg onClick={() => { this.handleSeeShop(row) }} className="svg-icon" viewBox="0 0 20 20">
                                <path d="M10,6.978c-1.666,0-3.022,1.356-3.022,3.022S8.334,13.022,10,13.022s3.022-1.356,3.022-3.022S11.666,6.978,10,6.978M10,12.267c-1.25,0-2.267-1.017-2.267-2.267c0-1.25,1.016-2.267,2.267-2.267c1.251,0,2.267,1.016,2.267,2.267C12.267,11.25,11.251,12.267,10,12.267 M18.391,9.733l-1.624-1.639C14.966,6.279,12.563,5.278,10,5.278S5.034,6.279,3.234,8.094L1.609,9.733c-0.146,0.147-0.146,0.386,0,0.533l1.625,1.639c1.8,1.815,4.203,2.816,6.766,2.816s4.966-1.001,6.767-2.816l1.624-1.639C18.536,10.119,18.536,9.881,18.391,9.733 M16.229,11.373c-1.656,1.672-3.868,2.594-6.229,2.594s-4.573-0.922-6.23-2.594L2.41,10l1.36-1.374C5.427,6.955,7.639,6.033,10,6.033s4.573,0.922,6.229,2.593L17.59,10L16.229,11.373z"></path>
                            </svg>
                        </button>
                    </>
                ),
            },

        ];
        let { dataTable, loading, errorsRequest } = this.state;
        return <DataTableMaterial
            dataTableData={dataTable}
            loading={loading}
            errors={errorsRequest}
            columns={columns}
            getRowsPerPage={this.getRowsPerPage}
            getCurrentPage={this.getCurrentPage}
        />;
    }

    render() {

        let { render, dataToDelete, shopSelected, dataToDisable, successRequest, errorsRequest, dataModalShop, defaultAgent, defaultSender, allAgent, allSender, defaultSearchParam, warningRequest } = this.state;

        return (
            <div className="col-lg-12 mt-5">
                {
                    render === 'add' || render === 'edit' ?
                        <AddOrEdit agents={allAgent} senders={allSender} defaultAgent={defaultAgent} defaultSender={defaultSender} render={render} shop={shopSelected} actionChange={this.actionChange} createdOrEditShop={this.createdOrEditShop} /> :
                        <div className="card-table">
                            <HeaderTable title={'Tiendas'} />
                            <ModalToEnable record={dataToDisable} disableRecord={this.disableShop} />
                            <ModalToDelete record={dataToDelete} deleteRecord={this.deleteShop} />
                            <ModalShop closeSeeShop={this.closeSeeShop} defaultSender={defaultSender} defaultAgent={defaultAgent} shop={dataModalShop} />
                            {warningRequest.successStatus && <Warning text={warningRequest.successMessage} />}
                            {successRequest.successStatus && <Success text={successRequest.successMessage} />}
                            {!errorsRequest.errorStatus &&
                                <div className="flex">
                                    <div className="row mt-3">
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-6" id="container-input-search" >
                                                    <Search defaultValue={defaultSearchParam} handleSearch={this.handleSearch} />
                                                </div>

                                                <div className="col-md-6 ">
                                                    <div className=" container-bottons">
                                                        <button onClick={this.searchShop} className="btn-coriport btn-add">Buscar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4" >
                                            <div className="float-right">
                                                <ButtonAdd actionChange={this.actionChange} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="mb-5 mt-4">
                                {this.materialDataTable()}
                            </div>
                        </div>
                }
            </div>
        )
    }

}