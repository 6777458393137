
export const productCategoryVinciErrors = ({ error, data }) => {
    let message = "";

    if (error === 500) return message = "error";


    if (error === 404) return message = 'La categoría de vinci enviada no existe';

    if (error === 400 && data.message === 'A vinci product category already exists with the submitted category name') return message = 'El nombre de la categoría ya existe';
   
    if (error === 400 && data.message === 'The vinci product category you want to disable was already disabled') return message = 'La categoría de vinci ingresada ya está desactivada';
 
    if(error === 400 && data.message.includes('record(s) of the table productCategory')) return message = 'La categoría de vinci a eliminar posee categorías de productos asociadas';

    if(error === 400 && data.message.includes('record(s) of the table vinciProductGroup')) return message = 'La categoría de vinci a eliminar posee de grupo de productos de vinci asociadas';

    if(error === 400 && data.message.includes('record(s) of the table vinciProductSubgroup')) return message = 'La categoría de vinci a eliminar posee sub-grupos de productos de vinci asociadas';

    return message;

};
