export default function validate(values){
    
    let errors = {};
    
    if(!values.id_vinci_product_group){
        errors.id_vinci_product_group = '*El grupo es requerido';
    }
    if(!values.id_vinci_product_category){
        errors.id_vinci_product_category = '*La categoría es requerida';
    }
    if(!values.subgroup_name){
        errors.subgroup_name = '*El nombre es requerido';
    } 
    if(values.enable === undefined){
        errors.enable = '*El estado es requerido';
    }

    return errors;
    
}