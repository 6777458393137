import React, { useEffect, useState } from "react";
import { statusAgent } from "../../service/defaultData";
import moment from 'moment';

export const ModalAgents = (props) => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (props.dataAgent.data && props.dataAgent.show) {
      setData(props.dataAgent.data);
      setShow(props.dataAgent.show);
    }
  }, [props]);

  const handleCloseModal = () => {
    setData(null);
    setShow(false);
    props.closeModal();
  };

  return (
    <>
      {show && (
        <div className="Modal" style={{ display: "block" }} aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content modal-invoice">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Datos del Agente
                </h5>
              </div>
              <div className="modal-body">
                <div style={{ width: "100%" }}>
                  <div className="text-left mt-1">
                    <span className="style-span">-Nombre: </span> {data.client_name}
                  </div>

                  <div className="text-left mt-1">
                    <span className="style-span">-Nombre de Usuario: </span> {data.username}
                  </div>

                  <div className="text-left mt-1">
                    <span className="style-span">-Identificación: </span> {data.id_client}
                  </div>

                  <div className="text-left mt-1">
                    <span className="style-span">-Correos: </span>
                    {data.email && data.email.replace(",", ", ")}
                  </div>

                  <div className="text-left mt-1">
                    <span className="style-span">-Estado: </span>
                    {statusAgent.map((s) => {
                      if (s.value === props.dataAgent.data.enable) {
                        return `${s.label}`;
                      }
                    })}
                  </div>

                  <div className="text-left mt-1">
                    <span className="style-span">-Estado de Instalación: </span> {data.installed ? "Instalado" : "Desintalado"}
                  </div>

                  <div className="text-left mt-1">
                    <span className="style-span"> -Tiempo Máximo de Facturación: </span>{data.max_ping_time} <span>Minutos</span>
                  </div>

                  <div className="text-left mt-1">
                    <span className="style-span"> -Fecha de Última Comunicación: </span> 
                    {data.date_last_ping ? moment(data.date_last_ping).format('DD/MM/YYYY, h:mm a') : ''}
                  </div>

                  <div className="text-left mt-1">
                    <span className="style-span"> -Fecha de Última Factura: </span>
                    {data.date_last_invoice ? moment(data.date_last_invoice).format('DD/MM/YYYY, h:mm a') : ''}
                  </div>

                </div>
              </div>
              <div className="modal-footer">
                <button
                  onClick={() => handleCloseModal()}
                  type="button"
                  className="btn"
                  id="btn-cancel"
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
