export default function validate(values){
    
    let errors = {};

    if(!values.startDate){
        errors.startDate = '*Fecha de inicio requerida';
    }
    if(!values.endDate){
        errors.endDate = '*Fecha final requerida';
    }
    if (values.startDate > values.endDate) {
        errors.endDate = '*Verificar fecha final';
    }
    if(!values.fileType){
        errors.fileType = '*Tipo de documento requerido';
    }

    return errors;

}