import React, { useState, useEffect } from "react";
import { getUserSession, logout } from "./Accounts";

export default () => {
  const [status, setStatus] = useState(false);
  const [username, setUsername] = useState(""); 

  useEffect(() => {
    let userData = getUserSession();
    if (userData) {
      setStatus(true);
      setUsername(userData.user);
    }else{
      logout();
    }
  }, [status, username]);

  return (
    <div>
      {status ? (
        <div className="header_avatar">
          <svg className="header_avatar__svgicon" viewBox="0 0 20 20">
            <path d="M12.075,10.812c1.358-0.853,2.242-2.507,2.242-4.037c0-2.181-1.795-4.618-4.198-4.618S5.921,4.594,5.921,6.775c0,1.53,0.884,3.185,2.242,4.037c-3.222,0.865-5.6,3.807-5.6,7.298c0,0.23,0.189,0.42,0.42,0.42h14.273c0.23,0,0.42-0.189,0.42-0.42C17.676,14.619,15.297,11.677,12.075,10.812 M6.761,6.775c0-2.162,1.773-3.778,3.358-3.778s3.359,1.616,3.359,3.778c0,2.162-1.774,3.778-3.359,3.778S6.761,8.937,6.761,6.775 M3.415,17.69c0.218-3.51,3.142-6.297,6.704-6.297c3.562,0,6.486,2.787,6.705,6.297H3.415z"></path>
          </svg>
          <span className="header_avatar__username text-capitalize">
            {username}
          </span>
          <span className="header_avatar__username">|</span>

          <span onClick={logout} className="header_avatar__username">Salir</span>
          <svg
            onClick={logout}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            className="header_avatar__username logout-icon"
          >
            <g className="logout-icon">
              <path d="M10 13v1H3V2h7v1h1V1.5a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5V13h-1z" />
              <path d="M9.983 5.041a.5.5 0 0 1 .812-.39l3.7 2.96a.5.5 0 0 1 0 .78l-3.7 2.96a.5.5 0 0 1-.812-.39V9H7V7h2.983V5.041z" />
            </g>
          </svg>
        </div>
      ) : (
        "Por favor inicia Sesion"
      )}
    </div>
  );
};
