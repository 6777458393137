export const productCategoryErrors = ({ error, data }) => {
    let message = "";

    if (error === 500) return message = "error";
 
    if (error === 404 && data.message === 'Error, the product category with the sent id is not found') return  message = 'La categoría ingresada no existe';

    if (error === 400 && data.message === 'A product category already exists with the submitted subgroup name') return  message = 'El nombre ingresado ya está registrado';

    if (error === 400 && data.message === 'The id agent does not exist in the agent table, please check') return message = 'El agente ingresado no existe';

    if (error === 400 && data.message === 'Please contact the site administrator since the agent you want to insert is disabled') return message = 'El agente ingresado está desactivado';

    if (error === 400 && data.message === 'The id vinci product category does not exist in the vinci product category table, please check') return  message = 'La categoría de vinci ingresada no existe';

    if (error === 400 && data.message === 'Please contact the site administrator since the id vinci product category you want to insert is disabled') return  message = 'La categoría de vinci ingresado está desactivada';

    if (error === 400 && data.message === 'A product category already exists with the submitted category code, category name, id agent and id vinci product category') return message = 'Los datos ingresados ya están registrados';
   
    if (error === 400 && data.message === 'The product category you want to disable was already disabled') return message = 'La categoría ingresada ya está desactivada';

    if(error === 400 && data.message.includes('record(s) of the table invoice product')) return message = 'La categoría a eiliminar posee facturas asociadas';

    if(error === 400 && data.message.includes('record(s) of the table product')) return message = 'La categoría a eliminar posee productos asociados';

    if(error === 400 && data.message.includes('record(s) of the table product group')) return message = 'La categoría a eliminar posee grupos asociados';

    if(error === 400 && data.message.includes('record(s) of the table product subgroup')) return message = 'La categoría a eliminar posee sub-grupos asociados';

    return message;

};
