export const revenuesErrors = ({ error, data }) => {
    let message = "";

    if (error === 500) return message = "error";

    if (error === 404 && data.message === 'Error, the revenue with the sent revenue id is not found') return message = 'El ingreso no es válido';

    if (error === 400 && data.message === 'The revenue you want to disable was already disabled') return message = 'La tienda ya está desactivada';
   
    if (error === 400 && data.message === 'The sent shop id does not exist') return message = 'Tienda ingresada no valida';

    if (error === 400 && data.message === 'The date from is greater than the date to') return message = 'Fechas no validas';

    return message;

};
