import React from "react";
import { deleteRecord, disableRecord, getAll } from "../../request/Request";
import { customColumn } from "../../service/dataTable";
import { revenuesErrors } from "../../service/ErrorNotification/revenuesErrors";
import { filterSelectData, selectShop } from "../../service/comboBox";
import { ButtonAdd } from "../ButtonAdd";
import { ComboBox } from "../ComboBox/ComboBox";
import DataTableMaterial from "../DataTableMaterial/DataTableMaterial";
import { HeaderTable } from "../HeaderTable";
import ModalToDelete from "../Modal/ModalToDelete";
import ModalToEnable from "../Modal/ModalToEnable";
import Success from "../Notification/Success";
import Warning from "../Notification/Warning";
import { AddOrEdit } from "./AddOrEdit/AddOrEdit";
import { typeFilterSelect } from "../../service/defaultData";

export class Revenue extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            render: 'table',
            loading: true,
            loadingPageRevenues: false,
            dataTable: {
                data: [],
                perPage: 10,
                total: 0,
                currentPage: 1,
            },
            errorsRequest: {
                errorMessage: '',
                errorStatus: false
            },
            successRequest: {
                successMessage: '',
                successStatus: false
            },
            warningRequest: {
                successMessage: '',
                successStatus: false
            },
            dataToDelete: {
                show: false,
                data: {}
            },
            dataToEnable: {
                show: false,
                data: {}
            },
            revenueSelected: {},
            shops: [],
            shopSelected: {
                value: 0,
                label: ''
            },
            enableAndDisabledShops: [],
            statusPetitionShops: false,
            statusPetition: false
        };
        this.getRowsPerPage = this.getRowsPerPage.bind(this);
        this.actionChange = this.actionChange.bind(this);
        this.getCurrentPage = this.getCurrentPage.bind(this);
        this.disableRevenues = this.disableRevenues.bind(this);
        this.deleteRevenues = this.deleteRevenues.bind(this);
        this.comboBoxChange = this.comboBoxChange.bind(this);
    }

    componentDidMount() {
        this.getAllShop();
        this.setState({ dataToDelete: { show: false, data: {} } });
        this.setState({ dataToEnable: { show: false, data: {} } });
    }

    componentDidUpdate(prevProps, prevState) {
        let { dataTable, render, shopSelected } = this.state;
        if ((prevState.dataTable.perPage !== dataTable.perPage || prevState.dataTable.currentPage !== dataTable.currentPage)
            ||
            (prevState.render !== render && render === 'table')
            || (shopSelected !== prevState.shopSelected)) {
            this.getAllRevenues();
        }
    }

    actionChange(action) {
        if (action === 'add') this.setState({ revenueSelected: {} });
        this.setState({ render: action });
    }

    getCurrentPage(page) {
        let { dataTable, statusPetition } = this.state;
        if (!statusPetition) {
            this.setState({ dataTable: { ...dataTable, currentPage: page } });
        }
    }

    getRowsPerPage(perPage) {
        let { dataTable } = this.state;
        this.setState({ dataTable: { ...dataTable, perPage: perPage } });
    };

    async getAllRevenues() {

        let { dataTable, shopSelected } = this.state;
        if (!dataTable.data?.length) this.setState({ loading: true });

        if (shopSelected.value !== 0) {
            this.setState({ statusPetition: true });
            await getAll(`/revenues?page=${dataTable.currentPage - 1}&perPage=${dataTable.perPage}&idShop=${shopSelected.value}&order=desc&paginated=true`).then(request => {
                let { data } = request;
                this.setState({ dataTable: { ...dataTable, total: data.total, data: data.data } });

            }).catch(error => {
                if (error.error === 404) {
                    this.setState({ dataTable: { ...dataTable, total: 0, data: [] } });
                } else {
                    this.setState({ errorsRequest: { errorMessage: 'Ingresos', errorStatus: true } });
                }
            });
        } else {
            this.setState({ loading: false });
        }
        this.setState({ statusPetition: false });
        this.setState({ loading: false });
    }

    handleEdit(revenue) {
        this.actionChange('edit');
        this.setState({ revenueSelected: revenue });
    }

    handleDelete(revenue) {
        this.setState({ dataToDelete: { show: true, data: { ...revenue, name: this.state.shopSelected.label } } });
    }

    async deleteRevenues(revenue) {

        await deleteRecord(`/revenues/${revenue.id_revenues}`).then(request => {

            let { dataTable } = this.state;
            let revenuesTemp = dataTable.data.filter(x => x.id_revenues !== revenue.id_revenues);
            this.setState({ dataTable: { ...dataTable, total: revenuesTemp.length, data: revenuesTemp } });
            this.successNotifiaction('Ingreso eliminado', true);

        }).catch(error => {
            let respondeError = revenuesErrors(error);
            if (respondeError === 'error') return this.setState({ errorsRequest: { errorMessage: 'Ingresos', errorStatus: true } });
            this.warningNotification(respondeError, true);

        });
        this.getAllRevenues();
        this.setState({ dataToDelete: { show: false, data: {} } });

    }

    handleEnable(revenue) {
        this.setState({ dataToEnable: { show: true, data: { ...revenue, name: this.state.shopSelected.label } } });
    }

    async disableRevenues(revenue) {

        await disableRecord(`/revenues/${revenue.id_revenues}`).then(request => {
            let { dataTable } = this.state;
            dataTable.data.map(function (v) {
                if (v.id_revenues === revenue.id_revenues) v.enable = false;
            });
            this.setState({ ...dataTable, data: dataTable.data });
            this.successNotifiaction('Ingreso desactivado', true);

        }).catch(error => {
            let respondeError = revenuesErrors(error);
            if (respondeError === 'error') return this.setState({ errorsRequest: { errorMessage: 'Ingresos', errorStatus: true } });
            this.warningNotification(respondeError, true);

        });
        this.setState({ dataToEnable: { show: false, data: {} } });

    }

    successNotifiaction(msg, status) {
        this.setState({ ...this.state, successRequest: { successMessage: msg, successStatus: status } });
        setTimeout(() => {
            this.setState({ ...this.state, successRequest: { successMessage: '', successStatus: false } });
        }, 2500);
    }
    warningNotification(msg, status) {
        this.setState({ ...this.state, warningRequest: { successMessage: msg, successStatus: status } });
        setTimeout(() => {
            this.setState({ ...this.state, warningRequest: { successMessage: '', successStatus: false } });
        }, 2500);
    }

    createdOrEditRevenues = (action) => {
        if (action === 'created') {
            this.successNotifiaction('Ingreso agregado', true);
        } else {
            this.successNotifiaction('Ingreso actualizado', true);
        }
    }

    async getAllShop() {
        this.setState({ statusPetitionShops: true });
        const shops = await selectShop();
        if (shops.statusResponse) { 
            let enableAndDisabledShopsTemp = filterSelectData(typeFilterSelect.get, shops.data);
            this.setState({ enableAndDisabledShops: enableAndDisabledShopsTemp });
            this.setState({ shops: shops.data });
            this.setState({ loading: false });
            this.setState({ statusPetitionShops: false });
        } else {
            this.setState({ errorsRequest: { errorMessage: 'Ingresos', errorStatus: true } });
            this.setState({ loading: false });
            this.setState({ statusPetitionShops: false });
        }
    }

    comboBoxChange({ target }) {
        this.setState({ shopSelected: target });
    }

    materialDataTable() {
        let { dataTable, loading, errorsRequest } = this.state;
        let columns = [
            {
                ...customColumn('Ventas', row => row.sales),
                cell: row =>
                    <div style={{ textAlign: "right", minWidth: '100px' }}>
                        {`${Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(row.sales)}`}
                    </div>,
            },
            {
                ...customColumn('Ingreso Variable Neto', row => row.variable_revenues_net),
                cell: row =>
                    <div style={{ textAlign: "right", minWidth: '125px' }}>
                        {`${Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(row.variable_revenues_net)}`}
                    </div>,
            },
            {
                ...customColumn('Ingresos Fijos Netos', row => row.fixed_revenues_net),
                cell: row =>
                    <div style={{ textAlign: "right", minWidth: '125px' }}>
                        {`${Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(row.fixed_revenues_net)}`}
                    </div>,
            },
            {
                ...customColumn('Ingresos Totales Netos', row => row.total_revenues_net),
                cell: row =>
                    <div style={{ textAlign: "right", minWidth: '125px' }}>
                        {`${Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(row.total_revenues_net)}`}
                    </div>,
            },
            {
                ...customColumn('Estado', row => row.enable),
                cell: (row) =>
                    <span onClick={() => { row.enable && this.handleEnable(row) }} className={row.enable ? ` active-status` : 'inactive-status'}> {
                        row.enable ? 'Activo' : 'Inactivo'
                    } </span>
                ,
            },
            {
                ...customColumn('Acciones'),
                cell: (row) => (
                    <>
                        <button className="botton-delete">
                            <svg onClick={() => { this.handleDelete(row) }} className="svg-icon" viewBox="0 0 20 20">
                                <path d="M17.114,3.923h-4.589V2.427c0-0.252-0.207-0.459-0.46-0.459H7.935c-0.252,0-0.459,0.207-0.459,0.459v1.496h-4.59c-0.252,0-0.459,0.205-0.459,0.459c0,0.252,0.207,0.459,0.459,0.459h1.51v12.732c0,0.252,0.207,0.459,0.459,0.459h10.29c0.254,0,0.459-0.207,0.459-0.459V4.841h1.511c0.252,0,0.459-0.207,0.459-0.459C17.573,4.127,17.366,3.923,17.114,3.923M8.394,2.886h3.214v0.918H8.394V2.886z M14.686,17.114H5.314V4.841h9.372V17.114z M12.525,7.306v7.344c0,0.252-0.207,0.459-0.46,0.459s-0.458-0.207-0.458-0.459V7.306c0-0.254,0.205-0.459,0.458-0.459S12.525,7.051,12.525,7.306M8.394,7.306v7.344c0,0.252-0.207,0.459-0.459,0.459s-0.459-0.207-0.459-0.459V7.306c0-0.254,0.207-0.459,0.459-0.459S8.394,7.051,8.394,7.306"></path>
                            </svg>
                        </button>
                        <button className="botton-edit">
                            <svg onClick={() => { this.handleEdit(row) }} className="svg-icon" viewBox="0 0 20 20">
                                <path d="M19.404,6.65l-5.998-5.996c-0.292-0.292-0.765-0.292-1.056,0l-2.22,2.22l-8.311,8.313l-0.003,0.001v0.003l-0.161,0.161c-0.114,0.112-0.187,0.258-0.21,0.417l-1.059,7.051c-0.035,0.233,0.044,0.47,0.21,0.639c0.143,0.14,0.333,0.219,0.528,0.219c0.038,0,0.073-0.003,0.111-0.009l7.054-1.055c0.158-0.025,0.306-0.098,0.417-0.211l8.478-8.476l2.22-2.22C19.695,7.414,19.695,6.941,19.404,6.65z M8.341,16.656l-0.989-0.99l7.258-7.258l0.989,0.99L8.341,16.656z M2.332,15.919l0.411-2.748l4.143,4.143l-2.748,0.41L2.332,15.919z M13.554,7.351L6.296,14.61l-0.849-0.848l7.259-7.258l0.423,0.424L13.554,7.351zM10.658,4.457l0.992,0.99l-7.259,7.258L3.4,11.715L10.658,4.457z M16.656,8.342l-1.517-1.517V6.823h-0.003l-0.951-0.951l-2.471-2.471l1.164-1.164l4.942,4.94L16.656,8.342z"></path>
                            </svg>
                        </button>
                    </>
                ),
            },

        ];
        return <DataTableMaterial
            dataTableData={dataTable}
            loading={loading}
            errors={errorsRequest}
            columns={columns}
            getRowsPerPage={this.getRowsPerPage}
            getCurrentPage={this.getCurrentPage}
        />;
    }

    render() {

        let { render, dataToDelete, revenueSelected, dataToEnable, successRequest, warningRequest, shops, errorsRequest, shopSelected, enableAndDisabledShops, statusPetitionShops} = this.state

        return (
            <div className="col-lg-12 mt-5">
                {
                    render === 'add' || render === 'edit' ?
                        <AddOrEdit shops={shops} shopSelected={shopSelected} render={render} revenue={revenueSelected} actionChange={this.actionChange} createdOrEditRevenues={this.createdOrEditRevenues} /> :
                        <div className="card-table">
                            <HeaderTable title={'Ingresos'} />
                            <ModalToEnable record={dataToEnable} disableRecord={this.disableRevenues} />
                            <ModalToDelete record={dataToDelete} deleteRecord={this.deleteRevenues} />
                            {warningRequest.successStatus && <Warning text={warningRequest.successMessage} />}
                            {successRequest.successStatus && <Success text={successRequest.successMessage} />}

                            {!errorsRequest.errorStatus &&
                                <div className="row mt-3 container-search-button">
                                    <div className="col-md-4 pl-0" >
                                        <ComboBox 
                                        name={"id_shop"} 
                                        text={"Seleccionar Tienda"}
                                            options={enableAndDisabledShops}
                                            onChange={this.comboBoxChange}
                                            defaultValueSelect={shopSelected}
                                                isLoading={statusPetitionShops}
                                            />
                                    </div>
                                    <div>
                                        <ButtonAdd actionChange={this.actionChange} />
                                    </div>
                                </div>}

                            <div className="mb-5 mt-5">
                                {this.materialDataTable()}
                            </div>

                        </div>
                }
            </div>
        )
    }

}