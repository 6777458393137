export const identificationTypeErrors = ({ error, data }) => {

    let message = "";

    if (error === 500) return message = "error";

    if (error === 404) return message = 'La identificación ingresada no es válida';

    if (error === 400 && data.message === 'A parameter already exists with the submitted id identification type') return message = 'La identificación ingresado ya existe';

    if (error === 400 && data.message === 'A parameter already exists with the submitted id identification type and description value') return message = 'El tipo de identificación y descripción ya existen';

    if (error === 400 && data.message === 'A parameter already exists with the submitted id identification type or description value') return message = 'El tipo de identificación o descripción ya existen';
   
    if (error === 400 && data.message === 'The identification type you want to disable was already disabled') return message = 'La identificación ingresada ya está desactivada';

    if (error === 400 && data.message === 'A identification type already exists with the description') return message = 'La descripción ingresada ya existe';

    if(error === 400 && data.message.includes('record(s) of the table sender')) return message = 'La identificación a eliminar posee remitentes asociados';

    return message;
    
}   