// Home.js
import { Route } from 'react-router-dom';
import Login from '../Login/Login';
import { connect } from 'react-redux'
import { Dashboard } from './Dashboard';
const mapStateToProps = function (state) {
    return { session: state.session }
  }
function Home(props) {
    return (
        <Route path="/" component={props.session.isLoggedIn === true ? Dashboard : Login} />
    );
};
export default connect(mapStateToProps)(Home);

