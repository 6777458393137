export default function validate(values){
    
    let errors = {};

    if(!values.id_voucher_type){
        errors.id_voucher_type = '*El tipo es requerido';
    }
    if(!values.description){
        errors.description = '*La descripción es requerida';
    }
    if(values.enable === undefined){
        errors.enable = '*El estado es requerido'
    }

    return errors;
}