import React, { useEffect, useState } from "react";
import { itemsModalShop } from "../../service/defaultData";
import { RevenueSection } from "../Revenue/RevenueSection";
import { ShopSection } from "../Shop/ShopSection";
import { ScheduleSection } from "../ShopSchedule/ScheduleSection";
import './modal.css';

export const ModalShop = (props) => {

    const [show, setShow] = useState(false);
    const [shop, setShop] = useState(null);
    const [defaultAgent, setDefaultAgent] = useState(null);
    const [defaultSender, setDefaultSender] = useState(null);
    const [page, setPage] = useState('');

    useEffect(() => {
        setPage('info');
    },[]);

    useEffect(() => {
        setShop(props.shop.data);
        if ( props.defaultAgent !== defaultAgent && props.defaultSender !== defaultSender) {
            setDefaultAgent(props.defaultAgent);
            setDefaultSender(props.defaultSender);
        }
        props.shop.show !== show && setShow(true);
    }, [props]);

    const ChangeSection = (page) => setPage(page);

    const closeModal = () => {
        props.closeSeeShop()
        setShow(false)
    };

    return (
        <>
            {
                show && (
                    <div className="Modal" style={{ display: 'block' }} aria-hidden="true" >
                        <div className="modal-dialog modal-xl " >
                            <div className="modal-content modal-invoice " >
                                <div className="modal-body container-modal-shop">

                                    <div className="col-12" >
                                        <div className="card text-center shadow p-3 mb-5 bg-body rounded" id="container-section" >
                                            <div className="card-header">
                                                <ul className="nav nav-tabs card-header-tabs">
                                                    {itemsModalShop.map((item, index) => (
                                                        <li key={index} className="nav-item cursor-pointer" onClick={() => { ChangeSection(`${item.path}`) }} >
                                                            <a style={{ color: 'black' }} className={`nav-link  ${page === `${item.path}` && 'active'}`} aria-current="true"> {item.title} </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <div id="sections">
                                                {
                                                    page === 'info' ? <div className="card-body"  ><ShopSection defaultAgent={defaultAgent} defaultSender={defaultSender} shop={shop} /></div> :
                                                        page === 'revenues' ? <div className="card-body"> <RevenueSection shop={shop}/> </div>
                                                            : page === 'schedule' && <div className="card-body"> <ScheduleSection shop={shop}/> </div>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button onClick={() => { closeModal() }} type="button" className="btn" id="btn-cancel">Cancelar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}
