import React from 'react'
import moment from 'moment';

export default class ModalInvoice extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            data: ''
        };


    }

    componentDidUpdate(prevProps, prevState) {
        let { dataInvoice } = this.props;
        if (prevProps.dataInvoice !== dataInvoice) {
            this.setState({ show: dataInvoice.show, data: dataInvoice.data })
        }
    }


    render() {

        let { show } = this.state;
        let { dataInvoice } = this.props;

        return (
            <>
                {
                    show && (
                        <div className="Modal test" style={{ display: 'block' }} aria-hidden="true"  >
                            <div className="modal-dialog modal-lg container-modal-invoice">
                                <div className="modal-content modal-invoice"   >
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Detalle de Factura </h5>
                                    </div>
                                    <div className="modal-body scroll-modal">
                                        <div className='col-12'>
                                            <div className="row">
                                                <div className="col-12 text-right">
                                                    <strong> {moment(dataInvoice.data.date_issue).format('DD/MM/YYYY hh:mm')} </strong>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">

                                                    <h4 className="shop-name"> {dataInvoice.data.shop_name} </h4>

                                                    <div className="numbers-document">
                                                        <span>Número del Documento: {dataInvoice.data.document_number} </span>
                                                        <br />
                                                        <span>Número de Consecutivo:  {dataInvoice.data.consecutive_number} </span>
                                                    </div>

                                                    <div className="table-responsive">
                                                        <table className="table table-hover">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">#</th>
                                                                    <th scope="col">Código</th>
                                                                    <th scope="col">Nombre</th>
                                                                    <th scope="col">Cantidad</th>
                                                                    <th scope='col'>Moneda</th>
                                                                    <th scope="col">Precio</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    dataInvoice.data.invoice_products.map((prod, index) => (
                                                                        <tr key={index}>
                                                                            <td > {index + 1} </td>
                                                                            <td> {prod.product_code} </td>
                                                                            <td> {prod.product_name} </td>
                                                                            <td style={{ paddingLeft: '25px' }}> {prod.quantity} </td>
                                                                            <td>{dataInvoice.data.currency_code}</td>
                                                                            <td>{`${Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(prod.unit_price)}`}</td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    
                                                    <div className="col-12 text-right mb-4 mt-2">
                                                        <div className="row">
                                                            <div className="col mr-4">
                                                                <strong className="amounts">Venta Total:  </strong>
                                                                <br />
                                                            </div>
                                                            <div className="text-amounts" style={{ marginLeft: '11px' }}>
                                                                <span> {`${Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(dataInvoice.data.total_check)} ${dataInvoice.data.currency_code}`} </span>
                                                                <br />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />

                                                    <div className="col-12 text-right mb-4 mt-2">
                                                        <div className="row">
                                                            <div className="col mr-4">
                                                                <strong className="amounts">Impuestos:   </strong>
                                                                <br />
                                                                <strong className="amounts">Venta Neta:  </strong>
                                                                <br />
                                                                <strong className="amounts">Otros cargos:  </strong>
                                                            </div>
                                                            <div className="text-amounts" style={{ marginLeft: '11px' }}>
                                                                <span> {`${Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(dataInvoice.data.total_tax)} ${dataInvoice.data.currency_code}`} </span>
                                                                <br />
                                                                <span> {`${Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(dataInvoice.data.total_net)} ${dataInvoice.data.currency_code}`} </span>
                                                                <br />
                                                                <span> {`${Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(dataInvoice.data.total_other_charges)} ${dataInvoice.data.currency_code}`} </span>

                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button onClick={() => this.setState({ ...dataInvoice, show: false })} type="button" className="btn" id="btn-cancel">Cancelar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </>
        );

    }

}
