export const productErrors = ({ error, data }) => {
    let message = "";

    if (error === 500) return message = "error";

    if (error === 404 && data.message === "The sent agent does not exist") return message = "El agentes seleccionado no existe";

    if (error === 404 && data.message === "The product with the sent id is not found") return message = "El producto enviado no existe";
 
    if (error === 400 && data.message === "The product you want to disable was already disabled") return message = "El producto enviado está desctivado";

    if (error === 400 && data.message === "The product must have a category associated") return message = "El producto debe tener una categoría asociada";

    if (error === 400 && data.message === "The product must have a group associated") return message = "El producto debe tener un grupo asociado";

    if (error === 400 && data.message === "The product must have a subgroup associated") return message = "El producto debe tener un subgrupo asociado";
   
    if (error === 400 && data.message === "The sent agent is not enabled") return message = "El agente enviado está desactivado";

    if (error === 400 && data.message === "The category sent is not related to the agent") return message = "La categoría enviada no tiene relación con el agente";

    if (error === 400 && data.message === "Please contact the site administrator since the category you want to insert is disabled") return message = "La categoría enviada está desactivada";

    if (error === 400 && data.message === "The group sent is not related to the agent") return message = "El grupo enviado no tiene relación con el agente";

    if (error === 400 && data.message === "Please contact the site administrator since the group you want to insert is disabled") return message = "El grupo enviado está desactivado";

    if (error === 400 && data.message === "The subgroup sent is not related to the agent") return message = "El sub-grupo enviado no tiene relación con el agente";

    if (error === 400 && data.message === "Please contact the site administrator since the subgroup you want to insert is disabled") return message = "El sub-grupo enviado está desactivado";

    if (error === 400 && data.message === "There is already a record with the selected agent, category, group, subgroup, product code and name") return message = "Ya existe un registro con el agente seleccionado, categoría, grupo, subgrupo, código de producto y nombre";
     
    return message;

};