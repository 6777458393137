export const shopScheduleErrors = ({ error, data }) => {
    let message = "";

    if (error === 500) return message = "error";

    if (error === 404 && data.message === 'Error, the shop schedule with the sent id is not founds') return message  ='Horario no válido';

    if (error === 404 && data.message === 'Error, the shop schedule with the sent id is not found') return message  ='Horario no válido';

    if (error === 400 && data.message === 'The shop schedule you want to disable was already disabled') return message = 'El horario ya está desactivado';
    
    if (error === 400 && data.message === 'A shop schedule already exists with the id shop schedule, id shop, day and status') return message = 'Los datos registrados ya existen';

    if (error === 400 && data.message === 'Please contact the site administrator since the shop you want to insert is disabled') return message = 'La tienda enviada está desactivada';

    if (error === 400 && data.message === 'A parameter already exists with the submitted id shop, day and status') return message = 'Los datos ya existen';

    if (error === 400 && data.message === 'Please check the day you are sending as it does not comply with the valid format') return message = 'El día no es válido';

    if (error === 400 && data.message === 'The allowed hours are from 0 hours to 23 hours') return message = 'Las horas ingresadas no son validas';

    if (error === 400 && data.message === 'The allowed minutes are from 0 minutes to 59 minutes') return message = 'Los minutos ingresados no son validos';
  
    if (error === 400 && data.message === 'The shop id does not exist') return message = 'La tienda ingresada no es válida';
 

    return message;

};
