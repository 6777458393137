import React, { useEffect, useState } from "react";
import "./Login.css";
import { generateCodeVerifier, generateCodeChallenge } from '../../service/login';
import cognitoUtils from "../../lib/cognitoUtils";
 
 const Login = (props) => {

  const [code_verifier, setcode_verifier] = useState(null);
  const [code_challenge, setCode_challenge] = useState(null);

  useEffect(() => {
    let codeVerifier = generateCodeVerifier();  
    setcode_verifier(codeVerifier);
  },[]);

  useEffect(() => {
    if (code_verifier) {
      (async function () {
        let codeChallenge = await generateCodeChallenge(code_verifier); 
        setCode_challenge(codeChallenge);
      })(); 
    }
  }, [code_verifier])
  return code_challenge &&
    <section className="vh-100">
      <section className="vh-100">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-8 px-0 d-none d-sm-block">
              <img src="./fondo-login.jpg"
                alt="Login image" className="w-100 vh-100 image-login"></img>
            </div>
            <div className="col-sm-4 text-black">
              <div className="d-flex align-items-center h-custom-2 px-5 ms-xl-4 mt-5 pt-5 pt-xl-4 mt-xl-n5">
                <div className="container-login">
                  <div className="mt-5">
                    <img height="100%" width="95%" src="./logo-slogan-es.png" alt="..."></img>
                  </div>
                  <div className="pt-1 mb-4">
                  <a  className="btn btn-info btn-lg btn-block bg-button" href={cognitoUtils.getCognitoSignInUri()}>Iniciar Sesión</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
}
export default Login;