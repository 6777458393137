import React, { useState } from 'react';
import { sftpDownload } from '../../request/Request';
import { typeFileReportUpload } from '../../service/defaultData';
import validate from '../../validate-forms/Report/UploadReportFormValidationRules';
import useForm from '../../validate-forms/useForm';
import { ComboBox } from '../ComboBox/ComboBox';
import { CustomDatePicker } from '../CustomDatePicker/CustomDatePicker';
import { ErrorPage } from '../ErrorPage/ErrorPage';
import Success from '../Notification/Success';
import './Report.css';
import moment from 'moment';

export const Upload = () => {

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [successRequest, setSuccessRequest] = useState({ successMessage: '', successStatus: false });
    const { values, errors, handleChange, handleSubmit } = useForm(sendForm, validate);

    async function sendForm() {

        values.frequency = 'weekly';
        setLoading(true);
        setSuccessRequest({ successMessage: '', successStatus: false });

        let dateIssueStartTemp = moment(values.startDate).format('YYYY-MM-DDTHH:mm:ss');
        let dateIssueEndTemp = moment(values.endDate).format('YYYY-MM-DDTHH:mm:ss');

        values.startDate = dateIssueStartTemp;
        values.endDate = dateIssueEndTemp;

        setLoading(true);
        await sftpDownload(`/sftp/upload`, values).then(request => {
            if (request.statusResponse) {
                setLoading(false);
                setSuccessRequest({ successMessage: 'Archivo cargado con éxito', successStatus: true });
            }
        }).catch(error => {
            setErrorMessage(true);
            setLoading(false);
        });
    }

    const comboBoxChange = (e) => handleChange(e);

    const datePickerChange = (e) => handleChange(e);

    const handleStartDate = (date) => values.startDate = date;

    const handleEndDate = (date) => values.endDate = date;

    return errorMessage ? <ErrorPage name={'Reportes'} /> : <div className="container-dates-report">

        <form onSubmit={handleSubmit} noValidate>
            <div className="period-name-report"><h5>Generar de Archivo </h5></div>

            {successRequest.successStatus && <Success text={successRequest.successMessage} />}

            <div className="row">
                <div className="col-md-4">
                    <div className="form-group">
                        <CustomDatePicker type={'date-time'} onChange={datePickerChange} name='startDate' placeHolder={'dd/mm/aaaa --:--'} getDate={handleStartDate} title={'Fecha de Inicio'} />
                        {errors.startDate && (
                            <p className="help is-danger">{errors.startDate}</p>
                        )}
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <CustomDatePicker type={'date-time'} onChange={datePickerChange} name='endDate' placeHolder={'dd/mm/aaaa --:--'} getDate={handleEndDate} title={'Fecha Final'} />
                        {errors.endDate && (
                            <p className="help is-danger">{errors.endDate}</p>
                        )}
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group coriport-input">
                        <ComboBox name={"fileType"} text={"Tipo de Reporte"} options={typeFileReportUpload} onChange={comboBoxChange} />
                        {errors.fileType && (
                            <p className="help is-danger">{errors.fileType}</p>
                        )}
                    </div>
                </div>
            </div>

            <div className="container-bottons-report">

                <button disabled={loading} className="btn btn-coriport btn-add text-white btn-download" type="submit">
                    {
                        loading ? <div className="container-spinner-loading pt-2">
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </div> : 'Cargar'
                    }
                </button>

            </div>
        </form>

    </div>
}
