import React from "react";
import "./SideNavBar.css";
import { dataSidebar } from "../../service/Sidebar";
export class SideNavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: '',
      activeSubItem: '',
      itemExpand: {
        module: '',
        expand: false
      }
    }
    this.handleSubItemPage = this.handleSubItemPage.bind(this);

  } 

  componentDidMount() {
    this.props.funtionChangePage('Monitoring');
    this.setState({activeItem: 'Monitoring'})
  }
  changePage({ item, path, expand }) {
    if (!expand) {
      this.setState({ itemExpand: { module: '', expand: false } });
    }
    if (this.state.itemExpand.module === path && this.state.itemExpand.expand === expand) {
      this.setState({ itemExpand: { module: '', expand: false } });
    } else {
      if (item) {
        this.setState({ activeSubItem: '' });
        this.setState({ activeItem: path })
      }

      if (expand) {
        this.setState({ itemExpand: { module: path, expand: true } });
      }

      if (path !== 'InvoiceModule' && path !== 'ShopModule' && path !== 'ProductModule') {
        this.props.funtionChangePage(path);
      }

    }

  }

  handleSubItemPage({ path }) {
    this.setState({ activeSubItem: path });
    this.props.funtionChangePage(path);
  }


  render() {

    return (
      <div id="sidebar-wrapper">
        <ul id="nav" className="sidebar-nav">

          <li className="sidebar-brand"  >
            <img src="logo-slogan-es.png" alt="" width="80%" height="96%" />
          </li>

          <ul className="nav flex-column">

            {
              dataSidebar.map((item, index) => (

                <div key={index}>
                  <li id='container-item' className={"item" + (this.state.activeItem === item.path && 'sidebar-item active')} >
                    <a onClick={() => this.changePage(item)} className="nav-link pl-1">
                      {(this.state.itemExpand.module === item.path && this.state.itemExpand.expand === item.expand) ? item.iconDown : item.iconUp}
                      {!item.expand && item.icon}
                      <span className="label-item" > {item.name} </span>
                    </a>
                  </li>
                  {

                    (item.subItem) && item.subItem.map((subItem, index) => (
                      <div key={index} className='sub-item' >
                        {
                          (this.state.itemExpand.module === item.path && this.state.itemExpand.expand === item.expand) && (
                            <ul key={index} className='container-sub-item'>
                              <li
                                className={"item" + (this.state.activeSubItem === subItem.path && 'sidebar-item active')}
                                key={index}
                                id='sub-item container-item'
                                onClick={() => this.handleSubItemPage(subItem)}>
                                <a className="nav-link" id="option pl-0" > {subItem.icon} {subItem.name} </a>
                              </li>
                            </ul>
                          )
                        }
                      </div>
                    ))

                  }

                </div>

              ))

            }

          </ul>

        </ul>
      </div>
    );
  }
}