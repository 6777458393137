export const voucherTypeErrors = ({ error, data }) => {
    let message = "";

    if (error === 500) return message = "error";

    if (error === 404 && data.message === 'Error, the voucher type with the sent voucher id is not found') return message = "El tipo de documento ingresado no es válido";

    if (error === 404 && data.message === 'Error, the voucher with the sent voucher id is not found') return message = "El tipo de documento ingresado no es válido";

    if (error === 400 && data.message === "A voucher type with the given description already exists") return message = "La descripción ingresada ya existe";

    if (error === 400 && data.message === "A voucher type already exists with the submitted id") return message = "El tipo de documento ingresado ya existe";

    if (error === 400 && data.message === "A voucher type already exists with the submitted description") return message = "La descripción ingresada ya existe";

    if(error === 400 && data.message.includes('The voucher type id cannot be deleted as it is associated in')) return message = 'El tipo de documento a eliminar posee facturas asociadas'
    
    if(error === 400 && data.message === 'The voucher type you want to disable was already disabled') return message = 'El tipo de documento ingresado ya está desactivado'
    
    return message;

};
