import React, { useEffect, useState } from "react";
import useForm from "../../../validate-forms/useForm";
import validate from "../../../validate-forms/ProductCategory/AddFormValidationRules";
import { Loading } from "../../Loading/Loading";
import { ErrorPage } from "../../ErrorPage/ErrorPage";
import './AddOrEdit.css';
import { ComboBox } from "../../ComboBox/ComboBox";
import { createRecord, editRecord } from "../../../request/Request";
import { filterSelectData, getDefaultOptionSelect, selectProductCategoryVinci } from "../../../service/comboBox";
import Warning from "../../Notification/Warning";
import { productCategoryErrors } from "../../../service/ErrorNotification/productCategoryErrors";
import { selectEnable, typeFilterSelect } from "../../../service/defaultData";

export const AddOrEdit = ({ actionChange, createdOrEditProductCategory, productCategory, render, agents }) => {

    const [errorMessage, setErrorMessage] = useState(false);
    const [loading, setLoading] = useState(true);

    const [defaultEnable, setDefaultEnable] = useState(null);

    const [optionAgentDefault, setOptionAgenteDefault] = useState(null);
    const [filteredAgents, setFilterAgents] = useState([]);

    const [productCategoriesVinci, setProductCategoriesVinci] = useState([]);
    const [defaultCategoryVinci, setDefaultCategoryVinci] = useState(null);
    const [productCategoriesVinciFiltered, setProductCategoriesVinciFiltered] = useState([]);

    const [statusButton, setStatusButton] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');

    const { values, errors, handleChange, handleSubmit } = useForm(sendForm, validate);


    const [statusPetitionProductCategoriesVinci, setStatusPetitionProductCategoriesVinci] = useState(false);

    useEffect(() => {
        (async function () {

            setStatusPetitionProductCategoriesVinci(true);
            const productCategories = await selectProductCategoryVinci();

            if (productCategories.statusResponse) {
                setStatusPetitionProductCategoriesVinci(false);
                setProductCategoriesVinci(productCategories.data);
            } else {
                setLoading(false);
            }
        })();
    }, []);



    useEffect(() => {
        if (render === 'add') {
            let enabledAgents = filterSelectData(typeFilterSelect.add, agents);
            setFilterAgents(enabledAgents);

            if (productCategoriesVinci.length > 0) {
                let enabledProductCategoriesVinci = filterSelectData(typeFilterSelect.add, productCategoriesVinci);
                setProductCategoriesVinciFiltered(enabledProductCategoriesVinci);
            }
            setLoading(false);
        }
        else {

            let defaultAgentTemp = getDefaultOptionSelect(agents, productCategory.id_agent);
            (defaultAgentTemp.length > 0) ? setOptionAgenteDefault(defaultAgentTemp[0]) : setOptionAgenteDefault([]);

            let enableAndDisabledAgents = filterSelectData(typeFilterSelect.edit, agents, defaultAgentTemp);
            setFilterAgents(enableAndDisabledAgents);

            let defaultCategoryVinciTemp = getDefaultOptionSelect(productCategoriesVinci, productCategory.id_vinci_product_category);
            setDefaultCategoryVinci(defaultCategoryVinciTemp[0]);

            let enabledCategoriesVinci = filterSelectData(typeFilterSelect.edit, productCategoriesVinci, defaultCategoryVinciTemp);
            setProductCategoriesVinciFiltered(enabledCategoriesVinci);

            let defaultEnableTemp = getDefaultOptionSelect(selectEnable, productCategory.enable);
            setDefaultEnable(defaultEnableTemp[0]);

            values.id_vinci_product_category = productCategory.id_vinci_product_category;
            values.category_name = productCategory.category_name;
            values.category_code = productCategory.category_code;
            values.id_agent = productCategory.id_agent;
            values.enable = productCategory.enable;


        }
    }, [productCategoriesVinci]);

    useEffect(() => {

        if (optionAgentDefault && defaultCategoryVinci && defaultEnable && render === 'edit') {
            setLoading(false);
        }

    }, [optionAgentDefault, defaultCategoryVinci, defaultEnable])


    async function sendForm() {
        setStatusButton(true);
        setWarningMessage('');
        if (render === 'add') {
            await createRecord(`/productcategory`, values).then(request => {
                setStatusButton(false);
                if (request.statusResponse) {
                    redirect('created');
                }
            }).catch(error => getErrors(error));
        } else {
            await editRecord(`/productcategory/${productCategory.id_product_category}`, values).then(request => {
                setStatusButton(false);
                if (request.statusResponse) {
                    redirect('edit');
                }
            }).catch(error => getErrors(error));
        }
    }

    const redirect = (action) => {
        createdOrEditProductCategory(action);
        actionChange('table');
    }

    const getErrors = (error) => {

        setWarningMessage('');
        setErrorMessage(false);

        let respondeError = productCategoryErrors(error);

        if (respondeError === 'error') return setErrorMessage(true);

        setWarningMessage(respondeError);
        setStatusButton(false);

    }

    function comboBoxChange(e) {
        handleChange(e);
    }

    return loading ? <Loading /> : errorMessage ? <ErrorPage redirect={true} actionChange={actionChange} name={'Categoría de Productos'} /> :
        <section className="section form-color">
            <form onSubmit={handleSubmit} noValidate>
                <div className="acction-card"><span aria-hidden="true">
                    {render === 'add' ? 'Agregar ' : 'Actualizar '} Categoría</span>
                </div>

                {warningMessage !== '' && <Warning text={warningMessage} />}

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group coriport-input">
                            <label htmlFor="category_name" className="form-label">Nombre de la Categoría</label>
                            <input
                                type="text"
                                className={`input-form form-control ${errors.category_name && 'is-danger'}`}
                                placeholder="Nombre de Categoría"
                                name="category_name"
                                onChange={handleChange}
                                value={values.category_name || ''}
                                required
                            />
                            {errors.category_name && (<p className="help is-danger">{errors.category_name}</p>)}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group coriport-input">
                            <label htmlFor="category_code" className="form-label">Código de la Categoría</label>
                            <input
                                type="text"
                                className={`input-form form-control`}
                                placeholder="Código de Categoría"
                                name="category_code"
                                onChange={handleChange}
                                value={values.category_code || ''}
                                required
                            />
                            {errors.category_code && (<p className="help is-danger">{errors.category_code}</p>)}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group coriport-input">
                            <ComboBox
                                name={"id_agent"}
                                text={"Agente"}
                                options={filteredAgents}
                                onChange={comboBoxChange}
                                defaultValueSelect={optionAgentDefault}
                            />
                            {errors.id_agent && (<p className="help is-danger">{errors.id_agent}</p>)}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group coriport-input">
                            <ComboBox
                                name={"id_vinci_product_category"}
                                text={"Categoría de Productos Vinci"}
                                options={productCategoriesVinciFiltered}
                                onChange={comboBoxChange}
                                isLoading={statusPetitionProductCategoriesVinci}
                                defaultValueSelect={defaultCategoryVinci}
                            />
                            {errors.id_vinci_product_category && (<p className="help is-danger">{errors.id_vinci_product_category}</p>)}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group coriport-input">
                            <ComboBox
                                name={"enable"}
                                text={"Estado"}
                                options={selectEnable}
                                onChange={comboBoxChange}
                                defaultValueSelect={defaultEnable}
                            />
                            {errors.enable && (<p className="help is-danger">{errors.enable}</p>)}
                        </div>
                    </div>
                </div>

                <div className="container-bottons">
                    <button disabled={statusButton} id='custom-btn-coriport' className=" btn  btn-coriport btn-add text-white " type="submit">
                        {
                            statusButton ? <div className="container-spinner-loading pt-2">
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </div> : <p className="label-button-add"> {render == 'add' ? 'Agregar' : 'Actualizar'}</p>
                        }
                    </button>
                    <button className="btn-coriport btn-cancel" onClick={() => actionChange('table')}>
                        Cancelar
                    </button>
                </div>

            </form>
        </section>

}