import React from "react";
import "./Content.css";
import { Agent } from "../Agent/Agent";
import { Monitor } from "../Monitor/Monitor";
import { Product } from "../Product/Product";
import { Shop } from "../Shop/Shop";
import { VoucherType } from "../VoucherType";
import { Parameter } from "../Parameter/Parameter";
import { IdentificationType } from "../IdentificationType/IdentificationType";
import { Revenue } from "../Revenue/Revenue";
import { Currency } from "../Currency/Currency";
import { Invoice } from "../Invoice/Invoice";
import { ShopSchedule } from "../ShopSchedule/ShopSchedule";
import { ProductSubGroupVinci } from "../ProductSubGroupVinci/ProductSubGroupVinci";
import { ProductGroupVinci } from "../ProductGroupVinci/ProductGroupVinci";
import { ProductCategoryVinci } from "../ProductCategoryVinci/ProductCategoryVinci";
import { Sender } from "../Sender/Sender";
import { ProductCategory } from "../ProductCategory/ProductCategory";
import { ProductGroup } from "../ProductGroup";
import { Report } from "../Report/Report";
import { ProductSubGroup } from "../ProductSubGroup/ProductSubGroup";  

export class Content extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      page: ''
    };
  }

  conditionalRender = () => {
    const { action } = this.props;
    let page = null;
    switch (action) {

      case "Agent":
        page = <Agent action={action} />;
        break;
      case "Product":
        page = <Product />;
        break;
      case "Shop":
        page = <Shop />;
        break;
      case "Schedule":
        page = <ShopSchedule />;
        break;
      case "Invoice":
        page = <Invoice />;
        break;
      case "Parameter":
        page = <Parameter />;
        break;
      case "Voucher":
        page = <VoucherType />;
        break;
      case "Identification":
        page = <IdentificationType />;
        break;
      case "Monitoring":
        page = <Monitor />;
        break;
      case "Currency":
        page = <Currency />;
        break;
      case "Category-Vinci":
        page = <ProductCategoryVinci />;
        break;
      case "Group-Vinci":
        page = <ProductGroupVinci />;
        break;
      case "Sub-Group-Vinci":
        page = <ProductSubGroupVinci />;
        break;
      case "Sender":
        page = <Sender />;
        break;
      case "Product-Category":
        page = <ProductCategory />;
        break;
      case "Product-Group":
        page = <ProductGroup />;
        break;
      case "Report":
        page = <Report />;
        break;
      case "Product-Sub-Group":
        page = <ProductSubGroup />;
        break;
      case "Revenues":
        page = <Revenue />;
        break;

      default:
        break;
    }
    return <>{page}</>;
  };

  render() {
    return (
      <div id="page-content-wrapper">
        <div className="container-fluid">
          <main className="main">
            <div className="main_cards">
              <div className="card"> {this.conditionalRender()}</div>
            </div>
          </main>
        </div>
      </div>
    );
  }
}
