export const senderErrors = ({ error, data }) => {
    let message = "";

    if (error === 500) return message = "error";

    if (error === 404) return message = 'Remitente no válido';
 
    if (error === 400 && data.message === 'The sender you want to disable was already disabled') return message = 'El remitente ya está desactivado';
    
    if (error === 400 && data.message === 'A sender already exists with the id sender and id identification type') return message = 'El remitente y identificación ya existe';

    if (error === 400 && data.message === 'The identification type id does not exist') return message = 'El tipo de identificación ingresada no existe';
 
    if (error === 400 && data.message === 'A parameter already exists with the submitted id sender') return message = 'La identificación del remitente ya existe';

    if (error === 400 && data.message === 'A parameter already exists with the submitted id sender and id identification type') return message = 'El tipo de identificación y remitente ya existen';
 
    if (error === 400 && data.message === 'Please contact the site administrator since the identification type you want to insert is disabled') return message = 'El tipo de identificación está desactivada';

    if(error === 400 && data.message.includes('record(s) of the table shop')) return message = 'El remitente posee tiendas asociadas';

    return message;

};