import React from "react";
import "./Header.css";
import Status from "../Accounts/Status";
export class Header extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { funtionToggleSideNav } = this.props;
    return (
      <header className="header">
        <div className="header_title">
          <a className="btn btn-default" id="menu-toggle" onClick={funtionToggleSideNav} >
            <span className="header__menu-icon-color">
              <svg viewBox="0 0 100 80" width="25" height="25">
                <rect width="100" height="20"></rect>
                <rect y="30" width="100" height="20"></rect>
                <rect y="60" width="100" height="20"></rect>
              </svg>
            </span>
          </a>
          <span className="header__span">Consola Administrativa </span>
        </div>
        <Status/>
      </header>
    );
  }
}
