export default function validate(values) {

    let errors = {};
    let currentYear = new Date().getFullYear();
    
    if (values.documentNumber < 0) {  
        errors.documentNumber = '*Verificar número ingresado';
    }
    if (values.invoiceYear < 0 || values.invoiceYear > currentYear) {
        errors.invoiceYear = '*Verificar año';
    } 
    if (values.dateIssueEnd < values.dateIssueStart) {  
        errors.dateIssueEnd = '*Verificar fecha final';
    }
    if (!values.idShop) {
        errors.idShop = '*La tienda es requerida';
    }
    if (values.invoiceMonth && !values.invoiceYear) {
        errors.invoiceYear = '*El año es requerido';
    }
    if (values.dateIssueEnd && !values.dateIssueStart) {
        errors.dateIssueStart = '*Fecha inicial requerida';
    }
    if (!values.dateIssueEnd && values.dateIssueStart) {
        errors.dateIssueEnd = '*Fecha final requerida';
    }

 
    return errors;
}