import React from 'react'

export default class ModalProduct extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            data: ''
        };
    }
    componentDidUpdate(prevProps, prevState) {
        let { dataProduct } = this.props;
        if (prevProps.dataProduct !== dataProduct) {
            this.setState({ show: dataProduct.show, data: dataProduct.data });
        }
    } 
    render() {

        let { show } = this.state;
        let { dataProduct } = this.props;

        return (
            <>
                {
                    show && (
                        <div className="Modal" style={{ display: 'block' }} aria-hidden="true" >
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="card" style={{ padding: '5px' }}>
                                    <h5 className="card-header">Nombre: {dataProduct.data.product_name} </h5>
                                        <div className="card-body">
                                            <h5 className="card-title">Agente: {dataProduct.data.id_agent}</h5>
                                            <p className="card-text">Código: {dataProduct.data.product_code}</p>
                                            <div className='text-right'>
                                                <button onClick={() => this.setState({ ...dataProduct, show: false })} type="button" className="btn" id="btn-cancel">Cancelar</button>
                                            </div>                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </>
        );

    }

}
