import { getAll } from "../request/Request";
import { groupBadgeStyles, groupStyles, typeFilterSelect } from "./defaultData";

export const selectVoucherType = async () => {
    let response = null;
    await getAll(`/vouchertype?page=0&perPage=10&&order=desc&paginated=false`)
        .then((request) => {
            if (request.statusResponse) {
                response = request;
                const data = request.data.data;
                response.data = data.map((record) => ({
                    value: record.id_voucher_type,
                    label: record.description,
                }));
            }
        })
        .catch((error) => (response = error));
    return response;
};

export const selectProductCategory = async (id_agent) => {
    let response = null;
    await getAll(`/productcategory?page=0&perPage=10&idAgent=${id_agent}&order=desc&paginated=false`)
        .then((request) => {
            if (request.statusResponse) {
                response = request;
                const data = request.data.data;
                response.data = data.map((record) => ({
                    value: record.id_product_category,
                    label: record.category_name,
                    active: record.enable,
                    id_agent: record.id_agent,
                    id_vinci_product_category: record.id_vinci_product_category
                }));
            }
        })
        .catch((error) => (response = error));
    return response;
};

export const selectProductCategoryVinci = async () => {
    let response = null;
    await getAll(`/vinciproductcategory?page=0&perPage=10&order=desc&paginated=false`).then((request) => {
        if (request.statusResponse) {
            response = request;
            const data = request.data.data;
            response.data = data.map((record) => ({
                value: record.id_vinci_product_category,
                label: record.category_name,
                active: record.enable
            }));
        }
    })
        .catch((error) => (response = error));
    return response;
};

export const selectProductGroup = async (id_agent, id_product_category) => {
    let response = null;
    await getAll(`/productgroup?page=0&perPage=10&idAgent=${id_agent}&idProductCategory=${id_product_category}&order=desc&paginated=false`).then((request) => {
        if (request.statusResponse) {
            response = request;
            const data = request.data.data;
            response.data = data.map((record) => ({
                value: record.id_product_group,
                label: record.group_name,
                id_agent: record.id_agent,
                active: record.enable,
                id_product_category: record?.id_product_category,
                id_vinci_product_group: record?.id_vinci_product_group
            }));
        }
    })
        .catch((error) => (response = error));
    return response;
};

export const selectProductGroupVinci = async (id_vinci_product_category) => {
    let response = null;
    await getAll(`/vinciproductgroup?page=0&perPage=10&order=desc&paginated=false&idVinciProductCategory=${id_vinci_product_category}`).then((request) => {
        if (request.statusResponse) {
            response = request;
            const data = request.data.data;
            response.data = data.map((record) => ({
                value: record.id_vinci_product_group,
                label: record.group_name,
                id_vinci_product_category: record.id_vinci_product_category,
                active: record.enable
            }));
        }
    })
        .catch((error) => (response = error));
    return response;
};

export const selectProductSubGroupVinci = async (id_vinci_product_category, id_vinci_product_group) => {
    let response = null;
    await getAll(`/vinciproductsubgroup?page=0&perPage=&order=desc&paginated=false&idVinciProductCategory=${id_vinci_product_category}&idVinciProductGroup=${id_vinci_product_group}`).then((request) => {
        if (request.statusResponse) {
            response = request;
            const data = request.data.data;
            response.data = data.map((record) => ({
                value: record.id_vinci_product_subgroup,
                label: record.subgroup_name,
                active: record.enable,
                id_vinci_product_category: record.id_vinci_product_category,
                id_vinci_product_group: record.id_vinci_product_group
            }));
        }
    })
        .catch((error) => (response = error));
    return response;
};

export const selectProductSubGroup = async (id_agent, id_product_category, id_product_group) => {
    let response = null;
    await getAll(`/productsubgroup?page=0&perPage=&order=desc&paginated=false&idAgent=${id_agent}&idProductCategory=${id_product_category}&idProductGroup=${id_product_group}`).then((request) => {
        if (request.statusResponse) {
            response = request;
            const data = request.data.data;
            response.data = data.map((record) => ({
                value: record.id_product_subgroup,
                label: record.subgroup_name,
                active: record.enable,
                id_product_group: record?.id_product_group,
                id_product_category: record?.id_product_category, 
                id_agent: record.id_agent,
                id_vinci_product_subgroup: record?.id_vinci_product_subgroup
            }));
        }
    })
        .catch((error) => (response = error));
    return response;
};

export const selectAgent = async () => {
    let response = null;
    await getAll(`/agent?page=0&perPage=0&order=desc&search=&paginated=false`).then((request) => {
        if (request.statusResponse) {
            response = request;
            const data = request.data.data;
            response.data = data.map((record) => ({
                value: record.id_agent,
                label: record.client_name,
                active: record.enable
            }));
        }
    })
        .catch((error) => (response = error));
    return response;
};

export const selectSender = async () => {
    let response = null;
    await getAll(`/sender?page=0&perPage=0&order=desc&paginated=false`).then((request) => {
        if (request.statusResponse) {
            response = request;
            const data = request.data.data;
            response.data = data.map((record) => ({
                value: record.id_sender,
                label: record.sender_name,
                active: record.enable
            }));
        }
    })
        .catch((error) => {
            response = error;
        });
    return response;
};

export const selectShop = async () => {
    let response = null;
    await getAll(`/shop?page=0&perPage=10&order=desc&paginated=false`)
        .then((request) => {
            if (request.statusResponse) {
                response = request;
                const data = request.data.data;
                response.data = data.map((record) => ({
                    value: record.id_shop,
                    label: record.shop_name,
                    active: record.enable
                }));
            }
        })
        .catch((error) => (response = error));
    return response;
};

export const selectIdentificationType = async () => {
    let response = null;
    await getAll(`/identificationtype?page=0&perPage=10&order=desc&paginated=false`).then(request => {
        if (request.statusResponse) {
            response = request;
            const data = request.data.data;
            response.data = data.map(record => ({
                "value": record.id_identification_type,
                "label": record.description,
                'active': record.enable
            }));
        }
    }).catch(error => {
        response = error;
    });
    return response;
}

export const getDefaultOptionSelect = (array, id) => {
    return array.filter(c => c.value === id);
}

export const formatDefaultValue = (values) => {
    if (values && values.label !== '') {
        return values
    } else
        return null
}

export const formatGroupLabel = ({ label, options }) => {
    return (
        <div style={groupStyles}>
            <span>{label}</span>
            <span style={groupBadgeStyles}>{options.length}</span>
        </div>
    )
}

export const filterSelectData = (action, data, disabledRecord) => {

    let options = [];

    let enableLabel = 'Registros activos';
    let disableLabel = 'Registros inactivos';

    let newActivesData = changeActivesValues(data);
    data = newActivesData;

    let enableData = data.filter(record => record.active === true);
    let disableData = data.filter(record => record.active !== true);

    switch (action) {
        case typeFilterSelect.get:

            options.push(
                {
                    label: disableLabel,
                    options: disableData
                },
                {
                    label: enableLabel,
                    options: enableData
                }
            )

            break;
        case typeFilterSelect.add:

            options.push({
                label: enableLabel,
                options: enableData
            });

            break;
        case typeFilterSelect.edit:



            if (disabledRecord.length > 0) {

                let disableData = data.filter(record => (record.active !== true && record.value === disabledRecord[0].value));

                let newActivesDisableRecord = changeActivesValues(disabledRecord);
                disabledRecord = newActivesDisableRecord;

                if (disableData.length > 0) {
                    options.push(
                        {
                            label: disableLabel,
                            options: disableData
                        },
                        {
                            label: enableLabel,
                            options: enableData
                        }
                    );
                } else {
                    options.push(
                        {
                            label: enableLabel,
                            options: enableData
                        }
                    );
                }

            } else {
                options.push(
                    {
                        label: enableLabel,
                        options: enableData
                    }
                );
            }


            break;
        default:
            break;
    }

    return options;

}

export const filterByVinciCategoriesGroupsSubGroups = (enabledCategories, vinciCategories, keyID) => {
    // 
    let groupedCategories = vinciCategories.map((v) => {
        let filteredCategories = enabledCategories[0].options.filter(c => {
            if (c[keyID] === v.value){
                return c;
            }
        })

        return {
            label: v.label,
            options: filteredCategories
        }
    });

    return groupedCategories;

}


const changeActivesValues = (array) => {

    array.map(record => {

        if (typeof record.active === 'string') {

            (record.active === 'DIS') ? record.active = false : record.active = true;

        }

    });

    return array;
}


export const getDefaultDay = (array) => {
    let defaultValue = {label: '', value: ''}
    if (array.day === 'Monday') {
        return defaultValue = {label: 'Lunes', value: 'Monday'}
    }
    if (array.day === 'Tuesday') {
        return defaultValue = {label: 'Martes', value: 'Tuesday'}
    }
    if (array.day === 'Wednesday') {
        return defaultValue = {label: 'Miércoles', value: 'Wednesday'}
    }
    if (array.day === 'Thursday') {
        return defaultValue = {label: 'Jueves', value: 'Thursday'}
    }
    if (array.day === 'Friday') {
        return defaultValue = {label: 'Viernes', value: 'Friday'}
    }
    if (array.day === 'Saturday') {
        return defaultValue = {label: 'Sábado', value: 'Saturday'}
    }
    if (array.day === 'Sunday') {
        return defaultValue = {label: 'Domingo', value: 'Sunday'}
    }
}