import React from 'react'
import { useRef } from 'react';
import { useEffect } from 'react';
import CreatableSelect from 'react-select';
import { formatDefaultValue, formatGroupLabel } from '../../service/comboBox';
import "./ComboBox.css";

export const ComboBox = ({ onChange, name, text, options, defaultValueSelect, isDisabled, resetSelect, isLoading }) => {

    const inputEl = useRef();

    useEffect(() => {
        if (resetSelect) {
            inputEl.current.selectOption('');
        }
    }, [resetSelect]);

    const handleChange = (e) => {
        let eventCombo = { target: { name: '', value: '', label: '' } };
        eventCombo.target.name = name;
        eventCombo.target.value = e.value;
        eventCombo.target.label = e.label;
        eventCombo.target.data = e;
        onChange(eventCombo);
    }

    return (
        <>
            <label htmlFor={name} className="form-label">{text}</label>
            <div className="combo-container">
                <CreatableSelect
                    name={name}
                    id='custom-select'
                    options={options}
                    placeholder='Seleccione'
                    classNamePrefix="custom_select"
                    className="basic-select"
                    isLoading={isLoading}
                    ref={inputEl}
                    isDisabled={isDisabled}
                    formatGroupLabel={formatGroupLabel}
                    onChange={(event) => handleChange(event)}
                    defaultValue={formatDefaultValue(defaultValueSelect)}
                />
            </div>
        </>
    )
}
