export const productGroupErrors = ({ error, data }) => {
    let message = "";

    if (error === 500) return message = "error";

    if (error === 404 && data.message === 'The product group with the sent id is not found') return message = 'El grupo enviado no existe';

    if (error === 404 && data.message === 'The sent agent does not exist') return message = 'El agente enviado no existe';

    if (error === 404 && data.message === 'The category does not exist') return message = 'La categoría enviada no existe';

    if (error === 404 && data.message === 'The sent vinciProductGroup does not exist') return message = 'El sub-grupo de vinci no existe';

    if (error === 404 && data.message === 'The category sent is not related to the agent') return message = 'La categoría enviada no tiene relación con el agente';

    if (error === 400 && data.message === 'There is already a record with the selected name and with the null code, please go to the corresponding screen to modify the record') {
        message = 'Ya existe un registro con el nombre seleccionado y con el código nulo';
    }
    if (error === 400 && data.message === 'There is already a record with the selected code and name but it is disabled, go to the corresponding screen to enable it') {
        message = 'Ya existe un registro con el código y nombre seleccionado pero está deshabilitado,';
    }
    if (error === 400 && data.message === 'There is already a record with the selected code and name') return message = 'Ya existe un registro con el código y nombre seleccionado';

    if (error === 400 && data.message === 'The selected category has another related vinci group') return  message = 'La categoría seleccionada tiene otro grupo de vinci relacionado';

    if (error === 400 && data.message === 'The sent agent is disabled') return message = 'El agente está desactivado';

    if (error === 400 && data.message === 'Please contact the site administrator since the category you want to insert is disabled') return message = 'La categoría enviada está desactivada';

    if (error === 400 && data.message === 'Please contact the site administrator since the vinci product group you want to insert is disabled') return message = 'El grupo de vinci está desactivado';

    if (error === 400 && data.message === 'The sent agent is not enabled') return message = 'El agente está desactivado';

    if (error === 400 && data.message === 'The category sent is not related to the agent') return message = 'La categoría no tiene relación con el agente';

    if (error === 400 && data.message === 'The product subgroup you want to disable was already disabled') return message = 'El grupo ingresado ya está desactivado';

    if(error === 400 && data.message.includes('record(s) of the table invoice product')) return message = 'El grupo a eliminar posee facturas asociadas';
    
    if(error === 400 && data.message.includes('record(s) of the table product subgroup')) return message = 'El grupo a eliminar posee sub-grupos asociados';
    
    return message;

};
