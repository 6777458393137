import React, { useEffect, useState } from "react";
import useForm from "../../../validate-forms/useForm";
import validate from "../../../validate-forms/Product/AddFormValidationRules";
import { Loading } from "../../Loading/Loading";
import { ErrorPage } from "../../ErrorPage/ErrorPage";
import './AddOrEdit.css';
import { ComboBox } from "../../ComboBox/ComboBox";
import { createRecord, editRecord } from "../../../request/Request";
import { selectProductCategoryVinci, selectProductGroupVinci, selectProductSubGroupVinci,
         getDefaultOptionSelect, filterSelectData } from "../../../service/comboBox";
import { selectEnable, typeFilterSelect } from '../../../service/defaultData';
import { productErrors } from "../../../service/ErrorNotification/productErrors";
import Warning from "../../Notification/Warning";

export const AddOrEdit = ({ actionChange, createdOrEditProduct, product, render, agents }) => {

    const [warningMessage, setWarningMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState(false);
    const [loading, setLoading] = useState(true);

    const [agentSelected, setAgentSelected] = useState(null);
    const [categorySelected, setCategorySelected] = useState(null);
    const [groupSelected, setGroupSelected] = useState(null);

    const [filteredAgents, setFilteredAgents] = useState([]);
    const [productCategories, setProductCategories] = useState([]);
    const [productGroups, setProductGroups] = useState([]);
    const [productSubGroups, setProductSubGroups] = useState([]);

    const [defaultAgent, setDefaultAgent] = useState(null);
    const [defaultCategory, setDefaultCategory] = useState(null);
    const [defaultGroup, setDefaultGroup] = useState(null);
    const [defaultSubGroup, setDefaultSubGroup] = useState(null);
    const [defaultEnable, setDefaultEnable] = useState(null);

    const [resetProductCategory, setResetProductCategory] = useState(false);
    const [resetProductGroups, setResetProductGroups] = useState(false);
    const [resetProductSubGroup, setResetProductSubGroup] = useState(false);

    const [errorMessageCategory, setErrorMessageCategory] = useState('');
    const [errorMessageGroup, setErrorMessageGroup] = useState('');
    const [errorMessageSubGroup, setErrorMessageSubGroup] = useState('');

    const [statusPetitionProductCategories, setStatusPetitionProductCategories] = useState(false);
    const [statusPetitionProductGroups, setStatusPetitionProductGroups] = useState(false);
    const [statusPetitionProductSubGroups, setStatusPetitionProductSubGroups] = useState(false);

    const { values, errors, handleChange, handleSubmit } = useForm(sendForm, validate);

    const [statusButtonSubmit, setStatusButtonSubmit] = useState(false);

    useEffect(() => { 

        if (render === 'edit') {

            (async function () {
                setAgentSelected(product.id_agent);
                setCategorySelected(product.id_vinci_product_category);

                let defaultAgentTemp = getDefaultOptionSelect(agents, product.id_agent);
                (defaultAgentTemp.length > 0) ? setDefaultAgent(defaultAgentTemp[0]) : setDefaultAgent([]);

                let enabledAndDisabledAgents = filterSelectData(typeFilterSelect.edit, agents, defaultAgentTemp);
                setFilteredAgents(enabledAndDisabledAgents);

                
                let productCategories = await selectProductCategoryVinci();
                if (productCategories.statusResponse) {
                    let defaultCategoryTemp = getDefaultOptionSelect(productCategories.data, product.id_vinci_product_category);
                    (defaultCategoryTemp.length > 0) ? setDefaultCategory(defaultCategoryTemp[0]) : setDefaultCategory([]);
                    let enabledAndDisabledCategories = filterSelectData(typeFilterSelect.edit, productCategories.data, defaultCategoryTemp);
                    (enabledAndDisabledCategories.length > 0) ? setProductCategories(enabledAndDisabledCategories) : setProductCategories(productCategories.data);

                }


                let productGroups = await selectProductGroupVinci(product.id_vinci_product_category);
                if (productGroups.statusResponse) {
                    
                    let defaultGroupTemp = getDefaultOptionSelect(productGroups.data, product.id_vinci_product_group);

                    setCategorySelected(defaultGroupTemp[0]?.id_vinci_product_category);

                    (defaultGroupTemp.length > 0) ? setDefaultGroup(defaultGroupTemp[0]) : setDefaultGroup([]);

                    let enabledAndDisabledGroups = filterSelectData(typeFilterSelect.edit, productGroups.data, defaultGroupTemp);
                    (enabledAndDisabledGroups.length > 0) ? setProductGroups(enabledAndDisabledGroups) : setProductGroups(productGroups.data);

                }


                let productSubGrop = await selectProductSubGroupVinci(product.id_vinci_product_category, product.id_vinci_product_group);
                if (productSubGrop.statusResponse) {

                    let defaultSubGroupTemp = getDefaultOptionSelect(productSubGrop.data, product.id_vinci_product_subgroup);
                    setCategorySelected(defaultSubGroupTemp[0]?.id_vinci_product_category);
                    setGroupSelected(defaultSubGroupTemp[0]?.id_vinci_product_group);
                     
                    (defaultSubGroupTemp.length > 0) ? setDefaultSubGroup(defaultSubGroupTemp[0]) : setDefaultSubGroup([]);

                    let enabledAndDisabledSubGroups = filterSelectData(typeFilterSelect.edit, productSubGrop.data, defaultSubGroupTemp);
                    (enabledAndDisabledSubGroups.length > 0) ? setProductSubGroups(enabledAndDisabledSubGroups) : setProductSubGroups(productSubGrop.data);

                }

                let defaultEnableTemp = getDefaultOptionSelect(selectEnable, product.enable);
                setDefaultEnable(defaultEnableTemp[0]);

            })();

        }
    }, []);

    useEffect(() => {
        if (render === 'add') {

            let enabledAgents = filterSelectData(typeFilterSelect.add, agents);
            setFilteredAgents(enabledAgents);
            setLoading(false);

        } else {
            values.enable = product.enable;
            values.id_agent = product.id_agent;
            values.id_vinci_product_category = product.id_vinci_product_category;
            values.id_vinci_product_group = product.id_vinci_product_group;
            values.id_vinci_product_subgroup = product.id_vinci_product_subgroup;
            values.product_code = product.product_code;
            values.product_name = product.product_name;

            if (defaultAgent && defaultCategory && defaultGroup && defaultSubGroup && defaultEnable) {
                setLoading(false);
            }

        }
    }, [defaultAgent, defaultCategory, defaultGroup, defaultSubGroup, defaultEnable]);

    async function sendForm() {

        setStatusButtonSubmit(true);

        if (render === 'add') {
            await createRecord(`/product`, values).then(request => {
                setStatusButtonSubmit(false);
                if (request.statusResponse) {
                    redirect('created');
                }
            }).catch(error => getErrors(error));
        } else {
            await editRecord(`/product/${product.id_product}`, values).then(request => {
                setStatusButtonSubmit(false);
                if (request.statusResponse) {
                    redirect('edit');
                }
            }).catch(error => getErrors(error.message));
        }

    }

    const redirect = (action) => {
        createdOrEditProduct(action);
        actionChange('table');
    }

    const getErrors = (error) => {

        setWarningMessage('');
        setErrorMessage(false);
        let respondeError = productErrors(error);

        if (respondeError === 'error') return setErrorMessage(true);

        setWarningMessage(respondeError);
        setStatusButtonSubmit(false);

    }

    const cleanAllResetSelect = () => {
        setResetProductCategory(false);
        setResetProductGroups(false);
        setResetProductSubGroup(false);
    }

    const getProductCategory = async (id_agent) => {

        if (render === 'add' && !agentSelected) {
            cleanAllResetSelect();

            if (id_agent !== values.id_agent) {

                setStatusPetitionProductCategories(true);
                let productCategories = await selectProductCategoryVinci();

                if (productCategories.statusResponse) {

                    setErrorMessageCategory('');
                    setErrorMessageGroup('');
                    setErrorMessageSubGroup('');

                    let enabledCategories = filterSelectData(typeFilterSelect.add, productCategories.data);
                    setProductCategories(enabledCategories);

                    setResetProductCategory(true);
                    setResetProductGroups(true);
                    setResetProductSubGroup(true);

                    setStatusPetitionProductCategories(false);

                } else {

                    setErrorMessageCategory('*El agente no posee categorías relacionadas');
                    setErrorMessageGroup('*El agente no posee grupos relacionados');
                    setErrorMessageSubGroup('*El agente no posee sub-grupos relacionados');

                    setProductCategories([]);
                    setResetProductCategory(true);

                    setProductGroups([]);
                    setResetProductGroups(true);

                    setProductSubGroups([]);
                    setResetProductSubGroup(true);

                    setStatusPetitionProductCategories(false);

                }

            }
        }

    }

    const getProductGroup = async (id_product_category) => {

        cleanAllResetSelect();

        if (id_product_category  && id_product_category !== categorySelected) {

            setStatusPetitionProductGroups(true);
            let productGroups = await selectProductGroupVinci(id_product_category);

            if (productGroups.statusResponse) {

                setErrorMessageGroup('');
                setErrorMessageSubGroup('');

                let enabledGroups = filterSelectData(typeFilterSelect.add, productGroups.data);
                setProductGroups(enabledGroups);
                setResetProductGroups(true);

                setProductSubGroups([]);
                setResetProductSubGroup(true);

                setStatusPetitionProductGroups(false);

            } else {
                setErrorMessageGroup('*El agente no posee grupos relacionados');
                setErrorMessageSubGroup('*El agente no posee sub-grupos relacionados')

                setProductGroups([]);
                setResetProductGroups(true);

                setProductSubGroups([]);
                setResetProductSubGroup(true);

                setStatusPetitionProductGroups(false);
            }

        }

    }

    const getProductSubGroup = async (id_product_group) => {

        cleanAllResetSelect();

        if (id_product_group && id_product_group !== groupSelected) {

            setStatusPetitionProductSubGroups(true);
            let productSubGroups = await selectProductSubGroupVinci(categorySelected, id_product_group);
            if (productSubGroups.statusResponse) {
                setErrorMessageSubGroup('');
                setCategorySelected(productSubGroups[0]?.id_vinci_product_category);

                let enabledSubGroups = filterSelectData(typeFilterSelect.add, productSubGroups.data);
                setProductSubGroups(enabledSubGroups);

                setResetProductSubGroup(true);

                setStatusPetitionProductSubGroups(false);

            } else {
                setErrorMessageSubGroup('*El agente no posee sub-grupos relacionados');

                setProductSubGroups([]);
                setResetProductSubGroup(true);

                setStatusPetitionProductSubGroups(false);
            }

        }

    }

    function comboBoxChange(e) {
        handleChange(e);
        switch (e.target.name) {
            case 'id_agent':
                setAgentSelected(e.target.value);
                getProductCategory(e.target.value);
                break;
            case 'id_vinci_product_category':
                setCategorySelected(e.target.value);
                getProductGroup(e.target.value);
                break;
            case 'id_vinci_product_group':
                setGroupSelected(e.target.value);
                getProductSubGroup(e.target.value);
                break;
            default:
                break;
        }
    }

    return loading ? <Loading /> : errorMessage ? <ErrorPage redirect={true} actionChange={actionChange} name={'Productos'} /> :
        <section className="section form-color">
            <form onSubmit={handleSubmit} noValidate>
                <div className="acction-card"><span aria-hidden="true">
                    {render === 'add' ? 'Agregar ' : 'Actualizar '} Producto</span>
                </div>

                {warningMessage !== '' && <Warning text={warningMessage} />}

                <div className="row">
                    <div className="col">
                        <div className="form-group coriport-input">
                            <label htmlFor="product_name" className="form-label">Nombre del Producto</label>
                            <input
                                type="text"
                                className={`input-form form-control`}
                                placeholder="Nombre del Producto"
                                name="product_name"
                                onChange={handleChange}
                                value={values.product_name || ''}
                                required
                            />
                            {errors.product_name && (<p className="help is-danger">{errors.product_name}</p>)}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group coriport-input">
                            <label htmlFor="product_code" className="form-label">Código del Producto</label>
                            <input
                                type="text"
                                className={`input-form form-control`}
                                placeholder="Código del Producto"
                                name="product_code"
                                onChange={handleChange}
                                value={values.product_code || ''}
                                required
                            />
                            {errors.product_code && (<p className="help is-danger">{errors.product_code}</p>)}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group coriport-input">
                            <ComboBox
                                name={"id_agent"}
                                text={"Agente"}
                                options={filteredAgents}
                                onChange={comboBoxChange}
                                defaultValueSelect={defaultAgent}
                            />
                            {errors.id_agent && (<p className="help is-danger">{errors.id_agent}</p>)}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group coriport-input">
                            <ComboBox
                                name={"id_vinci_product_category"}
                                text={"Categoría del Producto"}
                                options={productCategories}
                                onChange={comboBoxChange}
                                isLoading={statusPetitionProductCategories}
                                resetSelect={resetProductCategory}
                                defaultValueSelect={defaultCategory}
                            />
                            {errors.id_vinci_product_category && (<p className="help is-danger">{errors.id_vinci_product_category}</p>)}
                            {errorMessageCategory && (<p className="help is-danger">{errorMessageCategory}</p>)}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group coriport-input">
                            <ComboBox
                                resetSelect={resetProductGroups}
                                name={"id_vinci_product_group"}
                                text={"Grupo del Producto"}
                                options={productGroups}
                                onChange={comboBoxChange}
                                isLoading={statusPetitionProductGroups}
                                defaultValueSelect={defaultGroup} />
                            {errors.id_vinci_product_group && (<p className="help is-danger">{errors.id_vinci_product_group}</p>)}
                            {errorMessageGroup && (<p className="help is-danger">{errorMessageGroup}</p>)}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group coriport-input">
                            <ComboBox
                                name={"id_vinci_product_subgroup"}
                                text={"Sub-Grupo del Producto"}
                                options={productSubGroups}
                                onChange={comboBoxChange}
                                resetSelect={resetProductSubGroup}
                                isLoading={statusPetitionProductSubGroups}
                                defaultValueSelect={defaultSubGroup}
                            />
                            {errors.id_vinci_product_subgroup && (<p className="help is-danger">{errors.id_vinci_product_subgroup}</p>)}
                            {errorMessageSubGroup && (<p className="help is-danger">{errorMessageSubGroup}</p>)}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group coriport-input">
                            <ComboBox
                                name={"enable"}
                                text={"Estado"}
                                options={selectEnable}
                                onChange={comboBoxChange}
                                defaultValueSelect={defaultEnable} />
                            {errors.enable && (<p className="help is-danger">{errors.enable}</p>)}
                        </div>
                    </div>
                </div>

                <div className="container-bottons">
                    <button disabled={statusButtonSubmit} id='custom-btn-coriport' className=" btn  btn-coriport btn-add text-white " type="submit">
                        {
                            statusButtonSubmit ? <div className="container-spinner-loading pt-2">
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </div> : <p className="label-button-add"> {render === 'add' ? 'Agregar' : 'Actualizar'}</p>
                        }
                    </button>
                    <button className="btn-coriport btn-cancel" onClick={() => actionChange('table')}>
                        Cancelar
                    </button>
                </div>

            </form>
        </section>

}