import React, { useEffect, useState } from 'react'
import useForm from '../../../validate-forms/useForm';
import { ComboBox } from '../../ComboBox/ComboBox'
import validate from '../../../validate-forms/Invoice/AddFormValidationRules';
import { selectMonth } from '../../../service/defaultData';
import './ParamSearch.css';
import { CustomDatePicker } from '../../CustomDatePicker/CustomDatePicker';
import moment from 'moment';
import { Button } from 'bootstrap';

export const ParamSearch = ({ dataTable, statusButton: status, shops, shopSelected, handleSearch, vouchers }) => {

    const [voucherType, setVoucherType] = useState([]);
    const { values, errors, handleChange, handleSubmit } = useForm(sendForm, validate);
    const [statusButton, setStatusButton, action] = useState(null);

    useEffect(() => {
        setStatusButton(status);
    }, [status])


    useEffect(() => {
        (async function () {
            let allVocuher = vouchers.map((d) => ({
                value: d.id_voucher_type,
                label: d.description,
            }));
            allVocuher.unshift({
                value: '',
                label: 'Sin Tipo documento',
            });
            setVoucherType(allVocuher);
        })();
    }, [vouchers]);

    async function sendForm() {
 
        if (values.dateIssueStart !== '' && values.dateIssueEnd !== '') {
            let dateIssueStartTemp = new Date(values.dateIssueStart).toISOString();
            let dateIssueEndTemp = new Date(values.dateIssueEnd).toISOString();

            values.dateIssueStart = moment(dateIssueStartTemp).format('YYYY-MM-DDTHH:mm:ss');
            values.dateIssueEnd = moment(dateIssueEndTemp).format('YYYY-MM-DDTHH:mm:ss');
        }

        values.idShop = String(values.idShop)

        handleSearch(values);



    }
    function report() {
        values.action = 'report';
    }
    function search() {
        values.action = 'search';
    }
    const handleStartDate = (date) => values.dateIssueStart = date;

    const handleEndtDate = (date) => values.dateIssueEnd = date;

    const comboBoxChange = (e) => handleChange(e);

    const datePickerChange = (e) => handleChange(e);

    return (
        <div>
            <form onSubmit={handleSubmit} noValidate>

                <div className="row">

                    <div className="col-md-4">
                        <ComboBox name={"idShop"} text={"Seleccionar Tienda"}
                            options={shops}
                            onChange={comboBoxChange}
                            defaultValueSelect={shopSelected} />
                        {errors.idShop && (
                            <p className="help is-danger">{errors.idShop}</p>
                        )}
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor="invoiceYear" className="form-label">Año</label>
                            <input type="number" className={`input-form form-control ${errors.invoiceYear && 'is-danger'}`} name="invoiceYear" onChange={handleChange} />
                            {errors.invoiceYear && (
                                <p className="help is-danger">{errors.invoiceYear}</p>
                            )}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group coriport-input">
                            <ComboBox name={"invoiceMonth"} text={"Mes"} options={selectMonth} onChange={comboBoxChange} />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group coriport-input">
                            <CustomDatePicker type={'date-time'} onChange={datePickerChange} name='dateIssueStart' placeHolder={'dd/mm/aaaa --:--:--'} getDate={handleStartDate} title={'Del'} />
                            {errors.dateIssueStart && (
                                <p className="help is-danger">{errors.dateIssueStart}</p>
                            )}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group coriport-input">
                            <CustomDatePicker type={'date-time'} onChange={datePickerChange} name='dateIssueEnd' placeHolder={'dd/mm/aaaa --:--:--'} getDate={handleEndtDate} title={'Hasta'} />
                            {errors.dateIssueEnd && (
                                <p className="help is-danger">{errors.dateIssueEnd}</p>
                            )}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group coriport-input">
                            <ComboBox name={"idVoucherType"} text={"Tipo de Documento"} options={voucherType} onChange={comboBoxChange} />
                            {errors.idVoucherType && (
                                <p className="help is-danger">{errors.idVoucherType}</p>
                            )}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-8">
                        <div className="form-group coriport-input">
                            <label htmlFor="documentNumber" className="form-label">Número de Documento</label>
                            <input type="number" className={`input-form form-control ${errors.documentNumber && 'is-danger'}`} name="documentNumber" onChange={handleChange} />
                            {errors.documentNumber && (
                                <p className="help is-danger">{errors.documentNumber}</p>
                            )}
                        </div>
                    </div>
                </div>

                <div className="row" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className="col-6 text-left">
                                <div className="container-bottons text-left">
                                    {
                                        dataTable > 0 && (
                                            <button onClick={report}
                                            id='generate-report' className="btn btn-coriport btn-add text-white">Descargar</button>
                                        )
                                    }
                                </div>
                            </div>



                    <div className="col-6 text-right">
                        <div className="container-bottons text-right">
                            <button type='submit' onClick={search} disabled={statusButton} id='custom-btn-coriport button-search-invoice' className=" btn  btn-coriport btn-add text-white ">
                                {
                                    statusButton ? <div className="container-spinner-loading pt-2">
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    </div> : <p className="label-button-add">Buscar</p>
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}