export const customColumn = (name, selector) => {
  return {
    selector: selector,
    name: name,
    sortable: true,
    reorder: true,
  };
};

export const paginationComponentOptions = {
  rowsPerPageText: "Filas",
  rangeSeparatorText: "de",
};
