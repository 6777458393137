export const agentErrors = ({ error, data }) => {

    let message = "";

    if (error === 500) return message = "error";

    if (error === 404) return message = "El agente ingresado no es válido";

    if (error === 400 && data.message === "An agent already exists with the submitted id client") return message = "La identificación enviada ya existe";

    if (error === 400 && data.message === "An agent already exists with the submitted username") return message = "El usuario ingresado ya existe";

    if (error === 400 && data.message === "An agent already exists with the submitted clientName") return message = "El nombre ingresado ya existe";
    
    if (error === 400 && data.message === "Error, the agent with the sent agent id is not found") return message = "El agente ingresado no existe";

    if(error === 400 && data.message.includes('records of the Product Category table')) return message = "El agente posee categoría de productos asociados";
    
    if (error === 400 && data.message === "The agent you want to disable was already disabled") return message = "El agente ya se encuentra desactivado";
   
    if (error === 400 && data.message === "The agent you want to disable is notified state") return message = "El agente a deshabilitar está en estado de notificado";
  
    if (error === 400 && data.message === "The agent you want to disable is in attention status") return message = "El agente a deshabilitar está en estado de atención";

    if (error === 400 &&
        data.message === "The email does not have the requested structure. The expected structure is: username@MailServer.domain If you want to add more than one email you must separate them by comma without space") {

        return message = "El email ingresado no es válido";
    }

    return message;
};
