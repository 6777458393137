export default function validate(values) {
  let errors = {};

  if (values.enable === undefined) {
    errors.enable = "*El estado es requerido";
  }
  if (!values.id_agent) {
    errors.id_agent = "*El agente es requerido";
  }
  if (!values.id_vinci_product_category) {
    errors.id_vinci_product_category = "*La categoría es requerida";
  }
  if (!values.id_vinci_product_group) {
    errors.id_vinci_product_group = "*El grupo es requerido";
  }
  if (!values.id_vinci_product_subgroup) {
    errors.id_vinci_product_subgroup = "*El sub-grupo es requerido";
  }
  if (!values.product_code) {
    errors.product_code = "*El código es requerido";
  }
  if (!values.product_name) {
    errors.product_name = "*El nombre es requerido";
  }

  return errors;
}
