 
export default function validate(values) {
  let errors = {};

  /* eslint-disable no-useless-escape */
  const regexEmail = /^((([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,}))(,(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,}))*$/g;


  if (values.email) {
    if (regexEmail.test(values.email) === false) {
      errors.email = "*Debe ingresar un correo valido";
    }
  }
  if (!values.client_name) {
    errors.client_name = '*El nombre del agente es requerido';
  } 
  if (!values.agent_password) {
    errors.agent_password = '*La contraseña es requerida';
  }
  if (values.enable === undefined) {
    errors.enable = '*El estado es requerido';
  }
  if (!values.passwordConfirmation) {
    errors.passwordConfirmation = '*La confirmación de la contraseña es requerida';
  } else if (values.passwordConfirmation && values.passwordConfirmation !== values.agent_password) {
    errors.passwordConfirmation = '*La confirmación de la contraseña debe ser igual a la contraseña';
  }
  if (!values.username) {
    errors.username = '*El nombre de usuario es requerido';
  }
  if(values.installed  === undefined){
    errors.installed = '*Estado de instalación requerido';
  }
  if(!values.id_client){
    errors.id_client = '*ID requerido';
  }
  if (values.passwordConfirmation?.length < 8) {
    errors.passwordConfirmation = '*Debe tener mínimo 8 caracteres'
  }
  if (values.agent_password?.length < 8) {
    errors.agent_password = '*Debe tener mínimo 8 caracteres'
  }

  return errors;
};